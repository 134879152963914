import { skipToken } from '@reduxjs/toolkit/query'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import penningLogo from '../../../../assets/penning.png'
import {
  ROUNDING_DIGITS_CRYPTO,
  ROUNDING_DIGITS_FIAT,
} from '../../../../constants'
import { useOtacGetSwapInvoiceDetailsQuery } from '../../../../redux/services/coreApi'
import { setOtacToken } from '../../../../redux/slices/sessionSlice'
import { formatNumber, formatUtcDate } from '../../../../utils/formatters'

type SwapInvoiceProps = {}

const Item: React.FC<{ title: string; value: string }> = ({ title, value }) => {
  return (
    <div className="pr-2">
      <span>{title}</span>
      <span className="text-brand-light-text-default"> {value}</span>
    </div>
  )
}

const SwapInvoice: React.FC<SwapInvoiceProps> = () => {
  const [swapId, setSwapId] = useState<string | undefined>(undefined)

  const { data: swapInvoiceDetails, isLoading: swapInvoiceIsLoading } =
    useOtacGetSwapInvoiceDetailsQuery(swapId ?? skipToken, { skip: !swapId })

  const dispatch = useDispatch()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const otacToken = searchParams.get('otacToken')
    if (otacToken) dispatch(setOtacToken({ otacToken }))

    const swapId = searchParams.get('id')
    if (swapId) setSwapId(swapId)
  }, [])

  if (swapInvoiceIsLoading) {
    return <div>Loading...</div>
  }

  const depositTotal =
    swapInvoiceDetails?.swap.deposit?.amount! *
    swapInvoiceDetails?.swap.deposit?.currencyToEurRate!

  const withdrawTotal =
    swapInvoiceDetails?.swap.withdraw?.amount! *
    swapInvoiceDetails?.swap.withdraw?.currencyToEurRate!

  const totalFee =
    swapInvoiceDetails?.swap.baseFee! + swapInvoiceDetails?.swap.bankFee!

  function splitByCapitalLetters(input?: string): string {
    if (!input) return ''
    return input
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  }

  const network =
    swapInvoiceDetails?.swap.withdraw?.wallet?.network.name ??
    swapInvoiceDetails?.swap.deposit?.wallet?.network.name

  const wallet =
    swapInvoiceDetails?.swap.withdraw?.wallet?.address ??
    swapInvoiceDetails?.swap.deposit?.wallet?.address

  const iban =
    swapInvoiceDetails?.swap.deposit?.bankAccount?.bankAccountDetails.iban ??
    swapInvoiceDetails?.swap.withdraw?.bankAccount?.bankAccountDetails.iban
  const bic =
    swapInvoiceDetails?.swap.deposit?.bankAccount?.bankAccountDetails.bic ??
    swapInvoiceDetails?.swap.withdraw?.bankAccount?.bankAccountDetails.bic

  const accountNo =
    swapInvoiceDetails?.swap.deposit?.bankAccount?.bankAccountDetails
      .accountNumber ??
    swapInvoiceDetails?.swap.withdraw?.bankAccount?.bankAccountDetails
      .accountNumber

  const regNo =
    swapInvoiceDetails?.swap.deposit?.bankAccount?.bankAccountDetails
      .registration ??
    swapInvoiceDetails?.swap.withdraw?.bankAccount?.bankAccountDetails
      .registration

  const withdrawInCrypto =
    swapInvoiceDetails?.swap.withdraw?.currency.currencyType ===
    'CryptoCurrency'

  const roundingDigitsDepositUnitValue =
    swapInvoiceDetails?.swap.deposit?.currency.currencyType === 'CryptoCurrency'
      ? ROUNDING_DIGITS_CRYPTO
      : ROUNDING_DIGITS_FIAT

  const roundingDigitsWithdrawUnitValue =
    swapInvoiceDetails?.swap.withdraw?.currency.currencyType ===
    'CryptoCurrency'
      ? ROUNDING_DIGITS_CRYPTO
      : ROUNDING_DIGITS_FIAT

  return (
    <div className="h-screen p-5 text-[10px] font-medium bg-white text-brand-light-text-description relative">
      <div className="flex justify-between items-start">
        <div>
          <p className="font-semi text-xs mb-1 text-brand-light-text-default">
            {swapInvoiceDetails?.user.companyTitle ??
              swapInvoiceDetails?.user.name}
          </p>

          <p>{swapInvoiceDetails?.user.address}</p>
          <p>{swapInvoiceDetails?.user.country.name}</p>
          <p>{swapInvoiceDetails?.user.vatNumber}</p>
          <p>{swapInvoiceDetails?.user.email}</p>
        </div>
        <img
          src={penningLogo}
          alt="Penning logo"
          className="h-16 w-16 rounded-full"
        />
      </div>

      <div className="grid grid-cols-2 mt-10">
        <Item
          title="Date:"
          value={`${formatUtcDate(swapInvoiceDetails?.swap.createdAt!)}`}
        />
        {withdrawInCrypto ? (
          <Item title="Network:" value={`${network}`} />
        ) : (
          <Item
            title={iban ? 'IBAN:' : 'Reg. No.:'}
            value={iban ?? regNo ?? ''}
          />
        )}
        <Item title="ID:" value={`${swapInvoiceDetails?.swap.id}`} />

        {withdrawInCrypto ? (
          <Item title="Wallet:" value={`${wallet}`} />
        ) : (
          <Item
            title={bic ? 'BIC:' : 'Accont No.:'}
            value={bic ?? accountNo ?? ''}
          />
        )}

        <Item
          title="Order ID:"
          value={`${swapInvoiceDetails?.swap.deposit?.bankDepositUniqueId}`}
        />
      </div>

      <div className="py-4 px-5 mt-5 text-xl text-brand-light-text-default font-semi -mx-5">
        Swap Order Receipt
      </div>

      <table className="mt-5">
        <thead>
          <th>Asset</th>
          <th>Description</th>
          <th className="text-right">Quantity</th>
          <th className="text-right">Unit value</th>
          <th className="text-right">Amount</th>
        </thead>
        <tbody className="border-b border-black/10 border-solid">
          <tr>
            <th>{swapInvoiceDetails?.swap.withdraw?.currency.isoCode}</th>
            <th>
              {splitByCapitalLetters(
                swapInvoiceDetails?.swap.withdraw?.currency.currencyType
              )}
            </th>
            <th className="text-right">
              {swapInvoiceDetails?.swap.withdraw &&
                formatNumber(swapInvoiceDetails.swap.withdraw.amount, {
                  currencyType:
                    swapInvoiceDetails.swap.withdraw.currency.currencyType,
                  isoCode: '',
                })}
            </th>
            <th className="text-right">
              {swapInvoiceDetails?.swap.withdraw &&
                formatNumber(
                  swapInvoiceDetails.swap.withdraw.currencyToEurRate,
                  {
                    currencyType: 'FiatCurrency',
                    isoCode: 'EUR',
                  },
                  roundingDigitsWithdrawUnitValue
                )}
            </th>
            <th className="text-right">
              {swapInvoiceDetails?.swap.deposit &&
                formatNumber(withdrawTotal, {
                  currencyType: 'FiatCurrency',
                  isoCode: 'EUR',
                })}
            </th>
          </tr>
        </tbody>
      </table>

      <table className="mt-10">
        <thead>
          <th>Payment unit</th>
          <th>Description</th>
          <th className="text-right">Quantity</th>
          <th className="text-right">Unit value</th>
          <th className="text-right">Amount</th>
        </thead>
        <tbody className="border-b border-black/10 border-solid">
          <tr>
            <th>{swapInvoiceDetails?.swap.deposit?.currency.isoCode}</th>
            <th>
              {splitByCapitalLetters(
                swapInvoiceDetails?.swap.deposit?.currency.currencyType
              )}
            </th>
            <th className="text-right">
              {swapInvoiceDetails?.swap.deposit &&
                formatNumber(swapInvoiceDetails.swap.deposit.amount, {
                  currencyType:
                    swapInvoiceDetails.swap.deposit.currency.currencyType,
                  isoCode: '',
                })}
            </th>
            <th className="text-right">
              {swapInvoiceDetails?.swap.deposit &&
                formatNumber(
                  swapInvoiceDetails.swap.deposit.currencyToEurRate,
                  {
                    currencyType: 'FiatCurrency',
                    isoCode: 'EUR',
                  },
                  roundingDigitsDepositUnitValue
                )}
            </th>
            <th className="text-right font-semi text-brand-light-text-default">
              <div>
                {swapInvoiceDetails?.swap.deposit &&
                  formatNumber(depositTotal, {
                    currencyType: 'FiatCurrency',
                    isoCode: 'EUR',
                  })}
              </div>
              {swapInvoiceDetails?.swap.deposit &&
                swapInvoiceDetails?.swap.deposit?.currency.isoCode !==
                  'EUR' && (
                  <div className="text-[8px] font-medium text-brand-light-text-description">
                    (
                    {formatNumber(
                      swapInvoiceDetails?.swap.deposit?.amount,
                      swapInvoiceDetails.swap.deposit.currency
                    )}
                    )
                  </div>
                )}
            </th>
          </tr>
        </tbody>
      </table>

      <div className="ml-auto w-4/12 py-2 border-b text-[10px] border-black/10 font-semi text-brand-light-text-default border-solid px-2 ">
        <div className="flex justify-between">
          <p>Sub total:</p>{' '}
          <p>
            {swapInvoiceDetails?.swap.deposit &&
              formatNumber(withdrawTotal, {
                currencyType: 'FiatCurrency',
                isoCode: 'EUR',
              })}
          </p>
        </div>
        <div className=" flex justify-between mt-2 gap-10">
          <p>Fee ({Math.round(totalFee * 100 * 100) / 100}%):</p>
          <p>
            {swapInvoiceDetails?.swap.deposit &&
              formatNumber(totalFee * depositTotal, {
                currencyType: 'FiatCurrency',
                isoCode: 'EUR',
              })}
          </p>
        </div>
      </div>

      <div className="absolute left-0 bottom-0 right-0 text-center py-3 border-t border-solid border-black/10 text-[10px]">
        <p>
          Penning A/S - Vester Voldgade 7B - 1552 Copenhagen V - Danmark -
          CVR-nr.: DK42312428
        </p>
        <p>j@penning.dk - www.penning.dk </p>
      </div>
    </div>
  )
}

export default SwapInvoice
