import { useDisclosure } from '@chakra-ui/react'
import moment from 'moment'
import type React from 'react'
import { useState } from 'react'
import Loading03Icon from '../../../../../../assets/loading-03-stroke-rounded'
import FormButton from '../../../../../../components/FormButton'
import InfoBox from '../../../../../../components/InfoBox'
import { useGetFundDepositsQuery } from '../../../../../../redux/services/coreApi'
import type { DepositDto } from '../../../../../../types/coreApi-types'
import AddFundDepositModal from './AddFundDepositModal'

interface FundDepositsProps {
  userId: string
}

const FundDeposits: React.FC<FundDepositsProps> = ({ userId }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { data: fundDepositsData, isLoading } = useGetFundDepositsQuery({
    investorId: userId,
  })

  const sortByDate = (arr?: DepositDto[]) => {
    if (!arr) return []
    const data = [...arr]
    return data.sort(
      (a, b) =>
        new Date(b.transferredAt ?? new Date()).getTime() -
        new Date(a.transferredAt ?? new Date()).getTime()
    )
  }

  const [editDeposit, setEditDeposit] = useState<DepositDto | null>(null)

  return (
    <div className="space-y-3">
      <div className="flex justify-between">
        <h1 className="h1">Fund deposits</h1>
        <div className="">
          <FormButton
            title="+ Add fund deposit"
            onClick={() => {
              setEditDeposit(null)
              onOpen()
            }}
          />
        </div>
      </div>

      <div className="card">
        {!fundDepositsData?.length && !isLoading && (
          <div className="flex justify-center text-description">
            No fund deposits yet.
          </div>
        )}

        {isLoading && (
          <Loading03Icon className="animate-spin w-6 h-6 self-center" />
        )}
        <div className="space-y-2">
          {sortByDate(fundDepositsData).map((item, i) => {
            return (
              <div
                onClick={() => {
                  setEditDeposit(item)
                  onOpen()
                }}
                className="p-2 px-3 flex justify-between items-center hover:bg-gray-50 rounded-md transition-all cursor-pointer"
              >
                <div>
                  <p className="text-sm font-semi">Deposited funds</p>
                  <p className="text-description">
                    {moment(item.transferredAt).format('DD MMMM, YYYY')}
                  </p>
                </div>
                <div className="flex gap-2 items-center">
                  <p className="font-semi text-sm">
                    {item.amount} {item.currency.isoCode}
                  </p>
                  <div>
                    <InfoBox
                      colorScheme={
                        item.transferStatus === 'Cancelled'
                          ? 'red'
                          : item.transferStatus === 'Completed'
                            ? 'green'
                            : item.transferStatus === 'Pending'
                              ? 'orange'
                              : 'orange'
                      }
                      className="h-[24px] text-xs"
                    >
                      {item.transferStatus}
                    </InfoBox>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <AddFundDepositModal
        deposit={editDeposit}
        isOpen={isOpen}
        onClose={onClose}
        userId={userId}
      />
    </div>
  )
}

export default FundDeposits
