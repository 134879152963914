import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import LoadingOverlay from './LoadingOverlay'

interface Props {
  isOpen: boolean
  onClose: () => void
  isLoading: boolean
  isLoadingText?: string
  title?: string
  children?: JSX.Element | (JSX.Element | boolean)[] | boolean
  maxW?: string
  whiteBG?: boolean
  renderTopRight?: React.ReactNode
}

const DefaultModal: React.FC<Props> = ({
  isOpen,
  onClose,
  title,
  isLoading,
  isLoadingText,
  children,
  maxW,
  whiteBG,
  renderTopRight,
}) => {
  const leastDestructiveRef = React.useRef(null)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={!isLoading}
      initialFocusRef={leastDestructiveRef}
    >
      <ModalOverlay bg={'rgba(0, 0, 0,0.85)'} />
      <ModalContent
        rounded="14px"
        p="0px"
        maxW={maxW ? maxW : '550px'}
        marginX="16px"
      >
        <div
          className={`${
            whiteBG
              ? 'bg-white dark:bg-brand-dark-card'
              : 'bg-brand-light-background dark:bg-brand-dark-background'
          }   rounded-xl relative`}
        >
          {isLoading && <LoadingOverlay title={isLoadingText || ''} />}

          {title && (
            <div className="p-5 pb-5 flex items-center justify-between">
              <p className="text-default-base">{title}</p>
              <div className="flex items-center gap-2">
                {renderTopRight}
                {renderTopRight && (
                  <div className="!w-[1px] bg-black/10 h-5 shrink-0"></div>
                )}

                <button
                  className="color-text-description hover:bg-brand-light-card  dark:hover:bg-brand-dark-card flex justify-center items-center h-[32px] w-[32px] rounded-lg cursor-pointer"
                  onClick={onClose}
                  ref={leastDestructiveRef}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>
            </div>
          )}

          <div>{children}</div>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default DefaultModal
