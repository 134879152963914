import { Spinner, useToast } from '@chakra-ui/react'
import {
  faCircleCheck,
  faCog,
  faUserShield,
  faWarning,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { captureException, captureMessage } from '@sentry/react'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import AnimateHeight from '../../../../components/AnimateHeight'
import Loader from '../../../../components/Loader'
import Toast from '../../../../components/Toast'
import {
  useCreateKyBCaseMutation,
  useCreateKycCaseMutation,
  useGetUltimateBeneficialOwnersQuery,
} from '../../../../redux/services/coreApi'
import type { RootState } from '../../../../redux/store'
import { handledErrorQuery } from '../../../../utils/handledError'
import KycPageWrapper from './components/KycPageWrapper'

type StartKycScreenProps = {}

const StartKycScreen: React.FC<StartKycScreenProps> = () => {
  const history = useHistory()

  const user = useSelector((state: RootState) => state.session.user)

  const toast = useToast()

  const [createKycCase, { isLoading: isCreatingKycCase }] =
    useCreateKycCaseMutation()

  const [createKybCase, { isLoading: isCreatingKybCase }] =
    useCreateKyBCaseMutation()

  const [pageState, setPageState] = useState<
    'introduction' | 'pending' | 'success' | 'error' | null
  >(null)

  const {
    data,
    isLoading: isLoadingUbos,
    isSuccess,
    error,
  } = handledErrorQuery(() =>
    useGetUltimateBeneficialOwnersQuery(null, {
      pollingInterval: pageState === 'pending' ? 10000 : 0,
    })
  )

  useEffect(() => {
    if (isSuccess) {
      setPageState('success')
      return
    }

    //@ts-ignore
    if (error?.data?.errors === 'No KYB has been started yet.') {
      setPageState('introduction')
    } else if (
      //@ts-ignore
      error?.data.errors ===
      'Your KYB Case has been created, but is still pending to be verified.'
    ) {
      setPageState('pending')
    } else if (
      //@ts-ignore
      error?.data.errors ===
      'Your company registration number was not found in the list of found companies during your KYB check. Please contact the Penning team.'
    ) {
      setPageState('error')
    } else if (error !== undefined) {
      captureMessage(`Odd error on getting status of KYB Case:
      ${error}`)
      toast({
        position: 'bottom-right',
        render: () => (
          <Toast type="error">
            {
              'Something went wrong when getting the status of your KYB Case, please contact the Penning team.'
            }
          </Toast>
        ),
      })
    }
  }, [error, isSuccess])

  return (
    <KycPageWrapper>
      <div className="bg-white dark:bg-brand-dark-card  p-10 rounded-xl">
        <AnimateHeight isVisible={isLoadingUbos && pageState !== 'pending'}>
          <Spinner size="md" />
        </AnimateHeight>
        <AnimateHeight isVisible={pageState === 'introduction'}>
          <div className="flex flex-col items-center text-center">
            <div className="w-16 h-16 flex items-center justify-center bg-brand-light-background dark:bg-brand-dark-background rounded-full mb-3">
              <FontAwesomeIcon
                icon={faUserShield}
                className="text-brand-blue text-lg dark:text-white"
              />
            </div>

            <h1 className="text-default-large mb-2 dark:text-white">
              {user.role === 'Organization'
                ? 'Welcome to Penning KYB'
                : 'Welcome to Penning KYC'}
            </h1>
            <p className="text-description-large mb-12 max-w-md text-center dark:text-brand-dark-text-description">
              {user.role === 'Individual'
                ? 'Before you can use the platform, you need to provide us with more information. Please click the button below to get an email sent to you with further instructions.'
                : 'Before you can use the platform, you need to provide us with more information. Please continue along in this flow to answer some simple questions about your situation and provide your documents.'}
            </p>
            <div>
              {user.role === 'Organization' ? (
                <button
                  onClick={async () => {
                    try {
                      await createKybCase({})

                      toast({
                        position: 'bottom-right',
                        render: () => (
                          <Toast type="success">
                            {
                              'We have started your KYB process, the wait time is approx. 5-10 minutes before you can continue.'
                            }
                          </Toast>
                        ),
                      })
                    } catch (error) {
                      captureException(error)
                      toast({
                        position: 'bottom-right',
                        render: () => (
                          <Toast type="error">
                            {
                              'Something went wrong when creating your KYB Case, please contact the Penning team.'
                            }
                          </Toast>
                        ),
                      })
                    }
                  }}
                  className="button"
                >
                  {isCreatingKybCase ? <Spinner /> : 'Start KYB process'}
                </button>
              ) : // <Link to="/app/kyc/owners" className="button px-[20px]">
              //   Get started
              // </Link>
              user.kycDetails.shuftiProKycUrl ? (
                <a
                  className="button px-[20px]"
                  href={user.kycDetails.shuftiProKycUrl}
                >
                  Open Shufti Pro to start KYC Process
                </a>
              ) : (
                <button
                  className={`button px-[20px] ${
                    isCreatingKycCase ? 'cursor-wait' : ''
                  }`}
                  disabled={isCreatingKycCase}
                  onClick={async () => {
                    if (isCreatingKycCase) return
                    const res = await createKycCase({}).unwrap()
                    if (res.shuftiProKycUrl) {
                      window.open(res.shuftiProKycUrl)
                    }
                  }}
                >
                  {isCreatingKycCase ? <Loader /> : 'Start KYC Process'}
                </button>
              )}
            </div>
          </div>
        </AnimateHeight>
        <AnimateHeight isVisible={pageState === 'pending'}>
          <div className="flex flex-col items-center text-center">
            <FontAwesomeIcon
              icon={faCog}
              className="text-brand-light-text-default w-8 h-8 text-lg dark:text-white animate-rotate-slow"
            />

            <h1 className="text-default-large mb-2 dark:text-white mt-5">
              Setting up your KYB
            </h1>
            <p className="text-description-large max-w-md text-center dark:text-brand-dark-text-description">
              Please wait while your KYB is being set up. <br /> Estimated wait
              time: 5 - 10 minutes.
            </p>
          </div>
        </AnimateHeight>
        <AnimateHeight isVisible={pageState === 'error'}>
          <div className="flex flex-col items-center text-center">
            <FontAwesomeIcon
              icon={faWarning}
              className="text-brand-light-red w-8 h-8 text-lg dark:text-brand-dark-red "
            />

            <h1 className="text-default-large mb-2 dark:text-white mt-5">
              Something went wrong
            </h1>
            <p className="text-description-large max-w-md text-center dark:text-brand-dark-text-description">
              Your company registration number was not found in the list of
              found companies during your KYB check. Please contact the Penning
              team.
            </p>
          </div>
        </AnimateHeight>
        <AnimateHeight isVisible={pageState === 'success'} durationInS={0.8}>
          <div className="flex flex-col items-center text-center">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="text-brand-light-green w-8 h-8 text-lg dark:text-white "
            />

            <h1 className="text-default-large mb-2 dark:text-white mt-5">
              KYB set up successfully!
            </h1>
            <p className="text-description-large max-w-md text-center dark:text-brand-dark-text-description">
              You are now ready to list the Ultimate Beneficial Owners in your
              company!
            </p>
            <Link to="/app/kyc/owners" className="button mt-10">
              Link Ultimate Beneficial Owners
            </Link>
          </div>
        </AnimateHeight>
      </div>
    </KycPageWrapper>
  )
}

export default StartKycScreen
