import type React from 'react'

interface Props {
  title: string
  value?: string | null
}

const PersonalDetailItem: React.FC<Props> = ({ title, value }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <p className="text-description mb-0.5 md:mb-0 md:w-[180px]">{title}</p>
      <p className="text-default-small">{value}</p>
    </div>
  )
}

export default PersonalDetailItem
