import type React from 'react'

import logo3 from '../assets/H-white-transparent.png'
import { SocialIcon } from './SocialIcon'

type FooterProps = {}

const Footer: React.FC<FooterProps> = ({}) => {
  const legalLinks = [
    {
      title: 'Personal data- and cookie policy',
      href: 'https://www.penning.dk/legal/cookiepolitik',
    },
    {
      title: 'Disclaimer',
      href: 'https://www.penning.dk/legal/ansvarsfraskrivelse',
    },
    { title: 'Copyright', href: 'https://www.penning.dk/legal/ophavsret' },
  ]

  return (
    <footer className="bg-brand-dark-card pt-[100px] md:pt-[150px] relative">
      <div className="column-responsive">
        <div className="col-span-full flex flex-col md:flex-row md:justify-between lg:flex-col lg:col-span-4">
          <div className="md:w-1/2 lg:w-auto">
            <div className="relative w-[200px] md:w-[250px] mb-3">
              <img src={logo3} alt="Penning logo" />
            </div>

            <p className="font-jostRegular text-white text-[16px] w-3/4 md:w-auto ">
              Providing access to financial optimization from the CeDeFi space
            </p>
          </div>

          <div className="flex items-center gap-[10px] mt-3">
            <SocialIcon
              link="https://www.linkedin.com/company/penning-dk/"
              type="linkedin"
            />
            <SocialIcon link="https://twitter.com/Penning_dk" type="twitter" />
            <SocialIcon
              link="https://www.facebook.com/Penning.dk"
              type="facebook"
            />
          </div>
        </div>

        <div className="col-span-full md:col-span-4 lg:col-start-6 lg:col-span-3 mt-[40px] lg:mt-0 flex flex-col">
          <p className="footer-title">CONTACT</p>

          <a
            href="mailto:info@penning.dk"
            target="_blank"
            rel="noreferrer"
            className="footer-item link"
          >
            info@penning.dk
          </a>
        </div>

        <div className="col-span-full md:col-span-4 mt-[40px] lg:mt-0 flex flex-col ">
          <p className="footer-title">ADDRESS</p>
          <p className="footer-item">Penning Group ApS</p>
          <p className="footer-item">C/O ARYZE</p>
          <p className="footer-item">Vester Voldgade 7B</p>
          <p className="footer-item">1552 Copenhagen V</p>
        </div>
      </div>
      <div className="flex flex-col  mt-[50px] md:mt-[90px] px-[30px] justify-center md:items-center text-[12px] font-light text-[#337B7B]">
        <div className="mb-[15px] flex flex-col md:flex-row  gap-[16px] ">
          {legalLinks.map((item, index) => {
            return (
              <a
                key={index}
                href={item.href}
                className="text-[14px] font-jostRegular text-brand-dark-text-description hover:text-white transition-all"
              >
                {item.title}
              </a>
            )
          })}
        </div>
        <div className="text-brand-dark-text-description text-[14px] text-center mt-[30px] mb-[4px] md:mt-[10px]">
          © 2023 Penning A/S. All rights reserved.
        </div>
        <div className="text-brand-dark-text-description text-[14px] text-center mb-[10px]">
          Danish company registry code: 42310352. Penning A/S is registered with
          <a
            className="text-brand-dark-text-description text-[14px] hover:underline"
            href="https://virksomhedsregister.finanstilsynet.dk/virksomhed-under-tilsyn-en.html?v=AAF559B5-8814-ED11-A2DE-005056907186"
            target="_blank"
            rel="noreferrer"
          >
            {' FAIF '}
          </a>
          {' & '}
          <a
            className="text-brand-dark-text-description text-[14px] hover:underline"
            href="https://virksomhedsregister.finanstilsynet.dk/virksomhed-under-tilsyn-en.html?v=5EBB28CD-8E14-ED11-A2DE-005056907186"
            target="_blank"
            rel="noreferrer"
          >
            VASP
          </a>{' '}
          at the Danish Financial Supervisory Authority.
        </div>
      </div>
    </footer>
  )
}

export default Footer
