import { Spinner } from '@chakra-ui/react'
import { faHeart } from '@fortawesome/free-regular-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import { useToggleFavouriteMutation } from '../../../../../../redux/services/coreApi'
import type { CurrencyDto } from '../../../../../../types/coreApi-types'
import ImageAndValue from '../../../Admin/Currencies/components/ImageAndValue'

interface CurrencyRowProps {
  onClick: () => void
  currency: CurrencyDto
  isFavourite?: boolean
  showSubtitle?: boolean
}

const CurrencyRow: React.FC<CurrencyRowProps> = ({
  onClick,
  currency,
  isFavourite,
  showSubtitle,
}) => {
  const [toggleFavourite, { isLoading: isTogglingFavourite }] =
    useToggleFavouriteMutation()

  return (
    <div
      onClick={onClick}
      className="p-2 px-3 rounded-lg hover:bg-gray-100 group transition-all flex items-center justify-between cursor-pointer shrink-0"
    >
      <ImageAndValue
        value={currency.name}
        iconUrl={currency.iconUrl}
        subtitle={showSubtitle ? currency.isoCode : undefined}
      />

      {isTogglingFavourite ? (
        <Spinner size={'sm'} />
      ) : (
        <FontAwesomeIcon
          icon={isFavourite ? faHeartSolid : faHeart}
          onClick={async e => {
            if (isTogglingFavourite) return
            e.stopPropagation()
            await toggleFavourite({
              currencyId: currency.id,
              isFavourite: !isFavourite,
            })
          }}
          className={`transition-all duration-150 w-4 h-4 ${
            isFavourite
              ? 'text-red-500 hover:text-red-600 '
              : 'text-gray-300 hover:text-gray-400 opacity-0 group-hover:opacity-100'
          }`}
        />
      )}
    </div>
  )
}

export default CurrencyRow
