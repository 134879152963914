import type React from 'react'
import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

interface IconUploadDropZoneProps {
  files: File[]
  setFiles: (files: File[]) => void
}

const IconUploadDropZone: React.FC<IconUploadDropZoneProps> = ({
  files,
  setFiles,
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles)
  }, [])
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
        'image/svg+xml': ['.svg'],
      },
      maxFiles: 1,
    })

  return (
    <div
      {...getRootProps()}
      className={`group h-[70px] rounded-lg border-[1.5px] border-dashed border-brand-gray-300 outline-none flex flex-col justify-center items-center gap-3 cursor-pointer !bg-white transition-colors hover:!bg-brand-gray-50 ${
        isDragReject ? 'border-brand-red-400 !bg-brand-red-100' : ''
      } ${isDragAccept ? '!border-brand-accent !bg-[#EEF0FC]' : ''} ${
        isFocused ? 'border-brand-gray-300' : ''
      }`}
    >
      <input {...getInputProps()} />
      {files && files.length > 0 ? (
        <div>{files[0].name}</div>
      ) : (
        <>
          <div className="text-sm">
            Drag & Drop or{' '}
            <span className="group-hover:underline text-brand-accent">
              Choose file
            </span>{' '}
            to upload an icon
          </div>
        </>
      )}
    </div>
  )
}

export default IconUploadDropZone
