import type React from 'react'
import { useHistory } from 'react-router-dom'
import AnimateHeight from './AnimateHeight'
import Loader from './Loader'

interface KYCBannerProps {
  title: string
  description: string
  icon: JSX.Element
  colorCheme: 'blue' | 'yellow' | 'green' | 'red'
  onClick?: () => void
  buttonText?: string
  isVisible: boolean
  isLoading?: boolean
}

const KYCBanner: React.FC<KYCBannerProps> = ({
  buttonText,
  colorCheme,
  description,
  icon,
  onClick,
  title,
  isVisible,
  isLoading,
}) => {
  const history = useHistory()

  const getColorScheme = () => {
    switch (colorCheme) {
      case 'blue':
        return { bg: '#3864FF', description: '#BAC9FF', iconBg: '#3156DC' }
      case 'yellow':
        return { bg: '#F6A62E', description: '#fff', iconBg: '#DF8E15' }
      case 'green':
        return { bg: '#334E4F', description: '#fff', iconBg: '#284041' }
      case 'red':
        return { bg: '#EE4040', description: '#fff', iconBg: '#D42F2F' }
    }
  }

  return (
    <AnimateHeight isVisible={isVisible} delayInMS={700}>
      <div
        style={{ backgroundColor: getColorScheme()?.bg }}
        className="p-4 flex items-center justify-between "
      >
        <div className="flex items-center text-white gap-3">
          <div
            style={{ backgroundColor: getColorScheme()?.iconBg }}
            className="w-12 h-12 shrink-0 flex items-center justify-center rounded-xl"
          >
            {icon}
          </div>
          <div>
            <p className="font-semi text-white text-sm">{title}</p>
            <p
              style={{ color: getColorScheme()?.description }}
              className="font-medium text-xs "
            >
              {description}
            </p>
          </div>
        </div>
        {onClick && (
          <div className="pl-2">
            <button
              disabled={isLoading}
              onClick={onClick}
              className="button-white whitespace-nowrap px-10"
            >
              {isLoading ? <Loader /> : buttonText}
            </button>
          </div>
        )}
      </div>
    </AnimateHeight>
  )
}

export default KYCBanner
