import { YearPickerInput } from '@mantine/dates'
import moment, { Moment } from 'moment'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Calendar03Icon from '../../../../assets/icons/calendar-03-stroke-rounded (1)'
import CDropdownMenu from '../../../../components/CDropdownMenu'
import DropdownCell from '../../../../components/DropdownCell'
import FundDepositActivityItem from '../../../../components/FundDepositActivityItem'
import PageWrapper from '../../../../components/Pagewrapper'
import {
  useGetFundDepositsQuery,
  useGetInvestorFundPerformanceQuery,
} from '../../../../redux/services/coreApi'
import type { RootState } from '../../../../redux/store'
import type {
  DepositDto,
  InvestorFundPerformanceDto,
} from '../../../../types/coreApi-types'
import { colorByPerformance } from '../../../../utils/colorByPerformance'
import { formatNumber } from '../../../../utils/formatters'
import LargeNumber from './components/LargeNumber'
import LineChart from './components/LineChart'

const generateMonths = (year: number) => {
  const months: Moment[] = []
  for (let i = 0; i < 12; i++) {
    months.push(moment([year, i, 1]))
  }
  return months
}

export type InvestorChartData = {
  month: Moment
  data: InvestorFundPerformanceDto
}

const InvestorScreen: React.FC = () => {
  const [selectedCurrency, setSelectedCurrency] = useState<string>('EUR')

  const [year, setYear] = useState<Date | null>(new Date())

  const [investorChartData, setInvestorChartData] = useState<
    InvestorChartData[] | null
  >(null)

  const {
    data: investorFundPerformance,
    isLoading,
    refetch: refetchFundPerformance,
  } = useGetInvestorFundPerformanceQuery({
    start: moment(year).startOf('year').format('YYYY-MM-DD HH:mm:ss'),
    end: moment(year).endOf('year').format('YYYY-MM-DD HH:mm:ss'),
    convertToEur: selectedCurrency === 'EUR',
  })

  useEffect(() => {
    refetchFundPerformance()
  }, [year])

  const sessionUser = useSelector((state: RootState) => state.session.user)

  const { data: fundDepositsData } = useGetFundDepositsQuery({
    investorId: sessionUser.id,
  })

  const lastMonth =
    investorFundPerformance &&
    investorFundPerformance[investorFundPerformance?.length - 1]

  const accountValue = lastMonth?.endOfMonthCapital ?? 0

  const accountPerformance = (lastMonth?.performancePercentToDate ?? 0) * 100

  const investedAmount =
    investorFundPerformance?.reduce(
      (acc, curr) => acc + curr.deposited - curr.withdrawn,
      0
    ) ?? 0

  const moneyDiff = accountValue - investedAmount

  const completedFundDeposits =
    fundDepositsData?.filter(i => i.transferStatus === 'Completed') ?? []

  const sortDepositsByDate = (arr?: DepositDto[]) => {
    if (!arr) return []

    return [...arr]
      .filter(el => moment(el.transferredAt).isSame(moment(year), 'year'))
      .sort(
        (a, b) =>
          new Date(b.transferredAt ?? new Date()).getTime() -
          new Date(a.transferredAt ?? new Date()).getTime()
      )
  }

  useEffect(() => {
    if (!investorFundPerformance || !year) return
    const months = generateMonths(year.getFullYear())

    const mergedData = months.map(month => {
      const monthValue = investorFundPerformance.find(data => {
        return moment(data.date).month() === month.month()
      })

      return { month, data: monthValue } as InvestorChartData
    })

    setInvestorChartData(mergedData)
  }, [investorFundPerformance])

  return (
    <PageWrapper>
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-full lg:col-span-9">
          <YearPickerInput
            leftSection={
              <Calendar03Icon className="w-4 h-4 -mr-1 text-gray-500" />
            }
            leftSectionPointerEvents="none"
            placeholder="Select year"
            value={year}
            onChange={setYear}
            className="w-[100px] mb-2 font-regular"
            radius={'md'}
            size="sm"
          />
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 mb-2">
            <LargeNumber
              title="Invested amount"
              value={`${formatNumber(
                investedAmount,
                { currencyType: 'FiatCurrency', isoCode: selectedCurrency },
                2
              )}`}
              color="text-gray-800"
              isLoading={isLoading}
            />
            <LargeNumber
              title="Account value"
              value={`${formatNumber(
                accountValue,
                { currencyType: 'FiatCurrency', isoCode: selectedCurrency },
                2
              )}`}
              color="text-gray-800"
              isLoading={isLoading}
            />
            <LargeNumber
              title="Overall Account Performance"
              value={`${accountPerformance.toFixed(2)}%`}
              color={colorByPerformance(accountPerformance)}
              isLoading={isLoading}
              renderRight={
                <p
                  className={`text-sm font-medium ${colorByPerformance(
                    moneyDiff
                  )}`}
                >{`${moneyDiff > 0 ? '+' : ''}${formatNumber(
                  moneyDiff,
                  { currencyType: 'FiatCurrency', isoCode: selectedCurrency },
                  2
                )}`}</p>
              }
            />
          </div>
          <div className="grid grid-cols-1">
            {investorChartData?.length ? (
              <LineChart
                data={investorChartData}
                currencyIsoCode={selectedCurrency}
              />
            ) : (
              <div className="bg-brand-light-card text-description flex flex-col items-center justify-center min-h-[500px] dark:bg-brand-dark-card col-span-full relative rounded-xl border-[1px] border-solid border-black/10 dark:border-brand-dark-card-border">
                <p className="font-semi text-sm mb-1">
                  You have no active deposits yet.
                </p>
                <p>Contact our team to learn more.</p>
              </div>
            )}
          </div>
        </div>
        <div className="col-span-full lg:col-span-3 lg:col-start-10">
          <p className="font-semi text-sm mb-1 dark:text-white">
            Activity for current year
          </p>
          <div className="bg-white dark:bg-brand-dark-card h-[450px] overflow-auto border border-solid border-black/10 shadow-sm rounded-xl p-2">
            {!sortDepositsByDate(completedFundDeposits).length && (
              <p className="text-description p-2 self-center">
                You have no active deposits yet.
              </p>
            )}
            {sortDepositsByDate(completedFundDeposits).map((d, i) => {
              return (
                <>
                  {i !== 0 && <div className="my-1.5 h-px bg-black/5" />}

                  <FundDepositActivityItem key={i} deposit={d} />
                </>
              )
            })}
          </div>
          <div className="bg-white dark:bg-brand-dark-card h-[112px] mt-5 overflow-auto border border-solid border-black/10 shadow-sm rounded-xl p-4">
            <CDropdownMenu
              emptyText="Select a currency"
              label="Select currency"
              tooltip="As the fund is traded in USD, we base all performance fee calculations on USD as well. Due the FX Rate differences between EUR and USD, the performance when converted to EUR can differ from the actual fund performance."
              selectedIconSrc={null}
              selectedText={selectedCurrency}
              renderList={['EUR', 'USD'].map((currency, index) => {
                return (
                  <DropdownCell
                    key={index}
                    title={currency}
                    subtitle={''}
                    onClick={() => setSelectedCurrency(currency)}
                    isActive={currency === selectedCurrency}
                    imageName={null}
                  />
                )
              })}
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default InvestorScreen
