import { useAuth0 } from '@auth0/auth0-react'
import { useDisclosure } from '@chakra-ui/react'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PersonalDetailItem from '../../../../components/PersonalDetailItem'
import {
  useGetMembersQuery,
  useGetUserQuery,
} from '../../../../redux/services/coreApi'
import type { RootState } from '../../../../redux/store'
import Member from './components/Member'
import AddMemberModal from './containers/AddMemberModal'

function ProfileScreen() {
  const addMemberModal = useDisclosure()

  const sessionUserId = useSelector((state: RootState) => state.session.id)
  const sessionUserType = useSelector((state: RootState) => state.session.role)

  const { logout, getAccessTokenSilently, getIdTokenClaims } = useAuth0()

  const sessionUser = useSelector((state: RootState) => state.session.user)
  const { data: user } = useGetUserQuery(sessionUser.id)

  const members = useGetMembersQuery(null, {
    skip: user?.role !== 'Organization',
  })

  const isOrganization = sessionUserType === 'Organization'

  useEffect(() => {
    const getAccessToken = async () => {
      // biome-ignore lint/suspicious/noConsole: <only happens in dev>
      console.debug('Bearer ' + (await getAccessTokenSilently()))
    }

    if (import.meta.env.DEV) {
      getAccessToken()
    }
  }, [])

  return (
    <div className="pb-40">
      <AddMemberModal
        isOpen={addMemberModal.isOpen}
        onClose={addMemberModal.onClose}
      />
      <h1 className="h1">Profile</h1>

      <h2 className="h2 mb-2">Account overview</h2>
      <div className="card gap-2 md:gap-4 mb-5">
        <PersonalDetailItem title="Account type" value={user?.role} />
        <PersonalDetailItem
          title={`${isOrganization ? 'KYB status' : 'KYC status'}`}
          value={user?.kycDetails.kycStatus ?? 'Not verified'}
        />
        <PersonalDetailItem title="Email" value={user?.email || ''} />
        <PersonalDetailItem title="Name" value={user?.name || ''} />
        <PersonalDetailItem title="Address" value={user?.address || ''} />
        <PersonalDetailItem title="Phone" value={user?.phone || ''} />
      </div>

      <div className="flex items-center gap-2 mb-3 mt-10">
        {isOrganization && (
          <>
            <h2 className="h2">Users & Owners</h2>
            <button
              onClick={addMemberModal.onOpen}
              className="text-brand-accent dark:text-white cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                size="lg"
                className="rotate-45"
              />
            </button>
          </>
        )}
      </div>
      {isOrganization && (
        <div className="card gap-5">
          {members && members.data?.length === 0 && (
            <div className="flex justify-center text-description">
              You have no members in your organization yet.
            </div>
          )}
          {members &&
            members.data?.map((member, idx) => {
              return <Member key={member.id} member={member} />
            })}
        </div>
      )}
    </div>
  )
}

export default ProfileScreen
