import type React from 'react'
import type { PageTab } from '../types/internal-types'
import MobileTabMenu, { type TabItem } from './MobileTabMenu'
import TabMenu from './TabMenu'

interface PageWrapperProps {
  children: React.ReactNode
  title?: string
  tabs?: PageTab[]
  fixedWidth?: boolean
  customMaxWidth?: string
  description?: string
  mobileTabs?: TabItem[]
  goBack?: () => void
  isLoading?: boolean
}

const PageWrapper: React.FC<PageWrapperProps> = ({
  children,
  title,
  tabs,
  fixedWidth,
  customMaxWidth,
  description,
  mobileTabs,
  goBack,
  isLoading,
}) => {
  return (
    // This is not a sustainable solution with pb
    <div className="h-screen overflow-y-auto pb-40 lg:pb-20 flex flex-col">
      <div className="sticky left-0 top-0 right-0 z-50">
        {title && (
          <TabMenu
            title={title}
            description={description}
            tabs={tabs}
            goBack={goBack}
            isLoading={isLoading}
          />
        )}
        {mobileTabs && <MobileTabMenu tabs={mobileTabs} />}
      </div>

      <div className="flex flex-col items-center h-full">
        <div
          style={{ maxWidth: customMaxWidth }}
          className={`w-full p-3 h-full flex flex-col ${
            fixedWidth ? 'max-w-3xl mt-5' : 'p-5'
          }`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default PageWrapper
