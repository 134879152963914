import { faRightLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'

type Props = {}

const SwapsPlaceholder: React.FC<Props> = ({}) => {
  return (
    <div className="flex flex-col items-center relative">
      <Swap />
      <div className="absolute -mt-7 z-[5] -ml-10">
        <Swap />
      </div>

      <h2 className="h2 mb-2 mt-10">No swaps</h2>
      <p className="text-description text-center">
        You don't have any swaps linked to your account yet.
      </p>
    </div>
  )
}

const Swap = () => {
  return (
    <div className="border-solid border-[1px] z-10 bg-white border-brand-light-card-border dark:bg-brand-dark-background dark:border-brand-dark-card-border shadow-swap-placeholder dark:shadow-none p-3.5 pr-28 rounded-xl space-x-4 flex items-center">
      <div className="w-20 h-5 rounded-md bg-brand-light-card-border dark:bg-brand-dark-card-border"></div>
      <FontAwesomeIcon
        icon={faRightLeft}
        className="text-brand-light-text-description text-xs"
      />
      <div className="w-24 h-5 rounded-md bg-brand-light-card-border dark:bg-brand-dark-card-border"></div>
    </div>
  )
}

export default SwapsPlaceholder
