import { type UseDisclosureProps, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import type React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import DefaultModal from '../../../../../components/DefaultModal'
import FormInput from '../../../../../components/FormInput'
import Toast from '../../../../../components/Toast'
import { useLinkUltimateBeneficialOwnersMutation } from '../../../../../redux/services/coreApi'
import type {
  LinkUltimateBeneficialOwnersItemCommand,
  UserDto,
} from '../../../../../types/coreApi-types'
import { companyShareSchema } from '../../../../../utils/companyShareSchema'

interface UpgradeToUboModalProps {
  modal: UseDisclosureProps
  member: UserDto
}

type FormData = {
  companyShare: string
}

const UpgradeToUboModal: React.FC<UpgradeToUboModalProps> = ({
  modal,
  member,
}) => {
  const schema: yup.SchemaOf<FormData> = yup.object({
    companyShare: companyShareSchema,
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const toast = useToast()
  const [linkUltimateBeneficialOwners, { isLoading: isLinkingUBOs }] =
    useLinkUltimateBeneficialOwnersMutation()

  const onSubmit: SubmitHandler<FormData> = async ({ companyShare }) => {
    const links: LinkUltimateBeneficialOwnersItemCommand[] = []

    if (isNaN(Number.parseFloat(companyShare))) return

    links.push({
      memberId: member.id,
      isoCode: member.country?.isoCode ?? '',
      sharePercentageOfCompany: Number.parseFloat(companyShare),
    })

    await linkUltimateBeneficialOwners({ links }).unwrap()

    toast({
      position: 'bottom-right',
      render: () => (
        <Toast type="success">{`${member.name} has been successfully upgraded to Ultimate Beneficial Owner.`}</Toast>
      ),
    })

    if (modal.onClose) modal.onClose()
  }

  return (
    <DefaultModal
      whiteBG
      title="Enter ownership of UBO"
      isLoadingText="Linking member..."
      isOpen={!!modal.isOpen}
      onClose={() => {
        if (modal.onClose) {
          clearErrors()
          modal.onClose()
          setValue('companyShare', '')
        }
      }}
      isLoading={isLinkingUBOs}
    >
      <div className="flex justify-center">
        <p className=" text-description text-center w-10/12 self-center">
          To upgrade a member to a Ultimate beneficial owner, we need to know
          how many shares the person owns of the company.
        </p>
      </div>

      <form className="p-5" onSubmit={handleSubmit(onSubmit)}>
        <FormInput
          label="Ownership (%)"
          inputMode="text"
          registeredForm={register('companyShare')}
          error={errors?.companyShare}
        />
        <button type="submit" className="button mt-5">
          Upgrade
        </button>
      </form>
    </DefaultModal>
  )
}

export default UpgradeToUboModal
