import * as yup from 'yup'
export const companyShareSchema = yup
  .string()
  .required('This field is required.')
  .test('isNumber', 'It must be a valid number', value =>
    value ? !isNaN(Number.parseInt(value)) : false
  )
  .test(
    'numberBetween0and100',
    'It must be a valid number between 0 and 100',
    value =>
      value
        ? Number.parseInt(value) <= 100 && Number.parseInt(value) > 0
        : false
  )
