import type React from 'react'
import { setTheme } from '../utils/setTheme'

type Props = {}

const ChangeThemeCard: React.FC<Props> = ({}) => {
  return (
    <div>
      <div className="card mb-2">
        <div className="flex gap-4">
          <div onClick={() => setTheme('light')}>
            <div
              className={`h-[90px] w-[180px] overflow-hidden border-solid rounded-lg cursor-pointer border-[3px] border-brand-accent dark:border-transparent transition-all flex justify-end items-end bg-brand-light-background `}
            >
              <div className="bg-brand-light-card h-2/3 w-3/4 rounded-tl-xl p-3">
                <p className="font-semi text-lg text-brand-light-text-default">
                  Aa
                </p>
              </div>
            </div>
            <p className="text-default-small mt-2">Light</p>
          </div>
          <div onClick={() => setTheme('dark')}>
            <div className="h-[90px] w-[180px] overflow-hidden border-solid rounded-lg cursor-pointer border-[3px] dark:border-white border-transparent transition-all flex justify-end items-end bg-brand-dark-background">
              <div className="bg-brand-dark-card h-2/3 w-3/4 rounded-tl-xl p-3">
                <p className="font-semi text-lg text-brand-dark-text-default">
                  Aa
                </p>
              </div>
            </div>
            <p className="text-default-small mt-2">Dark</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChangeThemeCard
