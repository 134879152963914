import type React from 'react'
import { useState } from 'react'
import PageWrapper from '../../../../components/Pagewrapper'

import { useGetSwapsQuery } from '../../../../redux/services/coreApi'
import Table from './components/Table'

import type { TabItem } from '../../../../components/MobileTabMenu'
import SwapMobileCard from './components/SwapMobileCard'
import SwapsPlaceholder from './components/SwapsPlaceholder'
import CreateSwap from './containers/CreateSwap'

type BrokerServiceScreenProps = {}

const BrokerServiceScreen: React.FC<BrokerServiceScreenProps> = ({}) => {
  const { data: swapsData, isLoading: swapsIsLoading } = useGetSwapsQuery(
    null,
    { refetchOnMountOrArgChange: false }
  )

  const [activeTab, setActiveTab] = useState<'create-swap' | 'previous-swaps'>(
    'create-swap'
  )

  const tabs: TabItem[] = [
    {
      title: 'Create swap',
      onClick: () => setActiveTab('create-swap'),
      isActive: activeTab === 'create-swap',
    },
    {
      title: 'Previous swaps',
      onClick: () => setActiveTab('previous-swaps'),
      isActive: activeTab === 'previous-swaps',
    },
  ]

  return (
    <PageWrapper
      title="Broker service"
      description="Create swaps and more."
      mobileTabs={tabs}
    >
      {/* Desktop render */}
      <div className="hidden xl:flex">
        <div className="flex shrink-0 w-[450px] mr-2">
          <CreateSwap />
        </div>
        <Table swaps={swapsData || []} isLoading={swapsIsLoading} />
      </div>

      {/* Mobile render */}
      <div className="xl:hidden">
        {activeTab === 'create-swap' ? (
          <CreateSwap />
        ) : (
          <div className="space-y-3">
            {swapsData &&
              [...swapsData].map((swap, idx) => {
                return <SwapMobileCard key={idx} swap={swap} />
              })}
            {swapsData && swapsData.length === 0 && (
              <div className="mt-20">
                <SwapsPlaceholder />
              </div>
            )}
          </div>
        )}
      </div>
    </PageWrapper>
  )
}

export default BrokerServiceScreen
