import { type Middleware, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { rtkQueryErrorLogger } from './rtkQueryErrorLogger'
import { coreApi } from './services/coreApi'
import sessionReducer from './slices/sessionSlice'
import {
  clientsTableReducer,
  currenciesTableReducer,
  initialState,
  transfersTableReducer,
} from './slices/tableFiltersSlice'

const localStorageMiddleware: Middleware = ({ getState }) => {
  return next => action => {
    const result = next(action)
    localStorage.setItem(
      'applicationState',
      JSON.stringify({
        currenciesTable: getState().currenciesTable,
        transfersTable: getState().transfersTable,
        clientsTable: getState().clientsTable,
      })
    )
    return result
  }
}

const reHydrateStore = () => {
  if (localStorage.getItem('applicationState') !== null) {
    const parsed = JSON.parse(localStorage.getItem('applicationState') ?? '') // re-hydrate the store
    const newState = {
      currenciesTable: { ...initialState, ...parsed?.currenciesTable },
      transfersTable: { ...initialState, ...parsed?.transfersTable },
      clientsTable: { ...initialState, ...parsed?.clientsTable },
    }
    return newState
  }
}

const store = configureStore({
  reducer: {
    session: sessionReducer,
    currenciesTable: currenciesTableReducer,
    transfersTable: transfersTableReducer,
    clientsTable: clientsTableReducer,
    [coreApi.reducerPath]: coreApi.reducer,
  },
  preloadedState: reHydrateStore(),
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      coreApi.middleware,
      rtkQueryErrorLogger,
      localStorageMiddleware,
    ]),
})
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store
