import { faCircleCheck, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'

interface ToastProps {
  children: React.ReactNode
  type: 'success' | 'info' | 'error'
}

const Toast: React.FC<ToastProps> = ({ children, type }) => {
  const getIcon = () => {
    switch (type) {
      case 'success':
        return (
          <FontAwesomeIcon
            icon={faCircleCheck}
            className="text-brand-dark-green text-base dark:text-brand-light-green"
          />
        )
      case 'error':
        return (
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="text-brand-light-red text-base dark:text-brand-dark-red"
          />
        )
    }
  }

  return (
    <div className="px-4 py-3 font-semi bg-brand-dark-background dark:bg-brand-light-background border-2 border-solid border-brand-dark-card-border dark:border-brand-dark-card-border text-white dark:text-brand-light-text-default text-xs rounded-lg flex items-center gap-2">
      {getIcon()}
      {children}
    </div>
  )
}

export default Toast
