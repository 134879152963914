import { useToast } from '@chakra-ui/react'
import type React from 'react'
import { useEffect, useState } from 'react'
import DefaultModal from '../../../../../../components/DefaultModal'
import Toast from '../../../../../../components/Toast'
import TransferStatusBadge from '../../../../../../components/TransferStatusBadge'
import { useUpdateSwapMutation } from '../../../../../../redux/services/coreApi'
import type {
  SwapDto,
  SwapLockedVariable,
  SwapUpdateStatusCommand,
  TransferStatus,
  XToEurLockedVariable,
} from '../../../../../../types/coreApi-types'
import { formatNumber, formatUtcDate } from '../../../../../../utils/formatters'
import StatusBox from './StatusBox'
import SummaryRow from './SummaryRow'

interface TransactionSummaryModalProps {
  isOpen: boolean
  onClose: () => void
  activeSwap: SwapDto | null
  withdrawTransactionHash: string
  setWithdrawTransactionHash: (value: string) => void
  setSwapStatus: (command: SwapUpdateStatusCommand) => Promise<SwapDto>
  setSwapsStatusIsLoading: boolean
  adminNote: string
  setAdminNote: (val: string) => void
  setTempTransferStatus: (status: TransferStatus) => void
  openConfirmModal: () => void
}

const TransactionSummaryModal: React.FC<TransactionSummaryModalProps> = ({
  isOpen,
  onClose,
  activeSwap,
  withdrawTransactionHash,
  setWithdrawTransactionHash,
  setSwapStatus,
  setSwapsStatusIsLoading,
  adminNote,
  setAdminNote,
  setTempTransferStatus,
  openConfirmModal,
}) => {
  const toast = useToast()
  const [updateSwap, { isLoading: updateSwapIsLoading }] =
    useUpdateSwapMutation()

  const [saveMode, setSaveMode] = useState<
    'transaction-hash' | 'admin-note' | null
  >(null)

  const [depositAmountChange, setDepositAmountChange] = useState(
    activeSwap?.deposit?.amount + ''
  )
  const [withdrawAmountChange, setWithdrawAmountChange] = useState(
    activeSwap?.withdraw?.amount + ''
  )

  const [swapRate, setSwapRate] = useState(activeSwap?.rate + '')

  const [lockedVariable, setLockedVariable] =
    useState<SwapLockedVariable | null>(null)

  const [xToEurLockedVariable, setXToEurLockedVariable] =
    useState<XToEurLockedVariable | null>(null)

  useEffect(() => {
    if (activeSwap?.deposit) {
      setDepositAmountChange(activeSwap.deposit.amount.toString())
    }
    if (activeSwap?.withdraw) {
      setWithdrawAmountChange(activeSwap.withdraw.amount.toString())
    }
    if (activeSwap?.rate) {
      setSwapRate(activeSwap.rate.toString())
    }
  }, [activeSwap])

  const depositAmount = activeSwap?.deposit
    ? formatNumber(activeSwap.deposit.amount, activeSwap.deposit.currency)
    : ''

  const depositAmountInEur = activeSwap?.deposit
    ? `(${formatNumber(
        activeSwap.deposit.amount * activeSwap.deposit.currencyToEurRate,
        { currencyType: 'FiatCurrency', isoCode: 'EUR' }
      )})`
    : ''

  const withdrawAmount = activeSwap?.withdraw
    ? formatNumber(activeSwap.withdraw.amount, activeSwap.withdraw.currency)
    : ''

  const withdrawAmountInEur = activeSwap?.withdraw
    ? `(${formatNumber(
        activeSwap.withdraw.amount * activeSwap.withdraw.currencyToEurRate,
        { currencyType: 'FiatCurrency', isoCode: 'EUR' }
      )})`
    : ''

  const bankFee = activeSwap?.deposit ? activeSwap.bankFee * 100 : 0

  const bankFeeInEur = activeSwap?.deposit
    ? formatNumber(
        activeSwap.bankFee *
          activeSwap.deposit.amount *
          activeSwap.deposit.currencyToEurRate,
        { currencyType: 'FiatCurrency', isoCode: 'EUR' }
      )
    : 0

  const baseFee = activeSwap?.deposit ? activeSwap.baseFee * 100 : 0

  const baseFeeInEur = activeSwap?.deposit
    ? formatNumber(
        activeSwap.baseFee *
          activeSwap.deposit.amount *
          activeSwap.deposit.currencyToEurRate,
        { currencyType: 'FiatCurrency', isoCode: 'EUR' }
      )
    : 0

  const isWalletTransfer = (swap?: SwapDto | null) =>
    Boolean(swap?.withdraw?.wallet)
  const isBankTransfer = Boolean(activeSwap?.withdraw?.bankAccount)

  return (
    <DefaultModal
      isLoading={false}
      isOpen={isOpen}
      onClose={onClose}
      title="Transaction summary"
      maxW="600px"
    >
      <div className="py-5">
        {activeSwap?.createdAt && (
          <SummaryRow
            title="Date"
            value={formatUtcDate(activeSwap.createdAt)}
          />
        )}
        {activeSwap?.withdraw && (
          <SummaryRow
            title="Status"
            value={
              <TransferStatusBadge
                transferStatus={activeSwap?.withdraw?.transferStatus}
              />
            }
          />
        )}
        <SummaryRow
          title="Unique ID"
          value={activeSwap?.deposit?.bankDepositUniqueId}
        />
        <SummaryRow
          title="Deposit amount"
          value={`${depositAmount} ${
            activeSwap?.deposit?.currencyToEurRate === 0 ||
            activeSwap?.deposit?.currency.isoCode === 'EUR'
              ? ''
              : depositAmountInEur
          }`}
          modalEditable={{
            title: `Change deposit amount`,
            description: activeSwap?.deposit
              ? `Change amount of ${
                  activeSwap.deposit.currency.isoCode
                } received from ${formatNumber(
                  activeSwap.deposit.amount,
                  activeSwap.deposit.currency
                )} to something else.`
              : '',
          }}
          editable={{
            isLoading: updateSwapIsLoading,
            onClickSave: async () => {
              if (activeSwap && lockedVariable) {
                await updateSwap({
                  id: activeSwap.id,
                  amountIn: Number.parseFloat(depositAmountChange),
                  lockedVariable,
                  xToEurLockedVariable,
                })
              }
            },
            value: depositAmountChange,
            onChangeInput: e => setDepositAmountChange(e.target.value),
          }}
          showLockedVariablePicker={true}
          lockedVariable={lockedVariable}
          setLockedVariable={setLockedVariable}
          xToEurLockedVariable={xToEurLockedVariable}
          setXToEurLockedVariable={setXToEurLockedVariable}
          xToEurRateData={{
            DepositToEurRate: {
              currency: activeSwap?.deposit?.currency,
              rate: activeSwap?.deposit?.currencyToEurRate,
            },
            WithdrawToEurRate: {
              currency: activeSwap?.withdraw?.currency,
              rate: activeSwap?.withdraw?.currencyToEurRate,
            },
          }}
        />
        <SummaryRow
          title="Withdraw amount"
          value={`${withdrawAmount} ${
            activeSwap?.deposit?.currencyToEurRate === 0 ||
            activeSwap?.withdraw?.currency.isoCode === 'EUR'
              ? ''
              : withdrawAmountInEur
          }`}
          modalEditable={{
            title: `Change withdraw amount`,
            description: activeSwap?.withdraw
              ? `Change amount of ${
                  activeSwap.withdraw.currency.isoCode
                } received from ${formatNumber(
                  activeSwap.withdraw.amount,
                  activeSwap.withdraw.currency
                )} to something else.`
              : '',
          }}
          editable={{
            isLoading: updateSwapIsLoading,
            onClickSave: async () => {
              if (activeSwap && lockedVariable) {
                await updateSwap({
                  id: activeSwap.id,
                  amountOut: Number.parseFloat(withdrawAmountChange),
                  lockedVariable,
                  xToEurLockedVariable,
                })
              }
            },
            value: withdrawAmountChange,
            onChangeInput: e => setWithdrawAmountChange(e.target.value),
          }}
          showLockedVariablePicker={true}
          lockedVariable={lockedVariable}
          setLockedVariable={setLockedVariable}
          xToEurLockedVariable={xToEurLockedVariable}
          setXToEurLockedVariable={setXToEurLockedVariable}
          xToEurRateData={{
            DepositToEurRate: {
              currency: activeSwap?.deposit?.currency,
              rate: activeSwap?.deposit?.currencyToEurRate,
            },
            WithdrawToEurRate: {
              currency: activeSwap?.withdraw?.currency,
              rate: activeSwap?.withdraw?.currencyToEurRate,
            },
          }}
        />
        {activeSwap?.deposit && (
          <>
            <SummaryRow
              title="Bank fee"
              value={`${bankFee}% ${
                activeSwap?.deposit.currencyToEurRate === 0
                  ? ''
                  : `(${bankFeeInEur})`
              }`}
            />
            <SummaryRow
              title="Base fee"
              value={`${baseFee}% ${
                activeSwap?.deposit.currencyToEurRate === 0
                  ? ''
                  : `(${baseFeeInEur})`
              }`}
            />
          </>
        )}
        <SummaryRow
          title={`Rate (${activeSwap?.deposit?.currency.isoCode} -> ${activeSwap?.withdraw?.currency.isoCode})`}
          value={`${activeSwap?.rate}`}
          modalEditable={{
            title: `Change rate`,
            description: 'Change rate of the swap.',
          }}
          editable={{
            isLoading: updateSwapIsLoading,
            onClickSave: async () => {
              if (activeSwap && lockedVariable) {
                await updateSwap({
                  id: activeSwap.id,
                  rate: Number.parseFloat(swapRate),
                  lockedVariable,
                  xToEurLockedVariable,
                })
              }
            },
            value: swapRate,
            onChangeInput: e => setSwapRate(e.target.value),
          }}
          showLockedVariablePicker={true}
          lockedVariable={lockedVariable}
          setLockedVariable={setLockedVariable}
          xToEurLockedVariable={xToEurLockedVariable}
          setXToEurLockedVariable={setXToEurLockedVariable}
          xToEurRateData={{
            DepositToEurRate: {
              currency: activeSwap?.deposit?.currency,
              rate: activeSwap?.deposit?.currencyToEurRate,
            },
            WithdrawToEurRate: {
              currency: activeSwap?.withdraw?.currency,
              rate: activeSwap?.withdraw?.currencyToEurRate,
            },
          }}
        />
        {activeSwap?.deposit?.currency.isoCode !== 'EUR' && (
          <SummaryRow
            title={`Rate (${activeSwap?.deposit?.currency.isoCode} -> EUR)`}
            value={`${activeSwap?.deposit?.currencyToEurRate}`}
          />
        )}
        {activeSwap?.withdraw?.currency.isoCode !== 'EUR' && (
          <SummaryRow
            title={`Rate (${activeSwap?.withdraw?.currency.isoCode} -> EUR)`}
            value={`${activeSwap?.withdraw?.currencyToEurRate}`}
          />
        )}

        {isWalletTransfer(activeSwap) && (
          <>
            <SummaryRow
              title="Address"
              value={activeSwap?.withdraw?.wallet?.address || ''}
            />
            {activeSwap?.withdraw?.wallet?.destinationTag && (
              <SummaryRow
                title="Destination tag"
                value={activeSwap?.withdraw?.wallet?.destinationTag || ''}
              />
            )}
            <SummaryRow
              title="Network"
              value={`${activeSwap?.withdraw?.wallet?.network?.name}`}
            />
          </>
        )}
        {activeSwap?.withdraw?.bankAccount?.bankAccountDetails
          .accountNumber && (
          <>
            <SummaryRow
              title="Registration Nr."
              value={`${activeSwap?.withdraw?.bankAccount?.bankAccountDetails.registration}`}
            />
            <SummaryRow
              title="Account Nr."
              value={
                activeSwap?.withdraw?.bankAccount?.bankAccountDetails
                  .accountNumber
              }
            />
          </>
        )}

        {activeSwap?.withdraw?.bankAccount?.bankAccountDetails.iban && (
          <>
            <SummaryRow
              title="BIC"
              value={`${activeSwap?.withdraw?.bankAccount?.bankAccountDetails.bic}`}
            />
            <SummaryRow
              title="IBAN"
              value={activeSwap?.withdraw?.bankAccount?.bankAccountDetails.iban}
            />
          </>
        )}

        {activeSwap && (
          <SummaryRow
            disabled={
              activeSwap?.withdraw?.transactionHash === withdrawTransactionHash
            }
            title="Transaction hash"
            value={activeSwap?.withdraw?.transactionHash}
            editable={
              activeSwap?.withdraw?.transferStatus === 'Processing'
                ? {
                    value: withdrawTransactionHash,
                    onChangeInput: e =>
                      setWithdrawTransactionHash(e.target.value),
                    isLoading:
                      setSwapsStatusIsLoading &&
                      saveMode === 'transaction-hash',
                    onClickSave: async () => {
                      setSaveMode('transaction-hash')
                      await setSwapStatus({
                        swapId: activeSwap?.id,
                        status: activeSwap?.withdraw?.transferStatus,
                        transactionHash: withdrawTransactionHash,
                      })
                    },
                  }
                : undefined
            }
          />
        )}

        <SummaryRow
          title="Admin note"
          disabled={adminNote === activeSwap?.adminNote}
          editable={{
            isLoading: setSwapsStatusIsLoading && saveMode === 'admin-note',
            onChangeTextArea: e => setAdminNote(e.target.value),
            onClickSave: async () => {
              if (!activeSwap || !activeSwap?.withdraw) return

              try {
                setSaveMode('admin-note')
                await setSwapStatus({
                  swapId: activeSwap?.id,
                  status: activeSwap?.withdraw?.transferStatus,
                  adminNote: adminNote,
                })
                toast({
                  position: 'top',
                  render: () => (
                    <Toast type="success">Admin note updated</Toast>
                  ),
                })
              } catch (error) {}
            },
            value: adminNote,
          }}
        />

        <SummaryRow title="Change status">
          <div className="grid grid-cols-4 gap-2">
            <StatusBox
              transferStatus="Pending"
              isActive={activeSwap?.withdraw?.transferStatus === 'Pending'}
              onClick={() => {
                setTempTransferStatus('Pending')
                openConfirmModal()
              }}
            />
            <StatusBox
              transferStatus="Processing"
              isActive={activeSwap?.withdraw?.transferStatus === 'Processing'}
              onClick={() => {
                setTempTransferStatus('Processing')
                openConfirmModal()
              }}
            />
            <StatusBox
              transferStatus="Completed"
              disabled={activeSwap?.withdraw?.transferStatus !== 'Processing'}
              isActive={activeSwap?.withdraw?.transferStatus === 'Completed'}
              onClick={() => {
                setTempTransferStatus('Completed')
                openConfirmModal()
              }}
            />
            <StatusBox
              transferStatus="Cancelled"
              isActive={activeSwap?.withdraw?.transferStatus === 'Cancelled'}
              onClick={() => {
                setTempTransferStatus('Cancelled')
                openConfirmModal()
              }}
            />
          </div>
        </SummaryRow>
      </div>
    </DefaultModal>
  )
}

export default TransactionSummaryModal
