import type React from 'react'
import Tooltip from '../../../../../../components/Tooltip'

interface ImageAndValueProps {
  value: string
  iconUrl?: string
  tooltip?: boolean
  subtitle?: string
}

const ImageAndValue: React.FC<ImageAndValueProps> = ({
  value,
  iconUrl,
  tooltip,
  subtitle,
}) => {
  return (
    <div className="w-min relative flex items-center gap-2 ">
      {iconUrl && (
        <div className="w-9 h-9 p-1 border-[1px] border-solid border-brand-gray-200 rounded-full">
          <img
            className="object-contain rounded-full"
            src={iconUrl}
            alt="placeholder"
          />
        </div>
      )}
      {tooltip ? (
        <Tooltip label={value}>
          <div>{value}</div>
        </Tooltip>
      ) : (
        <div className="whitespace-nowrap">
          <p>{value}</p>
          {subtitle && (
            <p className="text-[10px] font-medium text-brand-light-text-subtle-description -mt-0.5">
              {subtitle}
            </p>
          )}
        </div>
      )}
    </div>
  )
}

export default ImageAndValue
