import { captureException } from '@sentry/react'
import type React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import Download04Icon from '../../../../../assets/download-04-stroke-rounded'
import Loading03Icon from '../../../../../assets/loading-03-stroke-rounded'
import AnimateHeight from '../../../../../components/AnimateHeight'
import DefaultModal from '../../../../../components/DefaultModal'
import { useGetSwapActivitiesQuery } from '../../../../../redux/services/coreApi'
import type { RootState } from '../../../../../redux/store'
import type {
  SwapDto,
  TransferActivityDto,
} from '../../../../../types/coreApi-types'
import downloadSwapInvoice from '../../../../../utils/downloadInvoice'
import { formatNumber, formatUtcDate } from '../../../../../utils/formatters'
import getStatusFromSwap from '../../../../../utils/getStatusFromSwap'
import BankInfoCell from '../components/BankInfoCell'
import BankInfoContainer from '../components/BankInfoContainer'
import StatusModalStep from '../components/StatusModalStep'
import WalletInfoContainer from '../components/WalletInfoContainer'

interface Props {
  isOpen: boolean
  onClose: () => void
  swap: SwapDto
}

function getTitleFromActivity(
  swap: SwapDto,
  transferActivity: TransferActivityDto
): string {
  switch (transferActivity.transferActivityType) {
    case 'Created':
      return 'Swap was created'
    case 'MovedToProcessing':
      return `Received your deposit of ${formatNumber(
        swap.deposit!.amount,
        swap.deposit!.currency
      )}`
    case 'MovedToCompleted':
      let walletString = ''
      if (swap.withdraw?.wallet) {
        walletString = `your ${swap.withdraw.wallet.network?.name} wallet, ${swap.withdraw.wallet.address}, with transaction hash ${swap.withdraw.transactionHash}`
      } else if (swap.withdraw?.bankAccount) {
        const bank = swap.withdraw.bankAccount.bankAccountDetails
        if (bank.iban) {
          walletString = `your bank account IBAN: ${bank.iban}, BIC: ${bank.bic}`
        } else {
          walletString = `your bank account with registration ${bank.registration} and account number ${bank.accountNumber}`
        }
      }
      return `${formatNumber(
        swap.withdraw!.amount,
        swap.withdraw!.currency
      )} has been delivered to ${walletString}.`
    case 'MovedToCancelled':
      return 'Swap cancelled'
    case 'MovedToPending':
      return 'Swap moved to Pending'
  }
}

const StatusModal: React.FC<Props> = ({ isOpen, onClose, swap }) => {
  const { data: swapActivitiesData } = useGetSwapActivitiesQuery(swap.id)
  const [isDownloadingInvoice, setIsDownloadingInvoice] = useState(false)

  const session = useSelector((state: RootState) => state.session)

  const lastSwapActivity =
    swapActivitiesData !== undefined
      ? swapActivitiesData[swapActivitiesData.length - 1]
      : undefined

  return (
    <DefaultModal
      isLoading={false}
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      title="Swap status"
      maxW="700px"
      renderTopRight={
        swap.withdraw?.transferStatus === 'Completed' && (
          <button
            onClick={async () => {
              setIsDownloadingInvoice(true)
              try {
                await downloadSwapInvoice(swap, session.token)
              } catch (e) {
                captureException(e)
              } finally {
                setIsDownloadingInvoice(false)
              }
            }}
            className="px-2 py-1.5 rounded-lg flex items-center gap-1 hover:bg-white text transition-all text-sm font-medium text-brand-light-text-description hover:text-brand-light-text-default"
          >
            {isDownloadingInvoice ? (
              <Loading03Icon className="w-5 h-5 text-inherit animate-spin" />
            ) : (
              <Download04Icon className="w-5 h-5 text-inherit" />
            )}
            Download receipt
          </button>
        )
      }
    >
      <div className="p-5 pt-0 space-y-5">
        {swapActivitiesData?.map(activity => (
          <StatusModalStep
            key={activity.id}
            state={'completed'}
            title={getTitleFromActivity(swap, activity)}
            description={formatUtcDate(activity.createdAt)}
          />
        ))}

        {lastSwapActivity &&
          lastSwapActivity.transferActivityType !== 'MovedToCompleted' && (
            <StatusModalStep
              state="current"
              title={getStatusFromSwap(swap).title}
              description={getStatusFromSwap(swap).description}
            />
          )}
      </div>

      <div className="h-[2px] w-full bg-[#E7EBEF] dark:bg-brand-dark-card-border"></div>

      <div className="p-5 flex gap-5 flex-wrap">
        <BankInfoCell title="Conversion rate" value={`${swap.rate}`} />
        <BankInfoCell title="Base fee" value={`${swap.baseFee * 100}%`} />
        <BankInfoCell title="Bank fee" value={`${swap.bankFee * 100}%`} />
      </div>

      <div className="h-[2px] w-full bg-[#E7EBEF] dark:bg-brand-dark-card-border"></div>

      {swap && getStatusFromSwap(swap).transferStatus === 'Pending' && (
        <AnimateHeight delayInMS={400} isVisible>
          {swap.deposit?.bankAccount ? (
            <BankInfoContainer
              depositUniqueId={swap.deposit?.bankDepositUniqueId}
              depositCurrency={swap.deposit.currency}
              transferAmount={formatNumber(
                swap.deposit.amount,
                swap.deposit.currency
              )}
              accountInfo={swap.deposit?.bankAccount}
            />
          ) : swap.deposit?.wallet ? (
            <WalletInfoContainer
              transferAmount={formatNumber(
                swap.deposit.amount,
                swap.deposit.currency
              )}
              wallet={swap.deposit?.wallet}
            />
          ) : null}
        </AnimateHeight>
      )}

      <div className="p-5 pt-0">
        <button onClick={onClose} className="button">
          Close
        </button>
      </div>
    </DefaultModal>
  )
}

export default StatusModal
