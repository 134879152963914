import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AnimatePresence, motion } from 'framer-motion'
import type React from 'react'
import LoadingOverlay from '../../../components/LoadingOverlay'

interface FormProps {
  title?: string
  direction: 'fromLeft' | 'fromRight'
  onPressBack?: () => void
  backTitle?: string
  isLoading?: boolean
  children?: JSX.Element | JSX.Element[]
}

const Form: React.FC<FormProps> = ({
  children,
  direction,
  title,
  onPressBack,
  backTitle,
  isLoading,
}) => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, x: direction === 'fromLeft' ? -30 : 30 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: direction === 'fromLeft' ? -30 : 30 }}
        transition={{ ease: 'easeOut', duration: 0.4 }}
        className="flex flex-col w-full max-w-[550px] p-10 rounded-2xl overflow-y-auto bg-brand-light-card dark:bg-brand-dark-card relative"
      >
        {isLoading && <LoadingOverlay title="Loading.." />}
        {onPressBack && (
          <div
            onClick={onPressBack}
            className="flex items-center color-text-description hover:text-black dark:hover:text-white cursor-pointer text-[13px] gap-2 mb-4"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="text-xs" />
            {backTitle}
          </div>
        )}
        {title && (
          <div className="mb-5">
            <h1 className="text-lg font-semi text-brand-light-text-default dark:text-white mb-1">
              {title}
            </h1>
          </div>
        )}

        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default Form
