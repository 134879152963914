import {
  Tooltip as ChakraTooltip,
  type PlacementWithLogical,
} from '@chakra-ui/react'
import type React from 'react'

interface Props {
  label: React.ReactNode
  children: JSX.Element
  placement?: PlacementWithLogical
  closeOnClick?: boolean
  maxWidth?: number
}

const Tooltip: React.FC<Props> = ({
  label,
  children,
  placement,
  closeOnClick,
  maxWidth,
}) => {
  const isDarkMode =
    document.body.getAttribute('theme') === 'dark' &&
    localStorage.getItem('color-theme') === 'dark'

  return (
    <ChakraTooltip
      fontFamily={'GeneralSans-Medium'}
      fontSize="13px"
      px="12px"
      py="8px"
      closeOnClick={closeOnClick}
      shadow={isDarkMode ? 'none' : 'menu'}
      border={`1px solid ${isDarkMode ? '#1D2A37' : '#EAEDF3'}`}
      bg={isDarkMode ? '#1A2128' : '#FFF'}
      color={isDarkMode ? '#fff' : '#171F2C'}
      rounded={'8px'}
      placement={placement}
      label={label}
      maxWidth={maxWidth || 500}
    >
      <div className="flex items-center gap-2">{children}</div>
    </ChakraTooltip>
  )
}

export default Tooltip
