import type React from 'react'
import type { TransferStatus } from '../types/coreApi-types'

interface TransferStatusBadgeProps {
  transferStatus: TransferStatus
}

const TransferStatusBadge: React.FC<TransferStatusBadgeProps> = ({
  transferStatus,
}) => {
  const getClassName = () => {
    switch (transferStatus) {
      case 'Pending':
        return 'status-badge-pending'
      case 'Processing':
        return 'status-badge-processing'
      case 'Completed':
        return 'status-badge-success'
      case 'Cancelled':
        return 'status-badge-cancelled'
      default:
        return ''
    }
  }

  return <div className={`${getClassName()}`}>{transferStatus}</div>
}

export default TransferStatusBadge
