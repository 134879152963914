import type { UseToastOptions } from '@chakra-ui/react'
import {
  type Middleware,
  type MiddlewareAPI,
  isRejectedWithValue,
} from '@reduxjs/toolkit'
import { captureException } from '@sentry/react'
import Toast from '../components/Toast'

let toast: ((options: UseToastOptions) => any) | null = null
const handledErrors = new Set<string>()

export const bindHooksToRTKErrorLogger = (_toast: any) => {
  toast = _toast
}
export const addRtkQueryHandledError = (handledErrorRequestId: string) => {
  // Only setTimeout for cleanup on first call (might get call repeatededly on re-renders a bunch)
  // This way we don't have a bunch of timeouts
  if (!handledErrors.has(handledErrorRequestId)) {
    setTimeout(() => {
      handledErrors.delete(handledErrorRequestId)
    }, 1000)
  }

  handledErrors.add(handledErrorRequestId)
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => next => action => {
    if (isRejectedWithValue(action)) {
      if (toast !== null && action.payload.status !== 401) {
        const requestId = action?.meta?.requestId
        if (requestId && handledErrors.has(requestId)) {
          return next(action)
        }
        captureException(new Error(JSON.stringify(action)))

        let errorDescription = action.payload?.data?.errors

        if (!errorDescription) {
          switch (action.payload.status) {
            case 401:
              errorDescription =
                'Unauthenticated. If this is an error, please try to log out and log back in again.'
              break
            case 403:
              errorDescription =
                'You are not authorized to perform this action.'
              break
            case 404:
              errorDescription = 'Entity was not found.'
              break
            default:
              errorDescription =
                'Unknown error' +
                (action.payload?.status ? ' - ' + action.payload.status : '')
          }
        } else if (typeof errorDescription !== 'string') {
          errorDescription = JSON.stringify(errorDescription)
        }

        toast({
          position: 'top-right',
          render: () => {
            return <Toast type="error">{errorDescription}</Toast>
          },
        })
      }
    }

    return next(action)
  }
