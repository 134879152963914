import type React from 'react'
import CoinsBitcoinIcon from '../../../../../../assets/icons/coins-bitcoin-stroke-rounded'
import CoinsEuroIcon from '../../../../../../assets/icons/coins-euro-stroke-rounded'

interface TabButtonProps {
  title: string
  onClick: () => void
  active: boolean
  icon: 'crypto' | 'fiat'
}

const TabButton: React.FC<TabButtonProps> = ({
  title,
  onClick,
  active,
  icon,
}) => {
  const getIcon = () => {
    switch (icon) {
      case 'crypto':
        return <CoinsBitcoinIcon className={`w-4 h-4 text-inherit`} />
      case 'fiat':
        return <CoinsEuroIcon className={`w-4 h-4 text-inherit`} />
    }
  }

  return (
    <button
      onClick={onClick}
      className={`px-3 py-1.5 border flex items-center gap-1.5 border-solid rounded-full font-medium text-sm transition-all select-none ${
        active
          ? 'bg-[#2C383B] border-transparent text-white'
          : 'hover:bg-gray-100 text-[#576372] border-[#E7E7E9]'
      }`}
    >
      {getIcon()}

      {title}
    </button>
  )
}

export default TabButton
