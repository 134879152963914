import type { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'

interface AccountTypeItemProps {
  title: string
  description: string
  icon: IconDefinition
  isActive: boolean
  onClick: () => void
}

const AccountTypeItem: React.FC<AccountTypeItemProps> = ({
  title,
  description,
  icon,
  isActive,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex bg-brand-light-background dark:bg-brand-dark-background p-4 rounded-lg cursor-pointer border-2 border-solid  ${
        isActive
          ? 'border-brand-accent dark:border-white'
          : 'border-brand-light-background dark:border-brand-dark-card-border'
      }`}
    >
      <div className="w-[34px]">
        <FontAwesomeIcon
          icon={icon}
          className={`text-base ${
            isActive
              ? 'text-brand-accent dark:text-white'
              : 'text-brand-light-text-default dark:text-white'
          }`}
        />
      </div>
      <div>
        <p
          className={`font-semi text-sm mt-0.5 mb-2 select-none ${
            isActive
              ? 'text-brand-accent dark:text-white'
              : 'text-brand-light-text-default dark:text-white'
          }`}
        >
          {title}
        </p>
        <p className="text-description select-none">{description}</p>
      </div>
    </div>
  )
}

export default AccountTypeItem
