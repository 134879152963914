import type React from 'react'
import { useEffect, useState } from 'react'
import type { WalletDto } from '../../../../../types/coreApi-types'
import BankInfoCell from './BankInfoCell'

interface WalletInfoContainerProps {
  transferAmount?: string
  wallet?: WalletDto
}

const WalletInfoContainer: React.FC<WalletInfoContainerProps> = ({
  transferAmount,
  wallet,
}) => {
  const [showIdWarning, setShowIdWarning] = useState<boolean>(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowIdWarning(true)
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [setShowIdWarning])

  return (
    <div className="p-5">
      <div className="flex gap-5 flex-wrap">
        <BankInfoCell title="Amount" value={transferAmount} copy />
        <BankInfoCell title="Address" value={wallet?.address} copy />
        {wallet?.destinationTag && (
          <BankInfoCell
            title="Destination tag"
            value={wallet.destinationTag}
            copy
          />
        )}
        <BankInfoCell title="Network" value={wallet?.network?.name} copy />
      </div>

      {/* <div className="pt-6">
        <div className="flex items-center gap-2">
          <p className="text-description">Description</p>
        </div>
        <CopyTextWrapper text={depositUniqueId || ''}>
          <div className="mt-2.5 mb-3 bg-brand-light-card dark:bg-brand-dark-card text-default py-2.5 px-4 rounded-md flex items-center justify-between cursor-pointer">
            {depositUniqueId}
            <span className="text-brand-accent dark:text-white ml-4">
              <FontAwesomeIcon icon={faCopy} size="lg" />
            </span>
          </div>
        </CopyTextWrapper>
        <AnimateHeight isVisible={showIdWarning}>
          <MessageBox
            type="warning"
            title="IMPORTANT"
            description="Include the code above in the description / text of your bank transfer.  This is used to verify your transaction."
          />
        </AnimateHeight>
      </div> */}
    </div>
  )
}

export default WalletInfoContainer
