import moment from 'moment'
import type React from 'react'
import type { DepositDto } from '../types/coreApi-types'
import { formatNumber } from '../utils/formatters'

interface FundDepositActivityItemProps {
  deposit: DepositDto
}

const FundDepositActivityItem: React.FC<FundDepositActivityItemProps> = ({
  deposit,
}) => {
  return (
    <div
      className={`bg-white dark:bg-brand-dark-card p-2 rounded-md flex justify-between items-center`}
    >
      <div>
        <div className="flex">
          <p className="font-semi text-xs dark:text-white">
            {moment(deposit.transferredAt).format('DD MMM YYYY')}
          </p>
          <p className="ml-2 italic text-xs opacity-70 dark:text-white">
            (effective from{' '}
            {moment(deposit.transferredAt).add(1, 'M').format('MMMM')})
          </p>
        </div>
        <p className="text-description">
          You deposited:{' '}
          {formatNumber(
            deposit.amount,
            {
              currencyType: deposit.currency.currencyType,
              isoCode: deposit.currency.isoCode,
            },
            2
          )}
        </p>
      </div>
    </div>
  )
}

export default FundDepositActivityItem
