import { linearGradientDef } from '@nivo/core'
import { type Datum, ResponsiveLine } from '@nivo/line'
import type React from 'react'
import TooltipRow from '../../../../../components/TooltipRow'
import { colorByPerformance } from '../../../../../utils/colorByPerformance'
import { formatNumber } from '../../../../../utils/formatters'
import { InvestorChartData } from '../InvestorScreen'

interface LineChartProps {
  data?: InvestorChartData[]
  currencyIsoCode?: string
}

const LineChart: React.FC<LineChartProps> = ({ data, currencyIsoCode }) => {
  return (
    <div className="bg-brand-light-card dark:bg-brand-dark-card col-span-full relative rounded-xl border-[1px] border-solid border-black/10 dark:border-brand-dark-card-border">
      <div className="h-[500px]">
        <ResponsiveLine
          data={[
            {
              id: 'Id',
              data:
                data?.map(
                  (item, i) =>
                    ({
                      x: item.month.format('MMMM'),
                      y: item.data?.endOfMonthCapital ?? null,
                      realIndex: i,
                    }) as Datum
                ) ?? [],
            },
          ]}
          enableSlices="x"
          enableArea={true}
          enableGridX={true}
          enableGridY={true}
          colors="#29383B"
          margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
          defs={[
            linearGradientDef('gradientA', [
              { offset: 0, color: 'inherit' },
              { offset: 100, color: 'inherit', opacity: 0 },
            ]),
          ]}
          fill={[{ match: '*', id: 'gradientA' }]}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
            stacked: true,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisTop={null}
          animate={false}
          axisRight={null}
          tooltip={point => {
            return (
              <div className="bg-black text-white">
                {point.point.data.y.toString()}
              </div>
            )
          }}
          sliceTooltip={point => {
            //@ts-ignore
            const index = point.slice.points[0].data.realIndex

            const currMonth = data ? data[index].data : undefined

            const monthlyPerformancePercent = currMonth?.performancePercent ?? 0

            const performancePercentToDate =
              (currMonth?.performancePercentToDate ?? 0) * 100

            const endOfMonthCapital = currMonth?.endOfMonthCapital ?? 0

            return (
              <div className="bg-brand-light-card border dark:bg-brand-dark-background border-solid border-brand-light-card-border dark:border-brand-dark-card-border shadow-lg px-4 py-3 rounded-lg text-sm">
                <p className="font-semi flex justify-between">
                  <p className="dark:text-white">
                    {point.slice.points[0].data.x.toString()}
                  </p>

                  <span
                    className={`font-semi ${colorByPerformance(
                      monthlyPerformancePercent
                    )}`}
                  >
                    {(monthlyPerformancePercent * 100).toFixed(2)}%{' '}
                  </span>
                </p>
                <TooltipRow
                  description="Account value"
                  value={
                    <span>
                      <span className={colorByPerformance(endOfMonthCapital)}>
                        {formatNumber(
                          endOfMonthCapital,
                          {
                            currencyType: 'FiatCurrency',
                            isoCode: currencyIsoCode ?? 'EUR',
                          },
                          2
                        )}
                      </span>{' '}
                      <span
                        className={`${colorByPerformance(
                          performancePercentToDate
                        )}`}
                      >
                        ({performancePercentToDate.toFixed(2)}
                        %)
                      </span>
                    </span>
                  }
                />
                {!!currMonth?.performanceFee && (
                  <TooltipRow
                    description="Performance fee"
                    value={formatNumber(
                      currMonth?.performanceFee,
                      {
                        currencyType: 'FiatCurrency',
                        isoCode: currencyIsoCode ?? 'EUR',
                      },
                      2
                    )}
                  />
                )}
                {!!currMonth?.deposited && (
                  <TooltipRow
                    description="Deposited this month"
                    value={formatNumber(
                      currMonth?.deposited,
                      {
                        currencyType: 'FiatCurrency',
                        isoCode: currencyIsoCode ?? 'EUR',
                      },
                      2
                    )}
                  />
                )}
              </div>
            )
          }}
          pointSize={5}
          pointColor="#29383B"
          pointLabelYOffset={-12}
          useMesh={true}
        />
      </div>
    </div>
  )
}

export default LineChart
