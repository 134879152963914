import type React from 'react'
import type { FieldError, UseFormRegisterReturn } from 'react-hook-form'

interface FormInputProps {
  id?: string
  renderLabel: JSX.Element | string
  error?: FieldError | { message: string }
  registeredForm?: UseFormRegisterReturn
  disabled?: boolean
  clickableLabel?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  checked?: boolean
}

const FormCheckbox: React.FC<FormInputProps> = ({
  renderLabel,
  error,
  registeredForm,
  disabled,
  id,
  clickableLabel,
  onChange,
  checked,
}) => {
  return (
    <div className="space-y-1">
      <div className="flex items-center">
        <input
          className="appearance-none disabled:cursor-not-allowed h-4 w-4 mt-0.5 border border-gray-300 dark:border-brand-dark-card-border rounded-sm bg-white dark:bg-brand-dark-background checked:bg-brand-accent checked:border-brand-accent dark:checked:bg-brand-accent dark:checked:border-brand-accent focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          type="checkbox"
          disabled={disabled}
          id={id}
          onChange={onChange}
          checked={checked}
          {...registeredForm}
        />
        <label
          className={`text-description select-none pt-0.5 ${
            id ? 'cursor-pointer ' : ''
          }`}
          htmlFor={id}
        >
          {renderLabel}
        </label>
      </div>
      {error && (
        <div className="flex items-center gap-1 text-warning">
          {/* <FontAwesomeIcon icon={faExclamation} /> */}
          <span className="">{error.message}</span>
        </div>
      )}
    </div>
  )
}

export default FormCheckbox
