import * as yup from 'yup'

const validator: () => yup.StringSchema<string | undefined> = () =>
  yup
    .string()
    .min(8, 'The password is too short.')
    .max(30, 'The password is too long.')
    .test(
      'containsUpperCase',
      'The password need at least one big character.',
      (item: string | undefined) =>
        item !== undefined && item.toLowerCase() !== item
    )
    .test(
      'containsLowerCase',
      'The password need at least one small character',
      (item: string | undefined) =>
        item !== undefined && item.toUpperCase() !== item
    )
    .matches(/\d/, 'The password need at least one number')

export default validator
