import { motion } from 'framer-motion'
import type React from 'react'
import { useEffect, useState } from 'react'

interface Props {
  isVisible: boolean
  opacity?: number
  children?: React.ReactNode
  delayInMS?: number
}

const AnimateWidth: React.FC<Props> = ({
  isVisible,
  children,
  delayInMS,
  opacity,
}) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setVisible(isVisible)
    }, delayInMS)
  }, [isVisible])

  const variants = {
    open: {
      opacity: 1,
      width: 'auto',
    },
    collapsed: { opacity: opacity ?? 0, width: 0 },
  }

  return (
    <motion.div
      className="overflow-hidden"
      initial={visible ? 'open' : 'collapsed'}
      animate={visible ? 'open' : 'collapsed'}
      inherit={false}
      variants={variants}
      transition={{ duration: 0.35, ease: 'easeOut' }}
    >
      {children}
    </motion.div>
  )
}

export default AnimateWidth
