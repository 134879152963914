import type {
  BrokerPerformanceDto,
  CurrencyDto,
} from '../../../../../../types/coreApi-types'
import PerformanceData from './PerformanceData'

interface PerformanceStepProps {
  currency: CurrencyDto
  performance?: BrokerPerformanceDto
}

const PerformanceStep: React.FC<PerformanceStepProps> = ({
  currency,
  performance,
}) => {
  return (
    <>
      {performance?.subPerformances.map((performanceItem, index) => (
        <div className="w-full lg:w-1/3 gap-4 p-6  lg:p-0 flex flex-col lg:border-none border-b border-solid border-brand-light-card-border">
          <div className="flex items-center ">
            <p
              className={`rounded-full text-sm   px-3 py-1.5 font-medium  ${
                performanceItem.status === 'Pending'
                  ? 'text-[#E85300] bg-[#FFE8D7]'
                  : performanceItem.status === 'Processing'
                    ? 'text-brand-blue bg-brand-blue-tint'
                    : performanceItem.status === 'Completed'
                      ? 'text-brand-light-green bg-brand-light-green-tint'
                      : ''
              }`}
            >
              {performanceItem.status}
            </p>
            <div
              className={`h-[1px] bg-brand-gray-200 hidden lg:block ${
                index === 2 ? 'w-0' : 'w-full'
              }`}
            ></div>
          </div>
          <PerformanceData
            title="Total inflow"
            amount={performanceItem.inflow}
            currency={currency}
          />
          <PerformanceData
            title="Total fees"
            amount={performanceItem.totalFees}
            currency={currency}
          />
        </div>
      ))}
    </>
  )
}

export default PerformanceStep
