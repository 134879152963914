import { useDisclosure, useToast } from '@chakra-ui/react'
import { faBoltLightning } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as Sentry from '@sentry/react'
import type React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import KYCBanner from './components/KYCBanner'
import PhoneNumberModal from './components/PhoneNumberModal'
import SuspendedAccountModal from './components/SuspendedAccountModal'
import TemporaryServiceDisruptionModal from './components/TemporaryServiceDisruptionModal'
import WelcomeModal from './components/WelcomeModal'
import { NODE_ENV } from './constants'
import AppAuthorizationChecker from './containers/AppAuthorizationChecker'
import {
  useGetUserQuery,
  useResendVerificationEmailMutation,
} from './redux/services/coreApi'
import type { RootState } from './redux/store'
import AppMenu from './screens/App/components/AppMenu'
import AccountScreen from './screens/App/screens/Account/AccountScreen'
import ClientScreen from './screens/App/screens/Admin/Clients/ClientScreen'
import ClientsScreen from './screens/App/screens/Admin/Clients/ClientsScreen'
import CurrenciesScreen from './screens/App/screens/Admin/Currencies/CurrenciesScreen'
import FundScreen from './screens/App/screens/Admin/Fund/FundScreen'
import PerformanceScreen from './screens/App/screens/Admin/Performance/PerformanceScreen'
import TransfersScreen from './screens/App/screens/Admin/Transfers/TransfersScreen'
import BankAccountsScreen from './screens/App/screens/BankAccounts/BankAccountsScreen'
import BrokerServiceScreen from './screens/App/screens/Broker Service/BrokerServiceScreen'
import GetStartedScreen from './screens/App/screens/Get Started/GetStartedScreen'
import InvestorScreen from './screens/App/screens/Investor/InvestorScreen'
import KycNavigator from './screens/App/screens/Kyc/KycNavigator'
import WalletsScreen from './screens/App/screens/Wallets/WalletsScreen'

const AppNavigator: React.FC = () => {
  const { path } = useRouteMatch()
  const toast = useToast()

  const betaModalHasBeenOpen = useRef(false)
  const betaModal = useDisclosure()
  const temporaryServiceDisruptionModal = useDisclosure()
  const phoneNumberModal = useDisclosure()
  const suspendedAccountModal = useDisclosure()

  const history = useHistory()

  const sessionUser = useSelector((state: RootState) => state.session.user)
  const user = useGetUserQuery(sessionUser.id)

  const isEmailverified = useSelector(
    (state: RootState) => state.session.auth0user?.email_verified
  )

  const [showBanner, setShowBanner] = useState(false)

  useEffect(() => {
    Sentry.setContext('user', {
      id: sessionUser.id,
      role: sessionUser.role,
      name: sessionUser.name,
    })
  }, [user.data])

  useEffect(() => {
    setTimeout(() => {
      if (sessionUser.role !== 'Admin') {
        if (NODE_ENV !== 'development') {
          betaModal.onOpen()
          betaModalHasBeenOpen.current = true
        }
      }
    }, 1000)
  }, [betaModal.onOpen, sessionUser.role])

  useEffect(() => {
    // Only after the betaModal has been closed
    if (
      (betaModalHasBeenOpen.current && !betaModal.isOpen) ||
      NODE_ENV === 'development'
    ) {
      // Only if we have User Data (async, might come later)
      if (user.data) {
        if (!user.data.phone) {
          phoneNumberModal.onOpen()
        } else if (user.data.isSuspended) {
          suspendedAccountModal.onOpen()
        } else {
          // temporaryServiceDisruptionModal.onOpen()
        }
      }
    }
  }, [
    user.data,
    betaModal.isOpen,
    phoneNumberModal.onOpen,
    suspendedAccountModal.onOpen,
  ])

  useEffect(() => {
    const kycStatus = user.data?.kycDetails.kycStatus

    const unregisterHistory = history.listen(loc => {
      if (
        loc.pathname.includes('/get-started') ||
        loc.pathname.includes('/kyc/')
      ) {
        setShowBanner(false)
      } else {
        if (kycStatus !== 'Approved') {
          setShowBanner(true)
        }
      }
    })

    return () => {
      unregisterHistory()
    }
  }, [user.data?.kycDetails.kycStatus, history])

  const [resendVerificationEmail, { isLoading }] =
    useResendVerificationEmailMutation()

  const [hasSentMail, setHasResendMail] = useState(false)

  const role = sessionUser.role === 'Organization' ? 'KYB' : 'KYC'

  return (
    <div className="h-screen flex flex-col w-full md:flex-row ">
      <WelcomeModal isOpen={betaModal.isOpen} onClose={betaModal.onClose} />
      <TemporaryServiceDisruptionModal
        isOpen={temporaryServiceDisruptionModal.isOpen}
        onClose={temporaryServiceDisruptionModal.onClose}
      />
      <PhoneNumberModal
        isOpen={phoneNumberModal.isOpen}
        onClose={phoneNumberModal.onClose}
      />
      <SuspendedAccountModal
        isOpen={suspendedAccountModal.isOpen}
        onClose={suspendedAccountModal.onClose}
      />
      <AppMenu />
      <div className="w-full bg-brand-light-background dark:bg-brand-dark-background transition-all h-screen relative">
        <div className="sticky left-0 top-0 right-0 z-50">
          <KYCBanner
            isVisible={showBanner && sessionUser.role !== 'Admin'}
            title="Finish your account setup"
            description="Before you can use the dashboard properly, we need you to complete the Get Started flow."
            buttonText="Continue setup"
            colorCheme="blue"
            icon={
              <FontAwesomeIcon icon={faBoltLightning} className="text-base" />
            }
            onClick={async () => {
              history.push(`${path}/get-started`)
              // setBanner(undefined)
            }}
          />
        </div>
        <Switch>
          <Route exact path={`${path}/`}>
            {sessionUser?.role === 'Admin' ? (
              <Redirect to={`${path}/admin/clients`} />
            ) : (
              <Redirect to={`${path}/get-started`} />
            )}
          </Route>

          <Route path={`${path}/kyc`}>
            <KycNavigator />
          </Route>

          <Route path={`${path}/get-started`}>
            <GetStartedScreen />
          </Route>
          <Route path={`${path}/broker-service`}>
            <BrokerServiceScreen />
          </Route>
          <Route path={`${path}/wallets`}>
            <WalletsScreen />
          </Route>
          <Route path={`${path}/bank-accounts`}>
            <BankAccountsScreen />
          </Route>
          <Route path={`${path}/investor`}>
            <InvestorScreen />
          </Route>
          <Route path={`${path}/account`}>
            <AccountScreen />
          </Route>
          <Route path={`${path}/admin/transfers`}>
            <TransfersScreen />
          </Route>
          <Route path={`${path}/admin/clients/:id`}>
            <ClientScreen />
          </Route>
          <Route path={`${path}/admin/clients`}>
            <ClientsScreen />
          </Route>
          <Route path={`${path}/admin/currencies`}>
            <CurrenciesScreen />
          </Route>
          <Route path={`${path}/admin/Performance`}>
            <PerformanceScreen />
          </Route>
          <Route path={`${path}/admin/fund`}>
            <FundScreen />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

// TODO: This could probably be done cleaner
const WrappedWithAuthorization: React.FC = () => {
  return (
    <AppAuthorizationChecker>
      <AppNavigator />
    </AppAuthorizationChecker>
  )
}

export default WrappedWithAuthorization
