import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import { useHistory } from 'react-router-dom'
import AnimateHeight from './AnimateHeight'
import Tooltip from './Tooltip'

export interface MenuItemProps {
  isActive: boolean
  onClick: () => void
  title: string
  icon: JSX.Element
  comingSoon?: boolean
  links?: { title: string; path: string }[]
  isNewFeature?: boolean
}

const MenuItem: React.FC<MenuItemProps> = ({
  isActive,
  onClick,
  title,
  icon,
  comingSoon,
  links,
  isNewFeature,
}) => {
  const isDynamicSidebarActivated = () => {
    return localStorage.getItem('dynamic-sidebar') === 'on'
  }
  const history = useHistory()

  return (
    <>
      <div
        className={`w-full transition-all text-white h-[40px] px-3 rounded-xl mb-1.5 flex items-center select-none group   ${
          isActive
            ? links
              ? ''
              : 'bg-brand-app-menu-hover  dark:bg-brand-dark-background'
            : 'bg-brand-app-menu hover:bg-brand-app-menu-hover/50 dark:bg-brand-dark-card'
        } ${comingSoon ? 'opacity-40 ' : 'cursor-pointer'}`}
        onClick={!comingSoon ? onClick : undefined}
      >
        <Tooltip
          label={isDynamicSidebarActivated() ? title : ''}
          placement="right"
        >
          <div
            className={`flex-shrink-0 flex items-center justify-center rounded-lg `}
          >
            {icon}
          </div>
        </Tooltip>
        <div
          className={`${
            isDynamicSidebarActivated() ? 'flex md:hidden ' : 'flex'
          }  whitespace-nowrap overflow-hidden text-white ml-3 w-full justify-between font-medium text-xs`}
        >
          <div className="flex gap-1 items-center">
            {title}{' '}
            {isNewFeature && (
              <div className="font-semi text-brand-accent text-[8px] bg-white rounded-full px-1.5 ml-1 ">
                NEW
              </div>
            )}
          </div>

          <div>
            {comingSoon && (
              <span className="text-[10px] font-medium flex items-center ml-2">
                Coming soon
              </span>
            )}
            {links && (
              <FontAwesomeIcon
                icon={faChevronRight}
                className={`transition-all mr-1 ${isActive ? 'rotate-90' : ''}`}
              />
            )}
          </div>
        </div>
      </div>
      {/* Sublinks */}
      <AnimateHeight durationInS={0.2} isVisible={isActive && !!links}>
        <div className="mb-2 space-y-1">
          {links &&
            links?.map((item, i) => {
              const isSublinkActive = history.location.pathname.includes(
                item.path
              )
              return (
                <div
                  onClick={() => history.push(item.path)}
                  key={i}
                  className={`ml-9 py-2.5 px-3 cursor-pointer rounded-md ${
                    isSublinkActive
                      ? 'bg-brand-app-menu-hover dark:bg-brand-dark-background'
                      : ''
                  }`}
                >
                  <p className="text-white font-medium text-xs">{item.title}</p>
                </div>
              )
            })}
        </div>
      </AnimateHeight>
    </>
  )
}

export default MenuItem
