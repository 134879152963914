import type React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AnalyticsUpIcon from '../../../../../assets/icons/analytics-up-stroke-rounded (1)'
import ArrowDataTransferHorizontalIcon from '../../../../../assets/icons/arrow-data-transfer-horizontal-stroke-rounded (1)'
import BankIcon from '../../../../../assets/icons/bank-stroke-rounded'
import CheckmarkCircle02Icon from '../../../../../assets/icons/checkmark-circle-02-stroke-rounded'
import Wallet03Icon from '../../../../../assets/icons/wallet-03-stroke-rounded'
import PageWrapper from '../../../../../components/Pagewrapper'
import {
  useGetBankAccountsQuery,
  useGetFundDepositsQuery,
  useGetSwapsQuery,
  useGetWalletsQuery,
} from '../../../../../redux/services/coreApi'
import type { RootState } from '../../../../../redux/store'

type CompletedProps = {}

const Completed: React.FC<CompletedProps> = ({}) => {
  const history = useHistory()

  const sessionUser = useSelector((state: RootState) => state.session.user)

  const { data: walletsData } = useGetWalletsQuery(null)
  const { data: bankAccountsData } = useGetBankAccountsQuery(null)
  const { data: swapsData } = useGetSwapsQuery(null)
  const { data: fundDepositsData } = useGetFundDepositsQuery({
    investorId: sessionUser.id,
  })

  const actions = [
    {
      title: 'Add a Bank Account',
      onClick: () => history.push('/app/bank-accounts'),
      icon: <BankIcon className="h-5 w-5 text-inherit" />,
      isFulfilled: bankAccountsData?.length,
    },
    {
      title: 'Add a Crypto Wallet',
      onClick: () => history.push('/app/wallets'),
      icon: <Wallet03Icon className="h-5 w-5 text-inherit" />,
      isFulfilled: walletsData?.length,
    },
    {
      title: 'Make a swap',
      onClick: () => history.push('/app/broker-service'),
      icon: (
        <ArrowDataTransferHorizontalIcon className="h-5 w-5 text-inherit" />
      ),
      isFulfilled: swapsData?.length,
    },
    {
      title: 'Invest in our Capital Management fund',
      onClick: () => history.push('/app/investor'),
      icon: <AnalyticsUpIcon className="h-5 w-5 text-inherit" />,
      isFulfilled: fundDepositsData?.length,
    },
  ]

  return (
    <PageWrapper fixedWidth>
      <div className="bg-white border border-solid border-black/10 rounded-3xl p-14 flex flex-col gap-1.5 mt-20">
        <div className="mb-8">
          <p className="text-xl font-semi mb-2">Getting started with Penning</p>
          <p className="text-description">
            Welcome {sessionUser.name}! Your account setup is done and you are
            now ready to get the most out of Penning by completing the following
            tasks.
          </p>
        </div>

        {actions.map((item, i) => {
          return (
            <button
              disabled={!!item.isFulfilled}
              onClick={item.onClick}
              className={`flex items-center justify-between px-4 py-3.5   select-none rounded-lg disabled:text-black/50 ${
                item.isFulfilled
                  ? 'bg-gray-50 '
                  : 'hover:bg-gray-100 border border-solid border-black/10 cursor-pointer'
              }`}
              key={i}
            >
              <div className="flex items-center gap-2 font-medium text-sm">
                {item.icon}
                {item.title}
              </div>
              {item.isFulfilled ? (
                <CheckmarkCircle02Icon className="w-5 h-5 text-white bg-green-700 rounded-full" />
              ) : undefined}
            </button>
          )
        })}
        <p className="text-description mt-10">
          Do you have other questions concerning how to get started?{' '}
          <a
            href="mailto:j@penning.dk"
            target="_blank"
            className="hover:underline text-blue-600"
            rel="noreferrer"
          >
            Click here
          </a>
        </p>
      </div>
    </PageWrapper>
  )
}

export default Completed
