import type { User } from '@auth0/auth0-react'
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { Role, UserDto } from '../../types/coreApi-types'

export interface SessionState {
  isLoading: boolean
  id: string
  auth0Id: string
  role: Role
  token: string
  otacToken: string
  user: UserDto
  auth0user?: User
}

const initialState: SessionState = {
  isLoading: true,
  id: '',
  auth0Id: '',
  token: '',
  otacToken: '',
  // @ts-ignore (Session isLoading = false until User is set)
  user: undefined,
  auth0user: undefined,
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    startSession: (
      state: SessionState,
      action: PayloadAction<{
        id: string
        auth0Id: string
        role: Role
        token: string
        auth0user: User
      }>
    ) => {
      state.token = action.payload.token
      state.id = action.payload.id
      state.auth0Id = action.payload.auth0Id
      state.role = action.payload.role
      state.auth0user = action.payload.auth0user

      if (state.user) state.isLoading = false
    },
    endSession: (state: SessionState) => {
      state.token = initialState.token
    },
    setUser: (
      state: SessionState,
      action: PayloadAction<{
        user: UserDto
      }>
    ) => {
      state.user = action.payload.user
      state.isLoading = false
    },
    setSessionLoading: (
      state: SessionState,
      action: PayloadAction<{ isLoading: boolean }>
    ) => {
      state.isLoading = action.payload.isLoading
    },
    setOtacToken: (
      state: SessionState,
      action: PayloadAction<{
        otacToken: string
      }>
    ) => {
      state.otacToken = action.payload.otacToken
    },
  },
})

export const {
  startSession,
  endSession,
  setSessionLoading,
  setUser,
  setOtacToken,
} = sessionSlice.actions

export default sessionSlice.reducer
