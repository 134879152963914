import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { TableState } from '../../components/Table/Table'

export const initialState: TableState = {
  columnVisibility: {},
  columnOrder: [],
  columnFilters: [],
  globalFilter: '',
  sorting: [],
  pageIndex: 0,
}

export const currenciesTableSlice = createSlice({
  name: 'currenciesTable',
  initialState,
  reducers: {
    setCurrenciesTableState: (
      state: TableState,
      action: PayloadAction<TableState>
    ) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const transfersTableSlice = createSlice({
  name: 'transfersTable',
  initialState,
  reducers: {
    setTransfersTableState: (
      state: TableState,
      action: PayloadAction<TableState>
    ) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const clientsTableSlice = createSlice({
  name: 'clientsTable',
  initialState,
  reducers: {
    setClientsTableState: (
      state: TableState,
      action: PayloadAction<TableState>
    ) => {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { setCurrenciesTableState } = currenciesTableSlice.actions
export const currenciesTableReducer = currenciesTableSlice.reducer

export const { setTransfersTableState } = transfersTableSlice.actions
export const transfersTableReducer = transfersTableSlice.reducer

export const { setClientsTableState } = clientsTableSlice.actions
export const clientsTableReducer = clientsTableSlice.reducer
