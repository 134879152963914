import type React from 'react'
import type { KycStatus, QuestionnaireStatus } from '../types/coreApi-types'
import InfoBox, { type InfoBoxColorscheme } from './InfoBox'

interface KycStatusProps {
  kycStatus: (KycStatus | QuestionnaireStatus) | 'Suspended'
  ignoreKyc?: boolean
}

const KycStatusBox: React.FC<KycStatusProps> = ({ kycStatus, ignoreKyc }) => {
  const getStyle = (): InfoBoxColorscheme => {
    switch (kycStatus) {
      case 'Processing':
      case 'Pending':
        return 'orange'
      case 'Completed':
      case 'Approved':
        return 'green'
      case 'Cancelled':
      case 'Declined':
      case 'Suspended':
        return 'red'
    }
    return 'gray'
  }

  return (
    <InfoBox colorScheme={getStyle()} className="text-xs">
      {ignoreKyc ? '' : 'KYC'} {kycStatus}
    </InfoBox>
  )
}

export default KycStatusBox
