import { Spinner, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import type React from 'react'
import { useEffect, useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  useCreateNetworkMutation,
  useUploadNetworkIconMutation,
} from '../redux/services/coreApi'
import DefaultModal from './DefaultModal'
import FormInput from './FormInput'
import IconUploadDropZone from './IconUploadDropZone'

interface AddNetworkModalProps {
  isOpen: boolean
  onClose: () => void
}

type FormData = {
  name: string
}

const AddNetworkModal: React.FC<AddNetworkModalProps> = ({
  isOpen,
  onClose,
}) => {
  const schema: yup.SchemaOf<FormData> = yup.object({
    name: yup.string().required('This field is required.'),
  })

  const toast = useToast()

  const [files, setFiles] = useState<File[]>([])

  const {
    register,
    handleSubmit,
    formState,
    reset: resetForm,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const [createNetwork, { isLoading: createNetworkIsLoading }] =
    useCreateNetworkMutation()
  const [uploadNetworkIcon] = useUploadNetworkIconMutation()

  const onSubmit: SubmitHandler<FormData> = async ({ name }) => {
    if (files.length > 0) {
      try {
        const res = await createNetwork({ name }).unwrap()
        const formData = new FormData()
        formData.append('File', files[0], files[0].name)
        await uploadNetworkIcon({ networkId: res.id, formData })
      } catch (e) {}

      onClose()
    }
  }

  useEffect(() => {
    if (!isOpen) {
      resetForm()
      setFiles([])
    }
  }, [isOpen])

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={false}
      title="Add new Network"
      whiteBG
    >
      <div className="p-5 pt-0 space-y-5 h-[500px]">
        <FormInput
          inputMode="text"
          registeredForm={register('name')}
          error={formState.errors?.name}
          label="Name"
        />
        <IconUploadDropZone files={files} setFiles={setFiles} />
        <button
          className="button"
          disabled={!formState.isValid}
          onClick={handleSubmit(onSubmit)}
        >
          {createNetworkIsLoading ? <Spinner size="sm" /> : 'Submit'}
        </button>
      </div>
    </DefaultModal>
  )
}

export default AddNetworkModal
