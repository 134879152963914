import { Timeline } from '@mantine/core'
import moment from 'moment'
import type React from 'react'
import Tooltip from '../../../../../../components/Tooltip'
import type { TransferActivityDto } from '../../../../../../types/coreApi-types'
import { formatNumber, formatUtcDate } from '../../../../../../utils/formatters'

interface ActivityProps {
  activity?: TransferActivityDto[]
}

const Activity: React.FC<ActivityProps> = ({ activity }) => {
  return (
    <div className="space-y-3">
      <h1 className="h1">Activity</h1>
      {activity && activity.length === 0 && (
        <div className="flex justify-center text-description">No activtiy.</div>
      )}
      {activity && activity.length > 0 && (
        <div className="card">
          <Timeline active={100} bulletSize={16} lineWidth={3} color="dark">
            {activity.map((item, i) => {
              return (
                <Timeline.Item
                  title={`${item.transferActivityType} ${
                    item.transfer.transferType
                  } (${formatNumber(
                    item.transfer.amount,
                    item.transfer.currency
                  )})`}
                  key={i}
                >
                  <Tooltip
                    placement="left"
                    label={formatUtcDate(item.createdAt)}
                  >
                    <p className="text-description ">
                      {moment.utc(item.createdAt).fromNow()}
                    </p>
                  </Tooltip>
                </Timeline.Item>
              )
            })}
          </Timeline>
        </div>
      )}
    </div>
  )
}

export default Activity
