import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { yupResolver } from '@hookform/resolvers/yup'
import type React from 'react'
import { useEffect } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import FormButton from '../../../components/FormButton'
import FormCheckbox from '../../../components/FormCheckbox'
import FormInput from '../../../components/FormInput'
import Tooltip from '../../../components/Tooltip'
import Form from './Form'

export type IndividualData = {
  accountPurpose: string
  sourceOfFunds: string
  intentOfUse: {
    BrokerServiceAndOtcDesk?: boolean
    InvestmentFund?: boolean
  }
}

interface IndividualFormProps {
  onPressBack: () => void
  onPressContinue: (data: IndividualData) => void
  initialValues?: IndividualData
}

// type IndividualFormData = {
//   accountPurpose: string
//   sourceOfFunds: string
// }

const IndividualForm: React.FC<IndividualFormProps> = ({
  onPressBack,
  onPressContinue,
  initialValues,
}) => {
  const schema: yup.SchemaOf<IndividualData> = yup.object({
    accountPurpose: yup.string().required('This field is required'),
    sourceOfFunds: yup.string().required('This field is required'),
    intentOfUse: yup.object().shape(
      {
        BrokerServiceAndOtcDesk: yup.boolean().when('investmentFund', {
          is: false,
          then: yup.boolean().required('').oneOf([true], ''),
        }),
        InvestmentFund: yup.boolean().when('brokerService', {
          is: false,
          then: yup
            .boolean()
            .required('At least one must be selected')
            .oneOf([true], 'At least one must be selected'),
        }),
      },
      [['brokerService', 'investmentFund']]
    ),
  })

  useEffect(() => {
    if (initialValues) {
      setValue('accountPurpose', initialValues.accountPurpose)
      setValue('sourceOfFunds', initialValues.sourceOfFunds)
      setValue(
        'intentOfUse.BrokerServiceAndOtcDesk',
        initialValues.intentOfUse.BrokerServiceAndOtcDesk
      )
      setValue(
        'intentOfUse.InvestmentFund',
        initialValues.intentOfUse.InvestmentFund
      )
    }
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<IndividualData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const onSubmit: SubmitHandler<IndividualData> = data => {
    onPressContinue(data)
  }

  return (
    <Form
      direction="fromRight"
      onPressBack={onPressBack}
      backTitle="Back to Account type"
    >
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-5 ">
        <p className="color-text-default font-semi text-lg">Account purpose</p>

        <div>
          <p className="text-description flex items-center gap-1">
            Penning services and products you intend to use
            <Tooltip label="If you wish to get access to additional services or products after account creation, Penning can grant such access upon request, subject to the possibility of additional information needed to be submitted.">
              <FontAwesomeIcon icon={faQuestionCircle} />
            </Tooltip>
          </p>
          <div className="space-y-1 m-3 mb-7">
            <FormCheckbox
              id="broker-service-check"
              renderLabel="Broker service & OTC desk"
              registeredForm={register('intentOfUse.BrokerServiceAndOtcDesk')}
              error={errors.intentOfUse?.BrokerServiceAndOtcDesk}
            />
            <FormCheckbox
              id="investment-fund-check"
              renderLabel="Investment fund"
              registeredForm={register('intentOfUse.InvestmentFund')}
              error={errors.intentOfUse?.InvestmentFund}
            />
          </div>
        </div>

        <FormInput
          label="Your purpose for opening an account with Penning"
          inputMode="text"
          registeredForm={register('accountPurpose')}
          error={errors?.accountPurpose}
          textarea
          tooltip="Describe your expected activity, usage of the Penning services and products, and the nature and purpose of the business relationship."
        />
        <div className="h-[1px] bg-black/10 -mx-10 "></div>
        <p className="color-text-default font-semi text-lg">Source of funds</p>
        <FormInput
          label="How have your funds been obtained?"
          inputMode="text"
          registeredForm={register('sourceOfFunds')}
          error={errors?.sourceOfFunds}
          textarea
          tooltip="Describe how the funds you will use for the Penning services and products have been obtained, i.e. how the funds have been generated or earned."
        />

        <FormButton isLoading={false} title="Continue" />
      </form>
    </Form>
  )
}

export default IndividualForm
