import moment from 'moment'
import { API_URL } from '../constants'
import { SwapDto } from '../types/coreApi-types'

export default async function downloadSwapInvoice(
  swap: SwapDto,
  sessionToken: string
) {
  const res = await fetch(`${API_URL}/swaps/${swap.id}/invoice`, {
    headers: { Authorization: `Bearer ${sessionToken}` },
  })

  const bankUniqueIdOrSwapId = swap.deposit?.bankDepositUniqueId ?? swap.id
  const createdAtFormatted = moment(swap.createdAt).format(
    'YYYY-MM-DD-HH-mm-ss'
  )
  const fullNameSeparated = swap.createdBy.name.replace(' ', '-')
  const fileTitle = `Swap_${createdAtFormatted}_${fullNameSeparated}_${bankUniqueIdOrSwapId}`

  const blob = await res.blob()
  const newBlob = new Blob([blob])

  const blobUrl = window.URL.createObjectURL(newBlob)

  const link = document.createElement('a')
  link.href = blobUrl
  link.setAttribute('download', `${fileTitle}.pdf`)
  document.body.appendChild(link)
  link.click()
  link.parentNode!.removeChild(link)

  // clean up Url
  window.URL.revokeObjectURL(blobUrl)
}
