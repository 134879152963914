import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { motion } from 'framer-motion'
import type React from 'react'
import type { HTMLProps } from 'react'
import Tooltip from './Tooltip'

export type SegmentTabItemProps = {
  title: string
  onClick: () => void
  isActive: boolean
  tooltip?: string
}

interface SegmentTabProps {
  tabs: SegmentTabItemProps[]
  className?: HTMLProps<HTMLElement>['className']
}

const SegmentTab: React.FC<SegmentTabProps> = ({ tabs, className }) => {
  return (
    <div
      className={`h-9 bg-brand-light-background mx-5 my-5 rounded-md flex gap-2 p-1 ${className}`}
    >
      {tabs.map(({ isActive, title, onClick, tooltip }, i) => {
        return (
          <div
            key={i}
            className="w-full flex items-center justify-center relative"
          >
            {isActive && (
              <motion.div
                layoutId="box"
                className="bg-white h-full w-full rounded shadow"
              ></motion.div>
            )}

            <div
              onClick={onClick}
              className="absolute inset-0 flex items-center justify-center z-50 cursor-pointer text-description gap-1 select-none"
            >
              {title}
              {tooltip && (
                <Tooltip label={tooltip}>
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </Tooltip>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default SegmentTab
