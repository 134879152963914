import { useToast } from '@chakra-ui/react'
import type React from 'react'
import { bindHooksToRTKErrorLogger } from '../redux/rtkQueryErrorLogger'

const RTKErrorLoggerHooksProvider: React.FC<{
  children?: JSX.Element | (JSX.Element | boolean)[] | boolean
}> = ({ children }) => {
  const toast = useToast()

  bindHooksToRTKErrorLogger(toast)

  return <>{children}</>
}

export default RTKErrorLoggerHooksProvider
