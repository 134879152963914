import type React from 'react'

interface ThemeButtonProps {
  onClick: () => void
  title: string
  subtitle?: string
  isActive?: boolean
  iconUrl?: string
}

const ThemeButton: React.FC<ThemeButtonProps> = ({
  onClick,
  title,
  subtitle,
  isActive,
  iconUrl,
}) => {
  return (
    <div
      onClick={onClick}
      className={`col-span-1 bg-gray-100 transition-all select-none hover:bg-gray-200 cursor-pointer py-2 md:py-3 px-5 md:px-0 flex flex-col items-center justify-center  border-2 rounded-lg border-solid ${
        isActive ? 'border-black' : 'border-transparent'
      } `}
    >
      <img
        src={iconUrl}
        alt="Icon of currency"
        className="w-6 h-6 rounded-full mb-2"
      />
      <p className="text-sm font-semi text-center">{title}</p>
      {subtitle && (
        <p className="text-xs font-medium text-brand-light-text-description">
          {subtitle}
        </p>
      )}
    </div>
  )
}

export default ThemeButton
