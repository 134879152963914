import type React from 'react'
import type { UserDto } from '../../../../../../types/coreApi-types'
import Member from '../../../Account/components/Member'

interface MembersProps {
  members?: UserDto[]
}

const Members: React.FC<MembersProps> = ({ members }) => {
  return (
    <div className="space-y-2">
      <h1 className="h1">Members</h1>

      {members && members.length === 0 && (
        <div className="card text-description">No members yet.</div>
      )}

      {members &&
        members.map((member, idx) => {
          return (
            <div className="card" key={member.id}>
              <Member member={member} />
            </div>
          )
        })}
    </div>
  )
}

export default Members
