import { useToast } from '@chakra-ui/react'
import { faCopy, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import CoptyTextWrapper from '../../../../../components/CopyTextWrapper'
import Tooltip from '../../../../../components/Tooltip'
import type { NetworkDto } from '../../../../../types/coreApi-types'

interface WalletItemProps {
  network?: NetworkDto | null
  address: string
  destinationTag?: string | null
  onPressDelete: () => void
}

export const WalletItem: React.FC<WalletItemProps> = ({
  network,
  address,
  destinationTag,
  onPressDelete,
}) => {
  const toast = useToast()

  const iconClass =
    'h-9 w-9 flex items-center justify-center cursor-pointer rounded-md text-brand-light-text-description dark:text-brand-dark-text-subtle-description group hover:bg-brand-light-background dark:hover:bg-brand-dark-background'

  const iconRed = 'hover:text-brand-light-red dark:hover:text-brand-dark-red'
  const iconBlue = 'hover:text-brand-accent dark:hover:text-brand-accent'

  return (
    <div className="card ">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <img
            className="h-9 w-9 rounded-full"
            src={network?.iconUrl}
            alt="Network"
          />
          <div>
            <p className="text-sm font-semi">{address}</p>
            {destinationTag && (
              <p className="text-description-subtle">{`${destinationTag}`}</p>
            )}
            <p className="text-description">{`${network?.name}`}</p>
          </div>
        </div>

        <div className="flex gap-2">
          <Tooltip label="Delete wallet">
            <div onClick={onPressDelete} className={`${iconClass} ${iconRed}`}>
              <FontAwesomeIcon icon={faTrashCan} />
            </div>
          </Tooltip>

          <Tooltip label="Copy address" closeOnClick={true}>
            <CoptyTextWrapper text={address}>
              <div className={`${iconClass} ${iconBlue}`}>
                <FontAwesomeIcon icon={faCopy} />
              </div>
            </CoptyTextWrapper>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
