import { Spinner } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import type React from 'react'
import { useEffect } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as yup from 'yup'
import { VERSION } from '../constants'
import { useAcceptBetaDisclaimerMutation } from '../redux/services/coreApi'
import type { RootState } from '../redux/store'
import DefaultModal from './DefaultModal'
import FormCheckbox from './FormCheckbox'

interface WelcomeModalProps {
  isOpen: boolean
  onClose: () => void
}

type FormData = {
  privacyPolicy: boolean
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ isOpen, onClose }) => {
  const schema: yup.SchemaOf<FormData> = yup.object({
    privacyPolicy: yup
      .boolean()
      .required('The terms must be accepted.')
      .oneOf([true], 'The terms must be accepted.'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const [acceptBetaDisclaimer, { isLoading: acceptBetaDisclaimerIsLoading }] =
    useAcceptBetaDisclaimerMutation()

  const onSubmit: SubmitHandler<FormData> = async ({ privacyPolicy }) => {
    if (privacyPolicy && !user.hasAcceptedBetaDisclaimer) {
      await acceptBetaDisclaimer(null).unwrap()
    }
    onClose()
  }

  const user = useSelector((state: RootState) => state.session.user)

  useEffect(() => {
    if (user.hasAcceptedBetaDisclaimer) {
      setValue('privacyPolicy', true)
    }
  }, [user])

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleSubmit(onSubmit)}
      isLoading={false}
      title="Welcome!"
    >
      <div className="flex flex-col items-center">
        <div className="bg-brand-dark-orange py-1 px-2 rounded-full cursor-pointer inline-block mt-2">
          <h1 className="text-white font-semi text-[10px]">
            BETA (v{VERSION})
          </h1>
        </div>
        <div className="text-description m-10 space-y-3">
          <p>
            We are working hard on building the world's best platform for
            investing in, purchasing and transacting in digital assets.
          </p>
          <p>
            You are using the beta-version of our platform, and while you do so,
            we are working hard behind the scenes on improving and expanding our
            offerings.
          </p>
          <p>
            For this reason, if you encounter anything that is difficult to
            understand, is not responding, or if you have questions or feedback,
            please contact us, and we will be happy to assist you or answer any
            questions you might have.
          </p>
          <p>
            You can contact Martin Hebsgaard Crillesen directly, who will be
            ready to assist:
          </p>
          <div className="space-y-0">
            <p>
              Email:{' '}
              <a
                href="mailto:m@penning.dk"
                className="hover:underline text-brand-blue dark:text-white"
              >
                m@penning.dk
              </a>{' '}
            </p>
            <p>
              Call/text:{' '}
              <a
                href="tel:+4530220033"
                className="hover:underline text-brand-blue dark:text-white"
              >
                +45 30 22 00 33
              </a>{' '}
            </p>
          </div>

          <p>
            If you encounter voicemail, please leave a message with your contact
            details, or send a text message. Please leave Martin one weekday to
            reply.
          </p>
          <FormCheckbox
            id="tos"
            renderLabel={
              <>
                I have read and understood the above and accept the{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.penning.dk/legal/cookiepolitik"
                  className="hover:underline text-brand-blue dark:text-white"
                >
                  Privacy policies
                </a>
                .
              </>
            }
            registeredForm={register('privacyPolicy')}
            error={errors?.privacyPolicy}
          />
          <button className="button" onClick={handleSubmit(onSubmit)}>
            {acceptBetaDisclaimerIsLoading ? (
              <Spinner size="sm" />
            ) : (
              'I understand'
            )}
          </button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default WelcomeModal
