import { Spinner, useDisclosure, useToast } from '@chakra-ui/react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { captureException } from '@sentry/react'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Copy01Icon from '../../../../../assets/icons/copy-01-stroke-rounded'
import DefaultModal from '../../../../../components/DefaultModal'
import PageWrapper from '../../../../../components/Pagewrapper'
import Table from '../../../../../components/Table/Table'
import Toast from '../../../../../components/Toast'
import TransferStatusBadge from '../../../../../components/TransferStatusBadge'
import {
  useGetSwapsQuery,
  useSetSwapStatusMutation,
} from '../../../../../redux/services/coreApi'
import { setTransfersTableState } from '../../../../../redux/slices/tableFiltersSlice'
import { RootState } from '../../../../../redux/store'
import { SwapDto, TransferStatus } from '../../../../../types/coreApi-types'
import downloadSwapInvoice from '../../../../../utils/downloadInvoice'
import { formatNumber } from '../../../../../utils/formatters'
import TransactionSummaryModal from './components/TransactionSummaryModal'

type TransfersScreenProps = {}

const TransfersScreen: React.FC<TransfersScreenProps> = ({}) => {
  const history = useHistory()
  const toast = useToast()
  const sessionToken = useSelector((state: RootState) => state.session.token)

  const { data: swapsData, isLoading } = useGetSwapsQuery(null)
  const [setSwapStatus, { isLoading: setSwapsStatusIsLoading }] =
    useSetSwapStatusMutation()

  const [activeSwap, setActiveSwap] = useState<SwapDto | null>(null)
  const [tempTransferStatus, setTempTransferStatus] =
    useState<TransferStatus | null>(null)

  const [withdrawTransactionHash, setWithdrawTransactionHash] =
    useState<string>('')
  const [adminNote, setAdminNote] = useState<string>('')
  const summaryModal = useDisclosure()
  const confirmModal = useDisclosure()

  const [isDownloadingInvoice, setIsDownloadingInvoice] = useState<{
    [swapId: string]: boolean
  }>({})

  const openSummaryModal = (v: SwapDto) => {
    setActiveSwap(v)
    setWithdrawTransactionHash(v.withdraw?.transactionHash ?? '')
    setAdminNote(v.adminNote ?? '')
    summaryModal.onOpen()
  }

  useEffect(() => {
    const matchingActiveSwap = swapsData?.find(x => x.id === activeSwap?.id)
    if (matchingActiveSwap) {
      setActiveSwap(matchingActiveSwap)
    }
  }, [swapsData])

  const transfersTableState = useSelector(
    (state: RootState) => state.transfersTable
  )

  return (
    <PageWrapper title="Transfers">
      <Table
        tableState={transfersTableState}
        setTableState={setTransfersTableState}
        navigate={() => null}
        downloadInvoicesCb={rows => {
          ;(rows as { original: SwapDto }[]).forEach(async row => {
            const swap = row.original
            if (swap.withdraw?.transferStatus === 'Completed') {
              try {
                await downloadSwapInvoice(swap, sessionToken)
              } catch (e) {
                captureException(e)
              }
            }
          })
        }}
        isLoading={isLoading}
        onClickRow={v => {
          openSummaryModal(v)
        }}
        emptyDataRender={<div className="text-xl mb-3">No transfers yet.</div>}
        rowMenuActions={[
          {
            title: 'Order details',
            onClick: v => {
              openSummaryModal(v)
            },
            hidden: v => false,
          },
          {
            title: 'Go to user',
            onClick: v => history.push(`/app/admin/clients/${v.createdBy.id}`),
            hidden: v => false,
          },
          {
            title: 'Download receipt',
            onClick: async v => {
              setIsDownloadingInvoice(prev => ({
                ...prev,
                [v.id]: true,
              }))

              try {
                await downloadSwapInvoice(v, sessionToken)
              } catch (e) {
                captureException(e)
              } finally {
                setIsDownloadingInvoice(prev => ({
                  ...prev,
                  [v.id]: false,
                }))
              }
            },
            isLoading: v => isDownloadingInvoice[v.id],
            disabled: v => isDownloadingInvoice[v.id],
            hidden: v => v.withdraw?.transferStatus !== 'Completed',
          },
        ]}
        defaultData={[
          ...(swapsData?.map(item => {
            return {
              ...item,
              name: `${item.createdBy.name} ${item.createdBy.companyTitle ? `(${item.createdBy.companyTitle})` : ''}`,
              status: item.withdraw?.transferStatus,
              bankDepositUniqueId: item.deposit?.bankDepositUniqueId,
              depositAmount: formatNumber(
                item.deposit!.amount,
                item.deposit!.currency
              ),
              withdrawAmount: formatNumber(
                item.withdraw!.amount,
                item.withdraw!.currency
              ),
            }
          }) ?? []),
        ]}
        schema={[
          {
            header: 'Status',
            accessorKey: 'status',
            type: 'status',
            priority: 1,
            cellValueWrapper: (value, originalValue, { original }) => (
              <div className="w-min ">
                {original?.status && (
                  <TransferStatusBadge transferStatus={original.status} />
                )}
              </div>
            ),
          },
          {
            cardTitle: true,
            header: 'Date created',
            accessorKey: 'createdAt',
            type: 'date',
            priority: 2,
          },
          {
            header: 'Investor name',
            accessorKey: 'name',
            type: 'string',
            priority: 3,
          },
          {
            header: 'Deposit',
            accessorKey: 'depositAmount',
            type: 'string',
            priority: 4,
          },
          {
            header: 'Receive',
            accessorKey: 'withdrawAmount',
            type: 'string',
            priority: 5,
          },
          {
            header: 'Bank Deposit Id',
            accessorKey: 'bankDepositUniqueId',
            type: 'string',
            priority: 6,
            cellValueWrapper: (value, originalValue, { original }) =>
              original?.bankDepositUniqueId ? (
                <div
                  className="px-2 py-1.5 w-min hover:bg-gray-100 transition-all rounded-md group"
                  onClick={e => {
                    e.stopPropagation()
                    navigator.clipboard.writeText(original.bankDepositUniqueId!)

                    toast({
                      position: 'top',
                      render: () => {
                        return <Toast type="success">Id copied</Toast>
                      },
                    })
                  }}
                >
                  <div className="flex gap-1.5 items-center">
                    {original.bankDepositUniqueId}
                    <div className="group-hover:opacity-100 opacity-0 transition-all">
                      <Copy01Icon className="w-4 h-4" />
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              ),
          },
          {
            header: 'Admin note',
            accessorKey: 'adminNote',
            type: 'string',
            priority: 7,
          },
        ]}
      />
      <TransactionSummaryModal
        isOpen={summaryModal.isOpen}
        onClose={summaryModal.onClose}
        activeSwap={activeSwap}
        withdrawTransactionHash={withdrawTransactionHash}
        setWithdrawTransactionHash={setWithdrawTransactionHash}
        setSwapStatus={updateSwapStatusCommand =>
          setSwapStatus(updateSwapStatusCommand).unwrap()
        }
        setSwapsStatusIsLoading={setSwapsStatusIsLoading}
        adminNote={adminNote}
        setAdminNote={setAdminNote}
        setTempTransferStatus={setTempTransferStatus}
        openConfirmModal={confirmModal.onOpen}
      />

      <DefaultModal
        isLoading={false}
        isOpen={confirmModal.isOpen}
        onClose={confirmModal.onClose}
        title="Are you sure?"
        maxW="500px"
      >
        <div className="flex flex-col items-center p-10 text-description-large">
          <p className="text-center">
            Are you sure you want to perform the following action:
          </p>

          <div className="flex justify-center items-center my-4 gap-2">
            {activeSwap?.withdraw?.transferStatus && (
              <TransferStatusBadge
                transferStatus={activeSwap?.withdraw?.transferStatus}
              />
            )}

            <FontAwesomeIcon icon={faArrowRight} className="text-black" />

            {tempTransferStatus && (
              <TransferStatusBadge transferStatus={tempTransferStatus} />
            )}
          </div>

          <p className="">With the following ID:</p>
          <div className="mt-3 py-1 px-2 bg-black/5 rounded">
            {activeSwap?.deposit?.bankDepositUniqueId}
          </div>

          <div className="flex gap-2 w-full mt-10">
            <button className="button-secondary" onClick={confirmModal.onClose}>
              Cancel
            </button>
            <button
              className="button"
              onClick={async () => {
                if (!activeSwap || !tempTransferStatus || !activeSwap.withdraw)
                  return

                await setSwapStatus({
                  swapId: activeSwap.id,
                  status: tempTransferStatus,
                  adminNote: adminNote,
                  transactionHash: withdrawTransactionHash,
                }).unwrap()
                confirmModal.onClose()

                toast({
                  position: 'top',
                  render: () => {
                    return (
                      <Toast type="success">
                        The status was successfully changed.
                      </Toast>
                    )
                  },
                })
              }}
            >
              {setSwapsStatusIsLoading ? <Spinner size="xs" /> : 'Confirm'}{' '}
            </button>
          </div>
        </div>
      </DefaultModal>
    </PageWrapper>
  )
}

export default TransfersScreen
