import { Skeleton } from '@mantine/core'
import type React from 'react'

interface LargeNumberProps {
  title: string
  value: string
  renderRight?: React.ReactNode
  color?: string
  isLoading?: boolean
}

const LargeNumber: React.FC<LargeNumberProps> = ({
  title,
  value,
  color,
  renderRight,
  isLoading,
}) => {
  return (
    <div className="space-y-1 bg-white dark:bg-brand-dark-card border-[1px] border-black/10 border-solid p-5 rounded-xl dark:border-brand-dark-card-border">
      <div className="mb-1 flex flex-row items-center gap-2 justify-between lg:flex-col lg:items-start lg:justify-normal xl:flex-row xl:items-center xl:justify-between">
        <Skeleton visible={isLoading}>
          <p
            className={`font-medium text-xl md:text-3xl dark:text-white whitespace-nowrap ${color}`}
          >
            {value}
          </p>
        </Skeleton>
        <Skeleton
          visible={isLoading}
          className="text-right lg:text-left xl:text-right"
        >
          <p className="whitespace-nowrap">{renderRight}</p>
        </Skeleton>
      </div>

      <p className="text-description">{title}</p>
    </div>
  )
}

export default LargeNumber
