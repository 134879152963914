import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import type { HTMLProps } from 'react'
import Tooltip from './Tooltip'

export type InfoBoxColorscheme = 'gray' | 'orange' | 'red' | 'green'

interface InfoBoxProps {
  clickable?: boolean
  colorScheme: InfoBoxColorscheme
  children?: React.ReactNode
  column?: boolean
  tooltipLabel?: string
  className?: HTMLProps<HTMLElement>['className']
  paddingLarge?: boolean
  rightChevron?: boolean
  onClick?: () => void
}

const InfoBox: React.FC<InfoBoxProps> = ({
  clickable,
  colorScheme,
  children,
  column,
  tooltipLabel,
  className,
  paddingLarge,
  rightChevron,
  onClick,
}) => {
  const getColorScheme = (): HTMLProps<HTMLElement>['className'] => {
    switch (colorScheme) {
      case 'orange':
        return 'bg-brand-light-orange-tint text-brand-light-orange dark:bg-brand-dark-orange dark:text-white'
      case 'red':
        return 'bg-brand-light-red-tint text-brand-light-red'
      case 'gray':
        return 'text-brand-light-text-default bg-[#F2F4F6] dark:bg-brand-dark-background dark:text-white'
      case 'green':
        return 'text-brand-light-green bg-brand-light-green-tint dark:bg-brand-dark-green dark:text-white'
    }
  }

  return (
    <Tooltip label={tooltipLabel}>
      <div
        onClick={onClick}
        className={`text-[0.85rem] font-medium flex gap-1 rounded w-full whitespace-nowrap ${className} ${getColorScheme()} ${
          column ? 'flex flex-col' : 'flex items-center'
        } ${clickable ? 'cursor-pointer' : ''} ${
          paddingLarge ? 'p-3' : 'py-1 px-2'
        }`}
      >
        {children}
        {rightChevron && <FontAwesomeIcon icon={faChevronDown} size="sm" />}
      </div>
    </Tooltip>
  )
}

export default InfoBox
