import type React from 'react'
import Alert02Icon from '../../../../../../assets/icons/alert-02-stroke-rounded (2)'

interface ReturnBadgeProps {
  value?: number
  isDisabled?: boolean
}

const ReturnBadge: React.FC<ReturnBadgeProps> = ({ value, isDisabled }) => {
  if (isDisabled) return <></>
  if (!value && value !== 0) {
    return (
      <div className="flex items-center gap-1 text-yellow-600">
        <Alert02Icon className="w-4 h-4 text-inherit" />
        Missing data
      </div>
    )
  }

  return (
    <div
      className={`font-semi px-2 py-1 text-xs rounded-md  ${
        value > 0
          ? 'bg-[#D8F0D8] text-green-700'
          : value < 0
            ? 'bg-[#FFEFEF] text-red-700'
            : 'bg-gray-200 text-gray-800'
      }`}
    >
      {value > 0 ? '+' : ''}
      {value} %
    </div>
  )
}

export default ReturnBadge
