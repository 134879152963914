import { Spinner } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import type React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as yup from 'yup'
import { useUpdateUserMutation } from '../redux/services/coreApi'
import type { RootState } from '../redux/store'
import DefaultModal from './DefaultModal'
import FormInput from './FormInput'

interface PhoneNumberModalProps {
  isOpen: boolean
  onClose: () => void
}

type FormData = {
  phone: string
}

const PhoneNumberModal: React.FC<PhoneNumberModalProps> = ({
  isOpen,
  onClose,
}) => {
  const sessionUserId = useSelector((state: RootState) => state.session.id)

  const schema: yup.SchemaOf<FormData> = yup.object({
    phone: yup.string().required('This field is required to continue.'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  })

  const [updateUser, { isLoading: updateUserIsLoading }] =
    useUpdateUserMutation()

  const onSubmit: SubmitHandler<FormData> = async ({ phone }) => {
    if (phone) {
      await updateUser({ phone, userId: sessionUserId }).unwrap()
    }
    onClose()
  }

  const user = useSelector((state: RootState) => state.session.user)

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleSubmit(onSubmit)}
      isLoading={false}
      title="Please set your phone number"
    >
      <div className="flex flex-col items-center">
        <div className="text-description m-10 space-y-3">
          <p>We can see that you have not entered your phone number.</p>
          <p>
            To make sure you are getting the best support, we'd like to have
            your phone number ready on hand, in case any issues arise that you
            may need to react to; or in case we can see your onboarding processs
            is stuck or lacking information.
          </p>
          <FormInput
            inputMode="text"
            registeredForm={register('phone')}
            error={errors?.phone}
            cardStyling={true}
          />
          <button className="button" onClick={handleSubmit(onSubmit)}>
            {updateUserIsLoading ? <Spinner size="sm" /> : 'Submit'}
          </button>
        </div>
      </div>
    </DefaultModal>
  )
}

export default PhoneNumberModal
