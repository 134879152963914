import type React from 'react'

interface FormSectionProps {
  children: React.ReactNode
  title: string
}

const FormSection: React.FC<FormSectionProps> = ({ title, children }) => {
  return (
    <div className="flex flex-col">
      <p className="text-default-small mb-2">{title}</p>
      <div className="flex gap-1">{children}</div>
    </div>
  )
}

export default FormSection
