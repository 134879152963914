//import { Tooltip } from '@chakra-ui/react'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HelpCircleIcon from '../assets/icons/help-circle-stroke-rounded'
import type { CurrencyDto, NetworkDto } from '../types/coreApi-types'
import DropdownMenu from './DropdownMenu'
import Icon from './Icon'
import Tooltip from './Tooltip'

interface CDropdownMenuProps {
  label?: string
  selectedText?: string
  emptyText: string
  selectedIconSrc?: CurrencyDto | NetworkDto | null
  renderList?: JSX.Element | JSX.Element[]
  tooltip?: string
}

const CDropdownMenu: React.FC<CDropdownMenuProps> = ({
  label,
  selectedText,
  emptyText,
  selectedIconSrc,
  renderList,
  tooltip,
}) => {
  return (
    <>
      {label && (
        <label
          htmlFor={label}
          className="flex text-description items-center mb-2"
        >
          <div>{label}</div>
          {tooltip && (
            <Tooltip label={tooltip}>
              <HelpCircleIcon className="ml-1 text-brand-light-text-description w-4 h-4" />
            </Tooltip>
          )}
        </label>
      )}
      <DropdownMenu maxH="310px" renderList={renderList}>
        <div className="input text-start flex items-center pl-3 gap-2">
          <Icon src={selectedIconSrc} size="base" />

          {selectedText}
          {!selectedText && <p className="text-description">{emptyText}</p>}

          <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 text-brand-light-text-description">
            <FontAwesomeIcon icon={faCaretDown} size="sm" />
          </div>
        </div>
      </DropdownMenu>
    </>
  )
}

export default CDropdownMenu
