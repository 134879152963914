import React from 'react'

interface TooltipRowProps {
  description: string
  value: string | React.ReactNode
  valueClass?: string
}

const TooltipRow: React.FC<TooltipRowProps> = ({
  description,
  value,
  valueClass,
}) => {
  return (
    <>
      <div className="my-2 h-px bg-black/10"></div>
      <div className="w-[250px] text-description flex justify-between">
        <p>{description}</p>
        <p className={valueClass}>{value}</p>
      </div>
    </>
  )
}

export default TooltipRow
