import { useDisclosure } from '@chakra-ui/react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import Icon from '../../../../../components/Icon'
import TransferStatusBadge from '../../../../../components/TransferStatusBadge'
import type { SwapDto } from '../../../../../types/coreApi-types'
import { formatNumber, formatUtcDate } from '../../../../../utils/formatters'
import getStatusFromSwap from '../../../../../utils/getStatusFromSwap'
import StatusModal from '../containers/StatusModal'

interface SwapMobileCardProps {
  swap: SwapDto
}

const Divider = () => (
  <div className="h-[1px] bg-brand-light-card-border dark:bg-brand-dark-card-border my-3 -mx-6"></div>
)

interface SwapInfoProps {
  title: string
  children: React.ReactNode
}
const SwapInfo: React.FC<SwapInfoProps> = ({ title, children }) => {
  return (
    <div>
      <p className="text-description">{title}</p>
      <div className="flex items-center gap-2 font-medium text-sm">
        {children}
      </div>
    </div>
  )
}

const SwapMobileCard: React.FC<SwapMobileCardProps> = ({ swap }) => {
  const status = getStatusFromSwap(swap)
  const statusModal = useDisclosure()

  const isWalletTransfer = Boolean(swap.withdraw?.wallet)
  const isBankTransfer = Boolean(swap.withdraw?.bankAccount)

  return (
    <div className="card">
      <StatusModal
        isOpen={statusModal.isOpen}
        onClose={() => {
          statusModal.onClose()
        }}
        swap={swap}
      />

      {swap.deposit && swap.withdraw && (
        <div className="font-semi flex items-center gap-4 text-sm">
          <div className="flex items-center gap-1.5">
            <Icon size="base" src={swap.deposit.currency} />
            <p>
              <span>
                {formatNumber(swap.deposit.amount, swap.deposit.currency)}
              </span>
            </p>
          </div>
          <FontAwesomeIcon icon={faArrowRight} />
          <div className="flex items-center gap-1.5">
            <Icon size="base" src={swap.withdraw.currency} />
            <p>
              <span>
                {formatNumber(swap.withdraw.amount, swap.withdraw.currency)}
              </span>
            </p>
          </div>
        </div>
      )}

      <Divider />

      <SwapInfo title={isBankTransfer ? 'Bank Details' : 'Address'}>
        {isBankTransfer
          ? swap.withdraw?.bankAccount?.bankAccountDetails.accountNumber ||
            swap.withdraw?.bankAccount?.bankAccountDetails.iban
          : swap.withdraw?.wallet?.address}
      </SwapInfo>
      <div className="flex items-center mt-3 gap-6">
        <SwapInfo title={isBankTransfer ? 'Bank Name' : 'Network'}>
          {isBankTransfer
            ? swap.withdraw?.bankAccount?.name
            : swap.withdraw?.wallet?.network?.name}
        </SwapInfo>
        {swap.deposit && (
          <SwapInfo title="Date">
            {formatUtcDate(swap.deposit.createdAt)}
          </SwapInfo>
        )}
      </div>

      <Divider />

      <div className="flex items-center gap-2">
        <TransferStatusBadge transferStatus={status.transferStatus} />
        <p className="font-semi text-xs">{status.title}</p>
      </div>
      <button
        onClick={statusModal.onOpen}
        className="bg-brand-light-background dark:bg-brand-dark-background py-3 font-semi text-xs rounded-md mt-4 gap-2 flex items-center justify-center"
      >
        Details
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  )
}

export default SwapMobileCard
