import { linearGradientDef } from '@nivo/core'
import { DatumValue, ResponsiveLine } from '@nivo/line'
import type React from 'react'
import TooltipRow from '../../../../../../components/TooltipRow'
import { colorByPerformance } from '../../../../../../utils/colorByPerformance'
import { FormattedFundPerformance } from '../FundScreen'

interface LineChartProps {
  data: FormattedFundPerformance[]
}

type CustomDatum = {
  x: DatumValue
  y: DatumValue
  realIndex: number
}

const LineChart: React.FC<LineChartProps> = ({ data }) => {
  return (
    <div className="bg-brand-light-card dark:bg-brand-dark-card col-span-full relative rounded-xl border-[1px] border-solid border-black/10 dark:border-brand-dark-card-border">
      <div className="h-[500px]">
        <ResponsiveLine
          data={[
            {
              id: 'Id',
              data: data?.map((item, i) => {
                return {
                  x: item.month.format('MMM YYYY'),
                  y: item.performanceToDate
                    ? Number.parseFloat(item.performanceToDate)
                    : null,
                  realIndex: i,
                } as CustomDatum
              }),
            },
          ]}
          enableSlices="x"
          enableArea={true}
          enableGridX={true}
          enableGridY={true}
          animate={false}
          colors="#29383B"
          margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
          defs={[
            linearGradientDef('gradientA', [
              { offset: 0, color: 'inherit' },
              { offset: 100, color: 'inherit', opacity: 0 },
            ]),
          ]}
          fill={[{ match: '*', id: 'gradientA' }]}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            max: 'auto',
            stacked: true,
            reverse: false,
            min: 'auto',
          }}
          yFormat=" >-.2f"
          axisTop={null}
          axisRight={null}
          tooltip={point => {
            return (
              <div className="bg-black text-white">
                {point.point.data.y.toString()}
              </div>
            )
          }}
          sliceTooltip={point => {
            //@ts-ignore
            const index = point.slice.points[0].data.realIndex

            const currMonth = data ? data[index] : undefined

            return (
              <div className="bg-brand-light-card border dark:bg-brand-dark-background border-solid border-brand-light-card-border dark:border-brand-dark-card-border shadow-lg px-4 py-3 rounded-lg text-sm">
                <div className="font-semi flex justify-between text-[13px]">
                  <p className="dark:text-white">
                    {point.slice.points[0].data.x.toString()}
                  </p>

                  <span
                    className={`font-semi ${colorByPerformance(
                      parseFloat(currMonth?.performance ?? '')
                    )}`}
                  >
                    {currMonth?.performance}%
                  </span>
                </div>
                <TooltipRow
                  description="Total performance"
                  value={
                    <span
                      className={colorByPerformance(
                        parseFloat(currMonth?.performanceToDate ?? '')
                      )}
                    >
                      {currMonth?.performanceToDate}%
                    </span>
                  }
                />
              </div>
            )
          }}
          pointSize={5}
          pointColor="#29383B"
          pointLabelYOffset={-12}
          useMesh={true}
        />
      </div>
    </div>
  )
}

export default LineChart
