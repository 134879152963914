import { Spinner } from '@chakra-ui/react'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import { skipToken } from '@reduxjs/toolkit/query'
import moment from 'moment'
import type React from 'react'
import { useEffect, useState } from 'react'
import PageWrapper from '../../../../../components/Pagewrapper'
import {
  useGetBrokerPerformanceQuery,
  useGetCurrenciesQuery,
} from '../../../../../redux/services/coreApi'
import type { CurrencyDto } from '../../../../../types/coreApi-types'
import PerformanceStep from './Components/PerformanceStep'

import '@mantine/core/styles.css'
import '@mantine/dates/styles.css'

type PerformanceScreenProps = {}

const PerformanceScreen: React.FC<PerformanceScreenProps> = () => {
  const { data: currencies, isLoading: currenciesIsLoading } =
    useGetCurrenciesQuery()

  const fiatCurrencies =
    currencies?.filter(x => x.currencyType === 'FiatCurrency') ?? []

  const [currentCurrency, setCurrentCurrency] = useState<CurrencyDto | null>(
    null
  )

  useEffect(() => {
    if (!currencies) return
    setCurrentCurrency(
      currencies.find(currency => currency.name === 'Danish Krone') ?? null
    )
  }, [currencies])

  const [date, setDate] = useState<[Date | null, Date | null]>([
    moment().startOf('month').toDate(),
    moment.utc().toDate(),
  ])

  function dateToUtcDate(date: Date) {
    // Remove timezone offset
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
  }

  const { data: performance, isFetching: perfomanceIsLoading } =
    useGetBrokerPerformanceQuery(
      currentCurrency && date[0] && date[1]
        ? {
            from: dateToUtcDate(date[0]).toISOString() as string,
            to: moment
              .utc(dateToUtcDate(date[1]))
              .endOf('d')
              .toISOString() as string,
            currencyId: currentCurrency.id,
          }
        : skipToken,
      { skip: !currentCurrency || !date[0] || !date[1] }
    )

  const preSetDates = [
    {
      title: 'This month',
      fromDate: moment().startOf('month').toDate(),
      toDate: new Date(),
    },
    {
      title: '30 days',
      fromDate: moment().subtract(30, 'days').toDate(),
      toDate: new Date(),
    },
    {
      title: 'This quarter',
      fromDate: moment().startOf('quarter').toDate(),
      toDate: new Date(),
    },
    {
      title: 'Last quarter',
      fromDate: moment().subtract(1, 'quarter').startOf('quarter').toDate(),
      toDate: moment().subtract(1, 'quarter').endOf('quarter').toDate(),
    },
    {
      title: '6 month',
      fromDate: moment().subtract(6, 'months').toDate(),
      toDate: new Date(),
    },
    {
      title: '1 year',
      fromDate: moment().subtract(1, 'years').toDate(),
      toDate: new Date(),
    },
    {
      title: 'All time',
      fromDate: moment.utc('1970-01-01T00:00:00Z').toDate(),
      toDate: new Date(),
    },
  ]
  const [chosenPreDate, setChosenPreDate] = useState(preSetDates[0].title)

  return (
    <PageWrapper title="Performance">
      <div className="max-w-[1600px] mx-auto w-full">
        <div className="flex gap-2 pb-7">
          <Menu shadow="md" position="bottom-start">
            <Menu.Target>
              <button className="bg-white hover:bg-brand-gray-100 gap-8 shadow-sm flex justify-between items-center h-full rounded-lg px-3 py-2.5 transition-all duration-300 text-sm font-medium">
                <p>{chosenPreDate}</p>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="xs"
                  color="brand-gray-800"
                />
              </button>
            </Menu.Target>
            <Menu.Dropdown>
              {preSetDates?.map(preDates => (
                <Menu.Item
                  component="a"
                  onClick={() => {
                    setChosenPreDate(preDates.title)
                    setDate(v => [preDates.fromDate, preDates.toDate])
                  }}
                >
                  <div className="flex justify-between items-center w-[260px] ">
                    <p className="flex-1">{preDates.title}</p>
                    <div className="flex flex-row gap-1 text-brand-dark-text-subtle-description text-xs">
                      <p>{moment(preDates.fromDate).format('DD MMM')}</p>-
                      <p className="flex-1">
                        {moment(preDates.toDate).format('DD MMM YYYY')}
                      </p>
                    </div>
                  </div>
                </Menu.Item>
              ))}
              <Menu.Item
                onClick={() => {
                  setChosenPreDate('Custom')
                }}
                component="a"
              >
                <p className="text-center">Custom</p>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          {chosenPreDate === 'Custom' ? (
            <DatePickerInput
              popoverProps={{ defaultOpened: true }}
              type="range"
              placeholder={`${moment(date[0]).format('DD MMM YYYY')}
               - ${moment(date[1]).format('DD MMM YYYY')}`}
              value={date}
              valueFormat="D MMM YYYY"
              maxDate={new Date()}
              onChange={v => {
                if (!v[0] && !v[1]) {
                  setDate(v => [v[0], new Date()])
                } else {
                  setDate(v)
                }
              }}
              classNames={{
                month: 'w-auto',
                input:
                  '!rounded-lg !border-none !shadow-sm hover:bg-brand-gray-100 !px-3 !py-2.5 !text-sm !font-medium !flex !justify-between !items-center',
              }}
            />
          ) : null}
          <Menu shadow="md" position="bottom-start">
            <Menu.Target>
              <button className="bg-white hover:bg-brand-gray-100  gap-8 shadow-sm flex justify-between items-center h-full rounded-lg px-3 py-2.5 transition-all duration-300 text-sm font-medium">
                <p>
                  {currentCurrency?.name} ({currentCurrency?.isoCode})
                </p>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size="xs"
                  color="brand-gray-800"
                />
              </button>
            </Menu.Target>
            <Menu.Dropdown>
              {fiatCurrencies?.map(currency => (
                <Menu.Item
                  component="a"
                  onClick={() => {
                    setCurrentCurrency(currency)
                  }}
                >
                  {currency.name}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </div>
        <div className="bg-white shadow-sm lg:p-9 h-auto overflow-hidden rounded-2xl border-solid border-[1px] border-black/10">
          <h1 className="font-medium pt-8 px-6 lg:p-0 lg:mb-8 text-xl">
            OTC Desk
          </h1>
          <div className="flex flex-col lg:flex-row">
            {perfomanceIsLoading || !currentCurrency ? (
              <div className="flex justify-center w-full py-8">
                <Spinner />
              </div>
            ) : (
              <PerformanceStep
                currency={currentCurrency}
                performance={performance}
              />
            )}
          </div>
        </div>
      </div>
    </PageWrapper>
  )
}

export default PerformanceScreen
