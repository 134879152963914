import { motion } from 'framer-motion'
import type React from 'react'
import { type HTMLProps, useEffect, useState } from 'react'

interface Props {
  isVisible: boolean
  children?: React.ReactNode
  delayInMS?: number
  className?: HTMLProps<HTMLElement>['className']
  durationInS?: number
}

const AnimateHeight: React.FC<Props> = ({
  isVisible,
  children,
  delayInMS,
  className,
  durationInS,
}) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => {
        setVisible(isVisible)
      }, delayInMS)
    } else {
      setVisible(isVisible)
    }
  }, [isVisible])

  const variants = {
    open: {
      opacity: 1,
      height: 'auto',
      x: 0,
    },
    collapsed: { opacity: 0, height: 0, x: 0 },
  }

  return (
    <motion.div
      className={`overflow-hidden ${className}`}
      initial={visible ? 'open' : 'collapsed'}
      animate={visible ? 'open' : 'collapsed'}
      inherit={false}
      variants={variants}
      transition={{ duration: durationInS ?? 0.35, ease: 'easeOut' }}
    >
      {children}
    </motion.div>
  )
}

export default AnimateHeight
