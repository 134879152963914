import { useRef, useState } from 'react'

export default function useAsyncAlert<T extends {}, Y extends {}>() {
  const [alertState, setAlertState] = useState<{
    isOpen: boolean
    data?: T
    onClickPayload?: Y
  }>({ isOpen: false })
  const confirmPromiseRef = useRef<((confirmed: boolean) => void) | null>(null)

  const openAlertAndAwaitConfirmation: (
    data?: T,
    onClickPayload?: Y
  ) => Promise<boolean> = async (data, onClickPayload) => {
    setAlertState({
      isOpen: true,
      data,
      onClickPayload,
    })
    return await new Promise<boolean>(
      resolve => (confirmPromiseRef.current = resolve)
    )
  }

  return {
    openAlertAndAwaitConfirmation,
    alertProps: {
      alertState,
      setAlertState,
      confirmPromiseRef,
    },
  }
}
