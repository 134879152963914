import { faBuilding, faUser } from '@fortawesome/free-solid-svg-icons'
import type React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import type { Role } from '../../../types/coreApi-types'
import AccountTypeItem from './AccountTypeItem'
import Form from './Form'

interface AccountTypeFormProps {
  onClickContinue: (role: Role) => void
}

const AccountTypeForm: React.FC<AccountTypeFormProps> = ({
  onClickContinue,
}) => {
  const history = useHistory()
  const [role, setRole] = useState<Role | null>(null)
  return (
    <Form
      direction="fromRight"
      title="Select account type"
      backTitle="Back to login"
      onPressBack={() => history.push('/')}
    >
      <div className="space-y-2">
        <AccountTypeItem
          title="Individual"
          description="Select this if you are a private investor that does not invest on behalf of a company."
          icon={faUser}
          isActive={role === 'Individual'}
          onClick={() => setRole('Individual')}
        />
        <AccountTypeItem
          title="Organization"
          description="Select this if you are a registered company and may want multiple users to access the Penning Dashboard."
          icon={faBuilding}
          isActive={role === 'Organization'}
          onClick={() => setRole('Organization')}
        />
      </div>
      <button
        disabled={!role}
        onClick={() => {
          if (!role) return
          onClickContinue(role)
        }}
        className="button mt-7"
      >
        Continue
      </button>
    </Form>
  )
}

export default AccountTypeForm
