export const setTheme = (theme: 'dark' | 'light') => {
  if (theme === 'dark') {
    document.documentElement.classList.add('dark')
    document.body.setAttribute('theme', 'dark')
    localStorage.setItem('color-theme', 'dark')
  } else {
    document.documentElement.classList.remove('dark')
    document.body.setAttribute('theme', 'light')
    localStorage.setItem('color-theme', 'light')
  }
}
