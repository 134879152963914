import { useDisclosure, useToast } from '@chakra-ui/react'
import sortBy from 'lodash/sortBy'
import type React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import AddBankAccountModal from '../../../../../components/AddBankAccountModal'
import AddCurrencyModal from '../../../../../components/AddCurrencyModal'
import AddNetworkModal from '../../../../../components/AddNetworkModal'
import AddWalletModal from '../../../../../components/AddWalletModal'
import InfoBox from '../../../../../components/InfoBox'
import PageWrapper from '../../../../../components/Pagewrapper'
import Table from '../../../../../components/Table/Table'
import { useGetOfframpDetailsQuery } from '../../../../../redux/services/coreApi'
import { setCurrenciesTableState } from '../../../../../redux/slices/tableFiltersSlice'
import { RootState } from '../../../../../redux/store'
import type { CurrencyToOffRampDto } from '../../../../../types/coreApi-types'
import CurrencyOverviewDetailsModal from './components/CurrencyOverviewDetailsModal'
import ImageAndValue from './components/ImageAndValue'

type CurrenciesScreenProps = {}

const CurrenciesScreen: React.FC<CurrenciesScreenProps> = () => {
  const toast = useToast()
  const { data, isLoading } = useGetOfframpDetailsQuery(null)

  const [chosenCurrency, setChosenCurrency] =
    useState<CurrencyToOffRampDto | null>(null)
  const changeStatusModal = useDisclosure()
  const addNetworkModal = useDisclosure()
  const addCurrencyModal = useDisclosure()
  const addWalletModal = useDisclosure()
  const addBankAccountModal = useDisclosure()

  const currenciesTableState = useSelector(
    (state: RootState) => state.currenciesTable
  )

  return (
    <PageWrapper title="Currency Overview">
      <Table
        tableState={currenciesTableState}
        setTableState={setCurrenciesTableState}
        navigate={() => null}
        isLoading={isLoading}
        onClickRow={v => {
          if (!v) return
          setChosenCurrency(v as any)
          changeStatusModal.onOpen()
        }}
        emptyDataRender={<div className="text-xl mb-3">No data</div>}
        defaultData={[
          ...(data?.map(item => {
            return {
              ...item,
              name: item.name,
              networks: item.networks,
              active: item.active ? 'Active' : 'Inactive',
            }
          }) ?? []),
        ]}
        schema={[
          {
            cardTitle: true,
            header: 'Token',
            accessorKey: 'name',
            type: 'string',
            priority: 1,
            cellValueWrapper: (value, originalValue, { original }) => (
              <div className="w-min">
                <ImageAndValue value={value} iconUrl={original?.iconUrl} />
              </div>
            ),
          },
          {
            header: 'Network/BIC',
            accessorKey: 'networks',
            type: 'string',
            priority: 2,
            cellValueWrapper: (value, originalValue, { original }) => (
              <div className="w-min flex flex-col">
                <div className="!gap-1 flex flex-col">
                  {original?.networks ? (
                    sortBy(original.networks, n => n.id).map((n, i) => {
                      return (
                        <ImageAndValue
                          key={i}
                          value={n.name}
                          iconUrl={n.iconUrl}
                        />
                      )
                    })
                  ) : (
                    <ImageAndValue
                      value={
                        original?.systemBankAccount?.bankAccountDetails.bic ||
                        original?.systemBankAccount?.bankAccountDetails
                          .registration ||
                        ''
                      }
                    />
                  )}
                </div>
              </div>
            ),
          },
          {
            header: 'Wallet/IBAN',
            accessorKey: 'supportedSystemWallets',
            type: 'string',
            priority: 3,
            cellValueWrapper: (value, originalValue, { original }) => (
              <div className="w-min flex flex-col gap-5">
                {original?.supportedSystemWallets ? (
                  sortBy(original.supportedSystemWallets, w => w.networkId).map(
                    (w, i) => {
                      return <ImageAndValue key={i} value={w.address} tooltip />
                    }
                  )
                ) : (
                  <ImageAndValue
                    value={
                      original?.systemBankAccount?.bankAccountDetails
                        .accountNumber ||
                      original?.systemBankAccount?.bankAccountDetails.iban ||
                      ''
                    }
                    tooltip
                  />
                )}
              </div>
            ),
          },
          {
            header: 'Destination tag',
            accessorKey: 'destinationTag',
            type: 'string',
            priority: 4,
            cellValueWrapper: (value, originalValue, { original }) => (
              <div className="w-min flex flex-col gap-5">
                {original?.supportedSystemWallets &&
                  sortBy(original.supportedSystemWallets, w => w.networkId).map(
                    (w, i) => {
                      if (w.destinationTag) {
                        return (
                          <ImageAndValue
                            key={i}
                            value={w.destinationTag || ''}
                            tooltip
                          />
                        )
                      }
                      // 20px height for same height as text to align properly
                      return <div key={i} className="h-[20px]"></div>
                    }
                  )}
              </div>
            ),
          },
          {
            header: 'Status',
            accessorKey: 'active',
            type: 'status',
            priority: 5,
            cellValueWrapper: (value, originalValue, row) => (
              <div className="w-min flex flex-col">
                <InfoBox colorScheme={value === 'Active' ? 'green' : 'red'}>
                  {value === 'Active' ? 'Active' : 'Inactive'}
                </InfoBox>
              </div>
            ),
          },
        ]}
      />

      <div className="w-fit card mt-6">
        <p className="font-medium text-md">Add new</p>
        <div className="flex space-x-4">
          <button className="button" onClick={addNetworkModal.onOpen}>
            Network
          </button>
          <button className="button" onClick={addWalletModal.onOpen}>
            Wallet
          </button>
          <button className="button" onClick={addBankAccountModal.onOpen}>
            Bank Account
          </button>
          <button className="button" onClick={addCurrencyModal.onOpen}>
            Currency
          </button>
        </div>
      </div>

      <CurrencyOverviewDetailsModal
        isOpen={changeStatusModal.isOpen}
        onClose={changeStatusModal.onClose}
        currency={chosenCurrency}
      />
      <AddNetworkModal
        isOpen={addNetworkModal.isOpen}
        onClose={addNetworkModal.onClose}
      />
      <AddCurrencyModal
        isOpen={addCurrencyModal.isOpen}
        onClose={addCurrencyModal.onClose}
      />
      <AddWalletModal
        isOpen={addWalletModal.isOpen}
        onClose={addWalletModal.onClose}
      />
      <AddBankAccountModal
        isOpen={addBankAccountModal.isOpen}
        onClose={addBankAccountModal.onClose}
      />
    </PageWrapper>
  )
}

export default CurrenciesScreen
