import { Spinner } from '@chakra-ui/react'
import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import {
  faAngleLeft,
  faAngleRight,
  faCheck,
  faChevronDown,
  faChevronUp,
  faEllipsis,
  faFont,
  faHashtag,
  faList,
  faLock,
  faRefresh,
  faTableCellsLarge,
  faTableColumns,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Drawer, Menu, Popover, Skeleton, Tooltip } from '@mantine/core'
import { DatePickerInput, type DateValue } from '@mantine/dates'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import type { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { type RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import {
  type CellContext,
  type Column,
  type ColumnDef,
  type ColumnFiltersState,
  type ColumnOrderState,
  type FilterFn,
  type FilterFnOption,
  type Row,
  type RowSelectionState,
  type SortingFnOption,
  type SortingState,
  type Table,
  type VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { debounce } from 'lodash'
import moment from 'moment'
import type React from 'react'
import {
  type Dispatch,
  type InputHTMLAttributes,
  type SetStateAction,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import type { PillColor } from './Pill'
import TableIcon, { type TableIcons } from './TableIcon'
import './tablestyles.css'
import roundedNumberToString from '../../utils/roundedNumberToString'
import AnimateWidth from '../AnimateWidth'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
    isWithinRange: FilterFn<unknown>
    notIncluded: FilterFn<unknown>
    isBetween: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  if (row.original['isLoading']) return true
  const itemRank = rankItem(row.getValue(columnId), value)

  addMeta({
    itemRank,
  })

  return itemRank.passed
}

const isWithinRange: FilterFn<any> = (row, columnId, value) => {
  if (row.original['isLoading']) return true
  const rowValue = row.getValue(columnId) as string
  const date = new Date(rowValue)

  const start = moment(value[0]).startOf('d').toDate()
  const end = moment(value[1]).endOf('d').toDate()
  if ((start || end) && !date) return false
  if (start && !end) {
    return date.getTime() >= start.getTime()
  } else if (!start && end) {
    return date.getTime() <= end.getTime()
  } else if (start && end) {
    return date.getTime() >= start.getTime() && date.getTime() <= end.getTime()
  } else return true
}

const notIncluded: FilterFn<any> = (row, columnId, value) => {
  const rowValue = row.getValue(columnId) as string
  if (value.includes(rowValue)) return false
  return true
}

const isBetween: FilterFn<any> = (row, columnId, value) => {
  if (row.original['isLoading']) return true
  const rowValue = row.getValue(columnId) as string
  const number = Number(rowValue)
  const minNumber = Number(value[0])
  const maxNumber = Number(value[1])

  if (!maxNumber && !minNumber) return true
  if (!maxNumber && number > minNumber) return true
  if (!minNumber && (number < maxNumber || !number)) return true
  if (number < maxNumber && number > minNumber) return true
  return false
}

export interface TableState {
  columnVisibility: VisibilityState
  columnOrder: ColumnOrderState
  columnFilters: ColumnFiltersState
  globalFilter: string
  sorting: SortingState
  pageIndex: number
}

export type SchemaType = 'date' | 'string' | 'number' | 'status'
export type TableType = 'table' | 'cards'

export type RowButton<T> = {
  title?: string
  icon: TableIcons
  bgColor?: string
  tooltip?: string
  onClick: (value: T) => void
  hidden: (value: T) => boolean
  disabled?: (value: T) => boolean
  navigation?: (value: T) => string
}

export type RowMenuAction<T> = {
  title: string
  onClick: (value: T) => void
  hidden: (value: T) => boolean
  disabled?: (value: T) => boolean
  isLoading?: (value: T) => boolean
}

export type TableMenuAction<T> = {
  title: string
  onClick: (values: T[]) => void
  hidden: (values: T[]) => boolean
  disabled?: (values: T[]) => boolean
}

export type SelectMenuAction<T> = {
  title: string
  icon: TableIcons
  selected: (values: T[]) => T[]
  onClick: (rows: Row<T>[]) => Promise<Row<T>[]>
  hidden: (values: T[]) => boolean
  disabled?: (values: T[]) => boolean
}

export type SchemaItem<T> = {
  cardTitle?: boolean
  header: string
  accessorKey: string
  priority: number
  type: SchemaType
  permanent?: boolean
  newlyAdded?: string
  suffix?: string
  initialSorted?: boolean
  cellValueWrapper?: (
    value: string,
    originalValue: string,
    row: Row<T>
  ) => JSX.Element
  pillColor?: (arg: string) => PillColor
  emptyStatusTitle?: string
}

interface TableProps<T> {
  defaultData: T[]
  onClickRow: (value: T, row: Row<T>) => void
  isDisabledRow?: (value: T, row: Row<T>) => boolean
  navigate: (value: T) => string | null
  isLoading?: boolean
  schema: SchemaItem<T>[]
  rowButtons?: RowButton<T>[]
  rowMenuActions?: RowMenuAction<T>[]
  tableMenuActions?: TableMenuAction<T>[]
  selectMenuActions?: SelectMenuAction<T>[]
  emptyDataRender: JSX.Element
  tableState: TableState
  setTableState: ActionCreatorWithPayload<TableState, string>
  initialPageSize?: number
  downloadInvoicesCb?: (rows: Row<T>[]) => void
}

const Table = <T extends object & { id: string }>({
  isLoading,
  onClickRow,
  isDisabledRow,
  navigate,
  defaultData,
  schema,
  rowButtons,
  rowMenuActions,
  tableMenuActions,
  selectMenuActions,
  emptyDataRender,
  tableState,
  setTableState,
  initialPageSize,
  downloadInvoicesCb,
}: TableProps<T>) => {
  const defaultColumns = useMemo<ColumnDef<T, any>[]>(
    () => [
      ...schema.map(item => {
        const accessorKey = item.accessorKey
        let cell = (info: CellContext<T, unknown>) => info.getValue()
        let filterFn: FilterFnOption<T> | undefined = undefined
        let sortingFn: SortingFnOption<T> | undefined = undefined
        let enableGlobalFilter = false
        const sortDescFirst = false

        switch (item.type) {
          case 'date':
            cell = info =>
              info.getValue()
                ? moment(info.getValue() as string).format('DD MMM YYYY')
                : ''
            filterFn = 'isWithinRange'
            break
          case 'string':
            filterFn = 'fuzzy'
            enableGlobalFilter = true
            break
          case 'number':
            filterFn = 'isBetween'
            sortingFn = (rowA, rowB, columnId) => {
              const numA = rowA.getValue(columnId) ?? (0 as number)
              const numB = rowB.getValue(columnId) ?? (0 as number)

              return numB < numA ? 1 : numB > numA ? -1 : 0
            }
            cell = info =>
              info.getValue()
                ? roundedNumberToString(info.getValue() as number, 2)
                : ''
            break
          case 'status':
            filterFn = 'notIncluded'
            break
          default:
            break
        }
        const oldCell = cell
        if (item.suffix) {
          cell = info =>
            info.getValue() ? (oldCell(info) as string) + item.suffix : ''
        }

        if (item.cellValueWrapper) {
          const initialCell = cell
          cell = info =>
            item.cellValueWrapper
              ? item.cellValueWrapper(
                  initialCell(info) as string,
                  oldCell(info) as string,
                  info.row
                )
              : oldCell
        }

        if (cell) {
          const finalCell = cell
          cell = info => {
            const original = info.row.original as Record<string, T>
            return original['isLoading'] && !finalCell(info) ? (
              <Skeleton height={23} radius={15} width={'70%'} />
            ) : (
              finalCell(info)
            )
          }
        }
        if (sortingFn) {
          return {
            header: item.header,
            accessorKey: accessorKey,
            cell: cell,
            filterFn: filterFn,
            enableGlobalFilter: enableGlobalFilter,
            sortingFn: sortingFn,
            sortDescFirst: sortDescFirst,
          }
        }

        return {
          header: item.header,
          accessorKey: accessorKey,
          cell: cell,
          filterFn: filterFn,
          enableGlobalFilter: enableGlobalFilter,
          sortDescFirst: sortDescFirst,
        }
      }),
    ],
    []
  )

  const [data, setData] = useState(() => [...defaultData])

  useEffect(() => {
    setData(() => [...defaultData])
  }, [defaultData])

  const smallScreen = useMediaQuery('(max-width: 1024px)')

  const [tableType, setTableType] = useState<TableType>(
    smallScreen ? 'cards' : 'table'
  )

  useEffect(() => {
    if (smallScreen) setTableType('cards')
    if (!smallScreen) setTableType('table')
  }, [smallScreen])

  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
    tableState.columnVisibility ?? {}
  )

  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([
    ...schema
      .sort((a, b) => a.priority - b.priority)
      .map(item => item.accessorKey),
  ])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    tableState.columnFilters.length > 0 ? tableState.columnFilters : []
  )
  const [globalFilter, setGlobalFilter] = useState(
    tableState.globalFilter ?? ''
  )
  const [sorting, setSorting] = useState<SortingState>(
    tableState.sorting.length > 0
      ? tableState.sorting
      : [
          ...(schema.find(item => item.initialSorted)
            ? [
                {
                  id: schema.find(item => item.initialSorted)!.accessorKey,
                  desc: true,
                },
              ]
            : []),
        ]
  )

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})

  const initialPageIndex = useMemo(() => tableState.pageIndex, [])

  const initialTablePageSize = initialPageSize ?? 16

  const maxSelectedLimit = 200

  const isDisabledSelectLimitWrapper = (original: T, row: Row<T>) => {
    if (
      Object.keys(rowSelection).filter(item => item).length >=
        maxSelectedLimit &&
      !rowSelection[row.id]
    ) {
      return true
    }

    if (isDisabledRow) {
      return isDisabledRow(original, row)
    }

    return false
  }

  const table = useReactTable<T>({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
      isWithinRange: isWithinRange,
      notIncluded: notIncluded,
      isBetween: isBetween,
    },
    state: {
      columnVisibility,
      globalFilter,
      columnFilters,
      sorting,
      columnOrder,
      rowSelection,
    },
    initialState: {
      pagination: {
        pageSize: initialTablePageSize,
        pageIndex: initialPageIndex,
      },
    },
    enableRowSelection: row => !isDisabledSelectLimitWrapper(row.original, row),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: fuzzyFilter,
    autoResetPageIndex: false,
  })

  // Save state with redux. Make more generic in the future.
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      setTableState({
        columnVisibility,
        columnOrder,
        columnFilters,
        globalFilter,
        sorting,
        pageIndex: table.getState().pagination.pageIndex,
      })
    )
  }, [
    columnVisibility,
    columnOrder,
    columnFilters,
    globalFilter,
    sorting,
    table.getState().pagination.pageIndex,
  ])

  const maxColumnWidth = 250
  const maxCardWidth = 350
  const cardRows = 3

  useEffect(() => {
    if (
      table.getPageCount() > 0 &&
      table.getState().pagination.pageIndex + 1 > table.getPageCount()
    ) {
      table.setPageIndex(table.getPageCount() - 1)
    }
  }, [table.getPageCount()])

  useEffect(() => {
    if (tableState.columnVisibility) return

    const allHideableColumnIds = table
      .getAllColumns()
      .filter(column => column.getCanHide())
      .map(column => column.id)

    const initialColumnIds: string[] = schema
      .sort((a, b) => {
        if (b.permanent) return 1
        if (a.permanent) return -1
        if (tableState.columnVisibility[b.accessorKey]) {
          return 1
        }
        if (tableState.columnVisibility[a.accessorKey]) {
          return -1
        }

        return a.priority - b.priority
      })
      .slice(0, maxVisibleColumns)
      .map(item => item.accessorKey)

    const initialColumnVisibility: VisibilityState =
      allHideableColumnIds.reduce((visibilityState, columnId) => {
        visibilityState[columnId] = initialColumnIds.includes(columnId)
        return visibilityState
      }, {} as VisibilityState)

    setColumnVisibility(initialColumnVisibility)
  }, [])

  const enabledColumns = Object.entries(columnVisibility).filter(
    item => item[1] === true
  )

  const tableWidthRef = useRef<HTMLDivElement>(null)
  const [tableWidth, setTableWidth] = useState(0)

  const maxVisibleColumns = useMemo(
    () =>
      Math.floor(
        tableWidth / (tableType === 'table' ? maxColumnWidth : maxCardWidth)
      ) || 1,
    [tableWidth, tableType, tableWidthRef.current]
  )

  useEffect(() => {
    if (!tableWidthRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver(
      debounce(() => {
        if (
          tableWidthRef.current &&
          tableWidthRef.current.offsetWidth !== tableWidth
        ) {
          setTableWidth(tableWidthRef.current.offsetWidth)
        }
      }, 100)
    )

    resizeObserver.observe(tableWidthRef.current)

    return function cleanup() {
      resizeObserver.disconnect()
    }
  }, [tableWidthRef.current])

  const [isFirstMount, setIsFirstMount] = useState(true)
  const [isFakeLoading, setIsFakeLoading] = useState(true)

  useEffect(() => {
    if (tableWidth === 0) return
    if (isFirstMount) setIsFakeLoading(true)

    if (tableType === 'cards')
      table.setPageSize((maxVisibleColumns || 1) * cardRows)
    if (tableType === 'table') table.setPageSize(initialTablePageSize)

    const allHideableColumnIds = table
      .getAllColumns()
      .filter(column => column.getCanHide())
      .map(column => column.id)

    const columnIds: string[] = schema
      .sort((a, b) => {
        if (b.permanent) return 1
        if (a.permanent) return -1
        if (tableState.columnVisibility[b.accessorKey]) {
          return 1
        }
        if (tableState.columnVisibility[a.accessorKey]) {
          return -1
        }

        return a.priority - b.priority
      })
      .slice(0, tableType === 'table' ? maxVisibleColumns : 5)
      .map(item => item.accessorKey)

    const columnVisibility: VisibilityState = allHideableColumnIds.reduce(
      (visibilityState, columnId) => {
        visibilityState[columnId] = columnIds.includes(columnId)
        return visibilityState
      },
      {} as VisibilityState
    )

    setColumnVisibility(columnVisibility)

    setTimeout(() => {
      setIsFakeLoading(false)
      setIsFirstMount(false)
    }, 100)
  }, [maxVisibleColumns, tableWidth])

  const searchRef = useRef<HTMLInputElement>(null)

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.metaKey && (event.key === 'K' || event.key === 'k')) {
      event.preventDefault()
      searchRef.current?.focus()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const [opened, { open, close }] = useDisclosure(false)

  const [selectMenuisLoadingState, setSelectMenuisLoadingState] =
    useState(false)

  const [hoveredMenuAction, setHoveredMenuAction] = useState('')

  const selectedHoveredRows = useMemo(
    () =>
      selectMenuActions
        ?.find(item => item.title === hoveredMenuAction)
        ?.selected(table.getSelectedRowModel().rows.map(row => row.original)) ??
      [],
    [hoveredMenuAction, table.getSelectedRowModel().rows]
  )

  return (
    <div className="w-full text-sm font-medium h-full flex flex-col">
      {smallScreen ? (
        <>
          <div className="w-full flex justify-between gap-2 h-[42px]">
            <DebouncedInput
              ref={searchRef}
              value={globalFilter ?? ''}
              onChange={value => setGlobalFilter(String(value))}
              className="p-2 font-lg border border-block rounded-lg bg-white border-brand-gray-200 border-solid w-full hover:!bg-brand-gray-50 focus:!bg-brand-gray-50"
              containerClassName="w-full !rounded-full border-brand-gray-200 !bg-white hover:!bg-brand-gray-50 focus-within:!bg-brand-gray-50"
              placeholder="Search"
              renderLeft={
                <div
                  className={`top-0 h-full flex items-center font-semi text-base`}
                >
                  <TableIcon
                    icon="search"
                    className="w-[16px] text-brand-gray-800"
                  />
                </div>
              }
            />
            <button
              onClick={open}
              className="border rounded-full bg-white border-brand-gray-200 border-solid min-w-[42px] w-[42px] flex justify-center items-center active:bg-brand-gray-200 hover:bg-brand-gray-100 transition-all"
            >
              <TableIcon
                icon="filter"
                className="text-brand-gray-800 h-[20px] w-[20px]"
              />
            </button>
          </div>
          <Drawer
            classNames={{ body: '!p-0' }}
            styles={{ body: { height: 'calc(100% - 74px)' } }}
            withCloseButton={false}
            position="right"
            opened={opened}
            onClose={close}
          >
            <Drawer.Header>
              <Drawer.Title>
                <SelectColumnMenu
                  forDrawer
                  enabledColumns={enabledColumns}
                  maxVisibleColumns={maxVisibleColumns}
                  schema={schema}
                  table={table}
                  tableType={tableType}
                />
              </Drawer.Title>
              <Drawer.CloseButton className="!bg-brand-gray-100 !rounded-full !w-[42px] !h-[42px] !p-2" />
            </Drawer.Header>
            <FilterDrawerContent
              table={table}
              schema={schema}
              columnVisibility={columnVisibility}
              onClose={close}
            />
          </Drawer>
        </>
      ) : (
        <div className="flex w-full justify-between items-end gap-2 flex-wrap">
          <div className="flex gap-1.5 h-[36px]">
            <FilterButtons
              table={table}
              schema={schema}
              columnVisibility={columnVisibility}
              downloadInvoicesCb={downloadInvoicesCb}
            />
          </div>

          <div className="flex gap-1.5 h-[36px]">
            {(table.getAllColumns().some(item => item.getIsFiltered()) ||
              table.getState().globalFilter) && (
              <button
                className="group base-button flex gap-2 items-center px-3"
                onClick={() => {
                  table.resetColumnFilters()
                  table.resetGlobalFilter()
                }}
              >
                <TableIcon
                  icon="reset"
                  className="w-[15px] group-hover:animate-[spin_1.5s_ease-out_infinite]"
                />
                <div>Reset</div>
              </button>
            )}
            <SelectColumnMenu
              enabledColumns={enabledColumns}
              maxVisibleColumns={maxVisibleColumns}
              schema={schema}
              table={table}
              tableType={tableType}
            />

            <DebouncedInput
              ref={searchRef}
              value={globalFilter ?? ''}
              onChange={value => setGlobalFilter(String(value))}
              className="p-2 font-lg base-button !shadow-none !border-none"
              containerClassName="base-button"
              placeholder="Search"
              renderLeft={
                <div
                  className={`top-0 h-full flex items-center font-semi text-base`}
                >
                  <TableIcon
                    icon="search"
                    className="text-brand-gray-800 w-[15px]"
                  />
                </div>
              }
              renderRight={
                <div
                  className={`p-1 bg-brand-gray-100 text-brand-gray-700 rounded text-2xs px-2 `}
                >
                  ⌘ + K
                </div>
              }
            />

            <TableTypeButtons
              tableType={tableType}
              setTableType={setTableType}
            />
          </div>
        </div>
      )}

      <div className="min-h-[16px]" />
      {tableType === 'table' && (
        <div
          ref={tableWidthRef}
          className={`border border-solid border-gray-300 rounded-xl overflow-hidden h-full flex flex-col relative shrink max-h-[847px] bg-white`}
        >
          <div className="absolute w-full overflow-hidden flex flex-col h-full">
            <TableHeader
              table={table}
              maxVisibleColumns={maxVisibleColumns}
              menuActions={tableMenuActions}
              isSelectable={!!selectMenuActions}
              rowMenuActions={!!rowMenuActions}
              rowButtons={!!rowButtons}
              maxSelectedLimit={maxSelectedLimit}
              isSelectMenuLoading={selectMenuisLoadingState}
              isLoading={isFakeLoading}
            />
            <div className="h-full w-full overflow-auto scrollbar-none">
              {isLoading || isFakeLoading ? (
                <RowsLoading table={table} />
              ) : table.getRowModel().rows.length > 0 ? (
                <Rows
                  table={table}
                  onClick={onClickRow}
                  isDisabled={isDisabledSelectLimitWrapper}
                  navigate={navigate}
                  schema={schema}
                  menuActions={rowMenuActions}
                  buttons={rowButtons}
                  maxVisibleColumns={maxVisibleColumns}
                  isSelectable={!!selectMenuActions}
                  isSelectMenuLoading={selectMenuisLoadingState}
                  selectedHoveredRows={selectedHoveredRows}
                />
              ) : defaultData.length === 0 ? (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  {emptyDataRender}
                </div>
              ) : (
                <div className="w-full h-full flex flex-col items-center justify-center">
                  <FiltersNoMatches table={table} />
                </div>
              )}
            </div>
            {selectMenuActions && (
              <SelectMenu
                table={table}
                selectMenuActions={selectMenuActions}
                isLoading={selectMenuisLoadingState}
                setIsLoading={setSelectMenuisLoadingState}
                setHoveredMenuAction={setHoveredMenuAction}
              />
            )}
          </div>
        </div>
      )}
      {tableType === 'cards' && (
        <>
          <div
            ref={tableWidthRef}
            className={`grid relative overflow-hidden ${
              'grid-cols-' + maxVisibleColumns
            } w-full gap-3 grid-rows-3 min-h-[750px]`}
          >
            {isLoading ? (
              <CardsLoading table={table} />
            ) : table.getRowModel().rows.length > 0 ? (
              <Cards
                table={table}
                onClick={onClickRow}
                isDisabled={isDisabledSelectLimitWrapper}
                navigate={navigate}
                schema={schema}
                menuActions={rowMenuActions}
                isSelectMenuLoading={selectMenuisLoadingState}
                isSelectable={!!selectMenuActions}
                buttons={rowButtons}
              />
            ) : defaultData.length === 0 ? (
              <div
                className={`w-full h-[400px] flex flex-col items-center justify-center col-span-full`}
              >
                {emptyDataRender}
              </div>
            ) : (
              <div
                className={`w-full h-[400px] flex flex-col items-center justify-center col-span-full `}
              >
                <FiltersNoMatches table={table} />
              </div>
            )}
            {selectMenuActions && (
              <SelectMenu
                table={table}
                selectMenuActions={selectMenuActions}
                isLoading={selectMenuisLoadingState}
                setIsLoading={setSelectMenuisLoadingState}
                setHoveredMenuAction={setHoveredMenuAction}
              />
            )}
          </div>
        </>
      )}
      <div className="min-h-[40px]" />
      {defaultData.length > 0 && table.getRowModel().rows.length > 0 && (
        <Pagination table={table} />
      )}
    </div>
  )
}

export default Table

const DebouncedInput = forwardRef<
  HTMLInputElement,
  {
    value?: string | number
    onChange: (value?: string | number) => void
    debounce?: number
    renderLeft?: JSX.Element
    renderRight?: JSX.Element
    containerClassName?: string
  } & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>
>(
  (
    {
      value: initialValue,
      onChange,
      debounce = 500,
      renderLeft,
      renderRight,
      containerClassName,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(initialValue)

    const [first, setFirst] = useState(true)

    useEffect(() => {
      if (first) {
        setFirst(false)
        return
      }
      setValue(initialValue)
    }, [initialValue])

    useEffect(() => {
      if (first) {
        setFirst(false)
        return
      }
      const timeout = setTimeout(() => {
        onChange(value)
      }, debounce)

      return () => clearTimeout(timeout)
    }, [value])

    const [isFocused, setIsFocused] = useState(false)

    return (
      <div
        className={`group ${containerClassName} overflow-y-hidden flex items-center pl-3 pr-2 focus-within:bg-brand-gray-50 hover:bg-brand-gray-50 rounded-lg bg-brand-gray-50 gap-3 border border-solid h-full relative transition-all`}
      >
        <div className="flex items-center gap-1 w-full">
          {renderLeft}
          <input
            ref={ref}
            onFocus={() => setIsFocused(() => true)}
            onBlur={() => setIsFocused(() => false)}
            {...props}
            className={`focus:bg-brand-gray-50 group-hover:bg-brand-gray-50 outline-none border-none transition-all duration-300 ${props.className} placeholder:text-brand-gray-800 focus:placeholder:invisible h-full`}
            value={value}
            onChange={e => {
              setValue(e.target.value)
            }}
          />
        </div>

        <div
          className={`absolute right-2 ${
            isFocused ? 'opacity-0' : 'opacity-100'
          } transition-all`}
        >
          {renderRight}
        </div>
      </div>
    )
  }
)

function statusIcon(status: SchemaType) {
  switch (status) {
    case 'status':
      return faList
    case 'date':
      return faCalendar
    case 'number':
      return faHashtag
    case 'string':
      return faFont
    default:
      return faFont
  }
}

function TableHeader<T>({
  table,
  maxVisibleColumns,
  menuActions,
  rowMenuActions,
  rowButtons,
  isSelectable,
  maxSelectedLimit,
  isSelectMenuLoading,
  isLoading,
}: {
  table: Table<any>
  maxVisibleColumns: number
  maxSelectedLimit: number
  menuActions?: TableMenuAction<T>[]
  rowMenuActions?: boolean
  rowButtons?: boolean
  isSelectable?: boolean
  isSelectMenuLoading?: boolean
  isLoading?: boolean
}) {
  return (
    <div className="bg-brand-gray-50 border-b border-solid border-brand-gray-200 py-1 h-[45px] relative">
      {isLoading ? (
        <div className="w-full flex px-3 items-center justify-center h-full">
          <Skeleton height={23} radius={15} width={'100%'} />
        </div>
      ) : (
        <>
          {table.getHeaderGroups().map(headerGroup => (
            <div
              key={headerGroup.id}
              className="flex justify-between px-3 h-full"
            >
              {isSelectable && (
                <CheckBox
                  isSelected={
                    table.getIsAllPageRowsSelected() &&
                    table
                      .getPaginationRowModel()
                      .rows.some(row => row.getIsSelected()) &&
                    !table
                      .getPaginationRowModel()
                      .rows.some(
                        row => row.getCanSelect() && !row.getIsSelected()
                      )
                  }
                  isDisabled={table
                    .getPaginationRowModel()
                    .rows.every(row => !row.getCanSelect())}
                  isLoading={isSelectMenuLoading}
                  onClick={() => {
                    const toggler = table.getToggleAllPageRowsSelectedHandler()
                    // Only select rows from the current page up to the maximum limit

                    if (table.getIsAllPageRowsSelected()) {
                      toggler({
                        target: { checked: !table.getIsAllPageRowsSelected() },
                      })
                    } else {
                      table
                        .getPaginationRowModel()
                        .rows.filter(
                          row => row.getCanSelect() && !row.getIsSelected()
                        )
                        .slice(
                          0,
                          maxSelectedLimit -
                            table.getSelectedRowModel().rows.length
                        )
                        .forEach(row => {
                          row.toggleSelected()
                        })
                    }
                  }}
                />
              )}
              <div
                className={`grid ${'grid-cols-' + maxVisibleColumns} w-full`}
              >
                {headerGroup.headers.map((header, index) => {
                  const sortedDir = header.column.getIsSorted() as string
                  return (
                    <div
                      key={header.id}
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none col-span-1 p-2 my-auto'
                          : 'col-span-1 p-2 my-auto',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div className="flex gap-2 items-center">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          <div className="flex flex-col">
                            <FontAwesomeIcon
                              icon={faChevronUp}
                              className={`text-[10px] -mb-[5px] ${
                                sortedDir === 'desc'
                                  ? 'text-brand-gray-200'
                                  : 'text-brand-gray-800'
                              }  `}
                            />
                            <FontAwesomeIcon
                              icon={faChevronDown}
                              className={`text-[10px] ${
                                sortedDir === 'asc'
                                  ? 'text-brand-gray-200'
                                  : 'text-brand-gray-800'
                              }`}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
              <div className="flex gap-2">
                {rowButtons && (
                  <div className="min-w-[126px] w-[126px] flex justify-center items-center"></div>
                )}
                {rowMenuActions && (
                  <div className="min-w-[34px] w-[34px] flex justify-center items-center">
                    {menuActions && (
                      <MenuButton
                        menuActions={menuActions}
                        original={
                          table
                            .getSortedRowModel()
                            .rows.map(row => row.original) as any
                        }
                        dark
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  )
}

function RowsLoading({ table }: { table: Table<any> }) {
  return (
    <>
      {Array.from(Array(7)).map((row, index) => (
        <div
          key={index}
          className="h-[50px] flex justify-between border-b border-solid border-brand-gray-200 px-3 -mr-7"
        >
          <div className={`w-full flex items-center justify-center`}>
            <Skeleton height={23} radius={15} width={'100%'} />
          </div>
          <div className="w-[30px]" />
        </div>
      ))}
    </>
  )
}

function Rows<T>({
  table,
  onClick,
  isDisabled,
  navigate,
  maxVisibleColumns,
  schema,
  menuActions,
  buttons,
  isSelectable,
  isSelectMenuLoading,
  selectedHoveredRows,
}: {
  table: Table<any>
  onClick: (value: T, row: Row<T>) => void
  isDisabled?: (value: T, row: Row<T>) => boolean
  isSelectable?: boolean
  navigate: (value: T) => string | null
  maxVisibleColumns: number
  schema: SchemaItem<T>[]
  menuActions?: RowMenuAction<T>[]
  buttons?: RowButton<T>[]
  isSelectMenuLoading?: boolean
  selectedHoveredRows: T[]
}) {
  return (
    <>
      {table.getRowModel().rows.map(row => {
        const isDisabledRow =
          isSelectMenuLoading ||
          (isDisabled ? isDisabled(row.original, row) : false)

        return (
          <Link
            to={isDisabledRow ? '#' : (navigate(row.original) ?? '#')}
            key={row.id}
            className={`transition-all hover:z-10 relative min-h-[50px] flex justify-between border-b border-solid border-brand-gray-200 px-3 cursor-pointer text-xs text-brand-gray-800 hover:shadow-[0_0px_22px_-8px_rgba(0,0,0,0.3)] ${
              isDisabledRow ? '!cursor-not-allowed' : 'cursor-pointer'
            } ${
              selectedHoveredRows.includes(row.original)
                ? 'bg-brand-gray-100 animate-pulses duration-300'
                : row.getIsSelected()
                  ? 'bg-brand-gray-50'
                  : 'bg-white'
            }`}
            onClick={() =>
              isDisabledRow
                ? null
                : navigate(row.original)
                  ? null
                  : onClick(row.original, row)
            }
          >
            {isSelectable && (
              <CheckBox
                isDisabled={isDisabledRow}
                isSelected={row.getIsSelected()}
                onClick={() => row.toggleSelected()}
                isLoading={isSelectMenuLoading}
              />
            )}
            <div className={`grid ${'grid-cols-' + maxVisibleColumns} w-full`}>
              {row.getVisibleCells().map((cell, index) => {
                const schemaColumn = schema.find(
                  item => item.accessorKey === cell.column.id
                )
                const newlyAddedSchema =
                  index === 0 ? schema.find(item => item.newlyAdded) : null
                const newlyAdded =
                  index === 0 &&
                  newlyAddedSchema?.newlyAdded &&
                  moment(
                    cell.row.original[newlyAddedSchema.newlyAdded]
                  ).isAfter(moment().subtract(12, 'hours'))
                return (
                  <div
                    className={`col-span-1 p-2 my-auto relative ${
                      isDisabledRow ? 'text-brand-gray-400' : ''
                    } ${schemaColumn?.type === 'string' ? 'truncate' : ''}`}
                    key={cell.id}
                  >
                    {newlyAdded && (
                      <div className="w-[6px] h-[6px] bg-brand-blue-500 rounded-full absolute -left-[5px] top-[44%]" />
                    )}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                )
              })}
            </div>
            {buttons && <RowButtons buttons={buttons} row={row} />}
            {menuActions && (
              <MenuButton menuActions={menuActions} original={row.original} />
            )}
          </Link>
        )
      })}
    </>
  )
}

function CardsLoading({ table }: { table: Table<any> }) {
  return (
    <>
      {Array.from(Array(table.getState().pagination.pageSize)).map(
        (row, index) => (
          <div
            key={index}
            className="rounded-lg shadow col-span-1 min-h-[242px] h-[242px]"
          >
            <Skeleton height={'100%'} w={'100%'} radius={6} />
          </div>
        )
      )}
    </>
  )
}

function Cards<T>({
  table,
  onClick,
  navigate,
  schema,
  menuActions,
  isDisabled,
  buttons,
  isSelectable,
  isSelectMenuLoading,
}: {
  table: Table<any>
  onClick: (value: T, row: Row<T>) => void
  navigate: (value: T) => string | null
  schema: SchemaItem<T>[]
  menuActions?: RowMenuAction<T>[]
  isDisabled?: (value: T, row: Row<T>) => boolean
  isSelectable?: boolean
  isSelectMenuLoading?: boolean
  buttons?: RowButton<T>[]
}) {
  return (
    <>
      {table.getRowModel().rows.map((row, index) => {
        const isDisabledCard =
          isSelectMenuLoading ||
          (isDisabled ? isDisabled(row.original, row) : false)

        return (
          <Link
            to={isDisabledCard ? '#' : (navigate(row.original) ?? '#')}
            key={row.id}
            className={`transition-all border border-solid border-brand-gray-200 rounded-lg shadow hover:shadow-lg col-span-1 p-2 min-h-[242px] cursor-pointer ${
              isDisabledCard ? '!cursor-not-allowed' : 'cursor-pointer'
            } ${row.getIsSelected() ? 'bg-brand-gray-50' : ''}`}
            onClick={() =>
              isDisabledCard
                ? null
                : navigate(row.original)
                  ? null
                  : onClick(row.original, row)
            }
          >
            <div
              className={`flex flex-col w-full ${
                isDisabledCard ? 'opacity-60' : ''
              }`}
            >
              {row
                .getVisibleCells()
                .filter(
                  item =>
                    item.column.id ===
                    schema.find(item2 => item2.cardTitle)?.accessorKey
                )
                .map(cell => {
                  const newlyAddedSchema = schema.find(item => item.newlyAdded)

                  const newlyAdded =
                    newlyAddedSchema?.newlyAdded &&
                    moment(
                      cell.row.original[newlyAddedSchema.newlyAdded]
                    ).isAfter(moment().subtract(12, 'hours'))

                  return (
                    <div key={cell.id} className="grid grid-cols-2 mb-2">
                      <div
                        className={`col-span-1 truncate p-2 font-semi items-center relative my-auto ${
                          newlyAdded ? 'pl-8' : ''
                        }`}
                        key={cell.id}
                      >
                        {newlyAdded && (
                          <div className="w-[12px] h-[12px] bg-brand-blue-500 rounded-full absolute left-[9px] top-[31%]" />
                        )}
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                      {menuActions && (
                        <div className="col-span-1 p-2 text-end justify-end flex items-center">
                          <MenuButton
                            menuActions={menuActions}
                            original={row.original}
                          />
                        </div>
                      )}
                    </div>
                  )
                })}
              {row
                .getVisibleCells()
                .filter(item =>
                  schema
                    .filter(item2 => !item2.cardTitle)
                    .map(item2 => item2.accessorKey)
                    .includes(item.column.id)
                )
                .map(cell => {
                  const schemaColumn = schema.find(
                    item => item.accessorKey === cell.column.id
                  )
                  if (!schemaColumn) return

                  return (
                    <div key={cell.id} className="grid grid-cols-2">
                      <div className="col-span 1 flex p-2 items-center gap-2 text-brand-gray-600">
                        <FontAwesomeIcon icon={statusIcon(schemaColumn.type)} />
                        <div className="truncate">
                          {cell.column.columnDef.header?.toString()}
                        </div>
                      </div>
                      <div
                        className="col-span-1 truncate p-2 text-end place-self-end flex items-center w-full justify-end"
                        key={cell.id}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>
            {(isSelectable || buttons) && (
              <div className="flex p-2 pt-4 border-t border-brand-gray-200 border-solid justify-between">
                {isSelectable && (
                  <CheckBox
                    isSelected={row.getIsSelected()}
                    onClick={() => row.toggleSelected()}
                    isLoading={isSelectMenuLoading}
                    isDisabled={isDisabledCard}
                  />
                )}
                {buttons && (
                  <RowButtons
                    buttons={buttons.map(button => ({
                      ...button,
                      title: button.title ?? button.tooltip,
                      tooltip: undefined,
                    }))}
                    row={row}
                  />
                )}
              </div>
            )}
          </Link>
        )
      })}
    </>
  )
}

function FiltersNoMatches({ table }: { table: Table<any> }) {
  return (
    <>
      <div className="text-xl mb-3 text-center">No items found</div>
      <div className="text-sm text-brand-gray-500 text-center">
        We couldn't find any items matching the current filters.
      </div>
      <div className="text-sm text-brand-gray-500 text-center">
        Try different filters or clear them.
      </div>
      <button
        className="flex gap-2 items-center bg-brand-gray-50 border-brand-gray-200 border border-solid rounded-lg py-1.5 px-3 mt-6 hover:bg-brand-gray-100 transition-all"
        onClick={() => {
          table.resetColumnFilters()
          table.resetGlobalFilter()
        }}
      >
        <FontAwesomeIcon icon={faRefresh} />
        <div>Clear filters</div>
      </button>
    </>
  )
}

function FilterButtons<T>({
  schema,
  table,
  columnVisibility,
  downloadInvoicesCb,
}: {
  schema: SchemaItem<T>[]
  table: Table<any>
  columnVisibility: VisibilityState
  downloadInvoicesCb?: (rows: Row<T>[]) => void
}) {
  return (
    <>
      {schema
        .filter(
          item => item.type !== 'string' && columnVisibility[item.accessorKey]
        )
        .map(item => {
          const schemaColumn = table
            .getAllColumns()
            .find(column => column.id === item.accessorKey)

          if (!schemaColumn) return

          switch (item.type) {
            case 'number':
              const filterValueNumbers = (schemaColumn.getFilterValue() as [
                number,
                number,
              ]) ?? [undefined, undefined]

              return (
                <Popover
                  key={item.accessorKey}
                  width={400}
                  trapFocus
                  position="bottom-start"
                  shadow="md"
                >
                  <Popover.Target>
                    <button className="base-button flex gap-2 items-center h-full px-3">
                      <div>
                        {schemaColumn.getIsFiltered()
                          ? `${
                              filterValueNumbers[0] && !filterValueNumbers[1]
                                ? 'Min: '
                                : ''
                            }${filterValueNumbers[0] ?? ''}${
                              filterValueNumbers[0] && filterValueNumbers[1]
                                ? ' - '
                                : ''
                            }${
                              filterValueNumbers[1] && !filterValueNumbers[0]
                                ? 'Max: '
                                : ''
                            }${filterValueNumbers[1] ?? ''}`
                          : item.header}
                      </div>
                      {!schemaColumn.getIsFiltered() ? (
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="text-[10px] text-brand-gray-800"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faXmark}
                          className="text-[15px] text-brand-gray-800"
                          onClick={e => {
                            e.stopPropagation()
                            schemaColumn.setFilterValue(undefined)
                          }}
                        />
                      )}
                    </button>
                  </Popover.Target>
                  <Popover.Dropdown className="!rounded-lg">
                    <NumberFilterInputs
                      popover
                      filterValueNumbers={filterValueNumbers}
                      schemaColumn={schemaColumn}
                    />
                  </Popover.Dropdown>
                </Popover>
              )
            case 'date':
              return (
                <DatePickerInput
                  key={item.accessorKey}
                  valueFormat="DD MMM"
                  value={
                    ((schemaColumn.getFilterValue() as [string, string])?.map(
                      item => (item ? new Date(item) : null)
                    ) as [DateValue, DateValue]) ?? [undefined, undefined]
                  }
                  classNames={{
                    input:
                      'base-button !rounded-lg !h-full !text-sm !font-medium !border-brand-gray-200 !text-brand-gray-800',
                    placeholder: '!text-brand-gray-800',
                    root: '!text-brand-gray-800 !h-full',
                    wrapper: '!h-full',
                  }}
                  popoverProps={{
                    classNames: {
                      dropdown: '!rounded-lg !max-w-auto !w-[290px]',
                    },
                  }}
                  placeholder={item.header}
                  rightSection={
                    !schemaColumn.getIsFiltered() ? (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="text-[10px] text-brand-gray-800"
                      />
                    ) : undefined
                  }
                  rightSectionPointerEvents={
                    !schemaColumn.getIsFiltered() ? 'none' : undefined
                  }
                  clearable
                  type="range"
                  onChange={v => {
                    // if (!v[1] && v[0]) return
                    if (!v[1] && !v[0]) {
                      schemaColumn.setFilterValue(undefined)
                      return
                    }
                    schemaColumn.setFilterValue(() => [
                      v[0]?.toISOString(),
                      v[1]?.toISOString(),
                    ])
                  }}
                />
              )
            case 'status':
              const sortedUniqueValues = Array.from(
                schemaColumn.getFacetedUniqueValues().keys()
              ).sort()
              if (sortedUniqueValues.length <= 1) {
                if (
                  !!schemaColumn.getFilterValue() &&
                  !!schemaColumn.getFacetedRowModel().rows.length
                )
                  schemaColumn.setFilterValue(undefined)
                return
              }
              const filterValue =
                (schemaColumn.getFilterValue() as string[]) ?? []

              return (
                <Menu
                  key={item.accessorKey}
                  shadow="md"
                  width={280}
                  position="bottom-start"
                  closeOnItemClick={false}
                >
                  <Menu.Target>
                    <button className="base-button flex gap-2 items-center h-full px-3">
                      <div>{item.header}</div>
                      <div className="bg-brand-gray-100 text-brand-gray-800 rounded-xl px-2">
                        {sortedUniqueValues.length - filterValue.length < 0
                          ? 0
                          : sortedUniqueValues.length - filterValue.length}
                        &nbsp;/&nbsp;
                        {sortedUniqueValues.length < 0
                          ? 0
                          : sortedUniqueValues.length}
                      </div>
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="text-[10px] text-brand-gray-800"
                      />
                    </button>
                  </Menu.Target>
                  <Menu.Dropdown
                    classNames={{
                      dropdown: '!rounded-lg !max-h-[306px] overflow-y-auto',
                    }}
                  >
                    {sortedUniqueValues.map(uniqueValue => {
                      return (
                        <Menu.Item
                          key={uniqueValue}
                          className="!py-2"
                          onClick={() => {
                            schemaColumn.setFilterValue(
                              (oldValue: string[]) => {
                                if ((oldValue ?? []).includes(uniqueValue)) {
                                  const newFilter = oldValue.filter(
                                    v => v !== uniqueValue
                                  )

                                  return newFilter.length === 0
                                    ? undefined
                                    : newFilter
                                }
                                return Array.from(
                                  new Set([...(oldValue ?? []), uniqueValue])
                                )
                              }
                            )
                          }}
                          rightSection={
                            !filterValue.includes(uniqueValue) ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-brand-gray-800"
                              />
                            ) : undefined
                          }
                        >
                          {uniqueValue || item.emptyStatusTitle}
                        </Menu.Item>
                      )
                    })}
                  </Menu.Dropdown>
                </Menu>
              )
            default:
              break
          }
        })}
      {downloadInvoicesCb && (
        <button
          className="base-button flex gap-2 items-center transition-all rounded-lg px-3 h-full font-medium text-brand-gray-800"
          onClick={() => {
            downloadInvoicesCb(table.getRowModel().rows)
          }}
        >
          Download invoices
        </button>
      )}
    </>
  )
}

function FilterDrawerContent<T>({
  schema,
  table,
  columnVisibility,
  onClose,
}: {
  schema: SchemaItem<T>[]
  table: Table<any>
  columnVisibility: VisibilityState
  onClose: () => void
}) {
  const getContent = (
    schemaItem: SchemaItem<T>,
    schemaColumn: Column<any, unknown>
  ) => {
    switch (schemaItem.type) {
      case 'number':
        const filterValueNumbers = (schemaColumn.getFilterValue() as [
          number,
          number,
        ]) ?? [undefined, undefined]

        return (
          <NumberFilterInputs
            filterValueNumbers={filterValueNumbers}
            schemaColumn={schemaColumn}
          />
        )
      case 'date':
        return (
          <DatePickerInput
            key={schemaItem.accessorKey}
            valueFormat="DD MMM"
            value={
              (schemaColumn.getFilterValue() as [DateValue, DateValue]) ?? [
                undefined,
                undefined,
              ]
            }
            classNames={{
              input:
                '!bg-brand-gray-100 !border !border-solid !border-brand-gray-100 !h-[40px] !rounded-lg !text-sm !font-medium !text-brand-gray-800',
              root: '!text-black !h-full',
              wrapper: '!h-full',
            }}
            rightSection={
              <FontAwesomeIcon
                icon={faChevronDown}
                className="text-[10px] text-brand-gray-800"
              />
            }
            popoverProps={{
              classNames: { dropdown: '!rounded-lg' },
            }}
            placeholder={schemaItem.header}
            clearable
            type="range"
            onChange={v => {
              // if (!v[1] && v[0]) return
              if (!v[1] && !v[0]) {
                schemaColumn.setFilterValue(undefined)
                return
              }
              schemaColumn.setFilterValue(() => [v[0], v[1]])
            }}
          />
        )
      case 'status':
        const sortedUniqueValues = Array.from(
          schemaColumn.getFacetedUniqueValues().keys()
        ).sort()
        if (sortedUniqueValues.length <= 1) return
        const filterValue = (schemaColumn.getFilterValue() as string[]) ?? []

        return (
          <div className="flex flex-wrap gap-1.5 text-sm font-medium">
            <div
              className={`${
                filterValue.length === 0
                  ? 'bg-brand-gray-800 text-white'
                  : 'bg-brand-gray-100'
              } h-[40px] px-4 rounded-full cursor-pointer flex items-center justify-center`}
              onClick={e => {
                schemaColumn.setFilterValue((oldValue: string[]) => [])
              }}
            >
              All
            </div>
            {sortedUniqueValues.map(uniqueValue => {
              return (
                <div
                  key={uniqueValue}
                  className={`${
                    filterValue.includes(uniqueValue) ||
                    filterValue.length === 0
                      ? 'bg-brand-gray-100 text-brand-gray-800'
                      : 'bg-brand-gray-800 text-white'
                  }  h-[40px] px-4 rounded-full cursor-pointer flex items-center justify-center`}
                  onClick={e => {
                    schemaColumn.setFilterValue((oldValue: string[]) => {
                      if ((oldValue ?? []).length === 0)
                        return sortedUniqueValues.filter(v => v !== uniqueValue)
                      if (oldValue.includes(uniqueValue))
                        return oldValue.filter(v => v !== uniqueValue)
                      return Array.from(
                        new Set([...(oldValue ?? []), uniqueValue])
                      )
                    })
                  }}
                >
                  {uniqueValue}
                </div>
              )
            })}
          </div>
        )
      default:
        break
    }
  }

  const currentSortedHeader = table
    .getHeaderGroups()
    .find(item => item.headers.find(item2 => item2.column.getIsSorted))
    ?.headers.find(item => item.column.getIsSorted())

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="overflow-y-auto">
        {schema
          .filter(
            item => item.type !== 'string' && columnVisibility[item.accessorKey]
          )
          .map(item => {
            const schemaColumn = table
              .getAllColumns()
              .find(column => column.id === item.accessorKey)

            if (!schemaColumn) return

            return (
              <div
                key={item.accessorKey}
                className="border-b border-solid border-brand-gray-200 flex flex-col gap-5 p-3 pb-6 mb-4"
              >
                <p className="text-lg font-semi">{item.header}</p>
                <div>{getContent(item, schemaColumn)}</div>
              </div>
            )
          })}
        <div className="flex flex-col gap-5 p-3 pb-6 mb-4">
          <p className="text-lg font-semi">Sort by</p>
          <Menu
            shadow="md"
            width={300}
            position="bottom-start"
            closeOnItemClick={false}
          >
            <Menu.Target>
              <button className="bg-brand-gray-100 border border-solid border-brand-gray-100 h-[40px] rounded-lg text-sm font-medium text-brand-gray-800 flex justify-between items-center px-3">
                {currentSortedHeader?.column.getIsSorted()
                  ? (currentSortedHeader.column.columnDef.header?.toString() ??
                      '') +
                    ' - ' +
                    currentSortedHeader.column.getIsSorted() +
                    'ending'
                  : 'None'}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="text-[10px] text-brand-gray-800"
                />
              </button>
            </Menu.Target>
            <Menu.Dropdown classNames={{ dropdown: '!rounded-lg' }}>
              {table.getHeaderGroups()[0].headers.map(item => (
                <Menu.Item
                  className="!py-2"
                  onClick={() => {
                    item.column.toggleSorting()
                  }}
                >
                  <div className="flex justify-between items-center text-xs text-brand-gray-800 font-medium">
                    {item.column.columnDef.header?.toString()}
                    {item.column.getIsSorted() && (
                      <div className="flex flex-col">
                        <FontAwesomeIcon
                          icon={faChevronUp}
                          className={`text-[10px] -mb-[5px] ${
                            item.column.getIsSorted() === 'desc'
                              ? 'text-brand-gray-200'
                              : 'text-brand-gray-800'
                          }  `}
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={`text-[10px] ${
                            item.column.getIsSorted() === 'asc'
                              ? 'text-brand-gray-200'
                              : 'text-brand-gray-800'
                          }`}
                        />
                      </div>
                    )}
                  </div>
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>

      <div className="h-[100px] bg-brand-gray-50 border-t border-solid border-brand-gray-200 flex items-center justify-between px-4">
        {table.getAllColumns().some(item => item.getIsFiltered()) ||
        table.getState().globalFilter ? (
          <button
            className="flex gap-2 items-center border-brand-gray-200 border border-solid transition-all rounded-lg px-3 h-[42px] font-medium text-brand-gray-800"
            onClick={() => {
              table.resetColumnFilters()
              table.resetGlobalFilter()
            }}
          >
            Clear filters
          </button>
        ) : (
          <div></div>
        )}
        <button
          className="flex gap-2 items-center bg-brand-blue-500 text-white transition-all rounded-lg px-3 h-[42px] font-medium"
          onClick={onClose}
        >
          Show {table.getFilteredRowModel().rows.length} results
        </button>
      </div>
    </div>
  )
}

function NumberFilterInputs({
  filterValueNumbers,
  schemaColumn,
  popover,
}: {
  filterValueNumbers: [number, number]
  schemaColumn: Column<any, unknown>
  popover?: boolean
}) {
  return (
    <div className="flex gap-3 items-center w-full">
      <div className="w-full">
        <p className="text-xs text-brand-gray-500">Minimum</p>
        <div className="w-full h-[40px] mt-1">
          <DebouncedInput
            value={filterValueNumbers[0]}
            containerClassName={`base-button ${
              popover
                ? 'border-brand-gray-300'
                : '!bg-brand-gray-100 border-brand-gray-100'
            }`}
            className={`rounded-lg text-md font-medium text-brand-gray-800 w-full ${
              popover
                ? 'border border-solid border-brand-gray-300'
                : 'bg-brand-gray-100 text-base'
            }`}
            type="number"
            placeholder="0"
            onChange={v => {
              if (!v && !filterValueNumbers[1]) {
                schemaColumn.setFilterValue(undefined)
                return
              }
              schemaColumn.setFilterValue((old: [number, number]) => [
                v,
                old?.[1],
              ])
            }}
          />
        </div>
      </div>
      <div className="w-[14px] h-[1px] bg-brand-gray-300 mt-5" />
      <div className="w-full">
        <p className="text-xs text-brand-gray-500">Maximum</p>
        <div className="w-full h-[40px] mt-1">
          <DebouncedInput
            value={filterValueNumbers[1]}
            containerClassName={`base-button ${
              popover
                ? 'border-brand-gray-300'
                : '!bg-brand-gray-100 border-brand-gray-100'
            }`}
            className={`rounded-lg text-md font-medium text-brand-gray-800 w-full ${
              popover
                ? 'border border-solid border-brand-gray-300'
                : 'bg-brand-gray-100 text-base'
            }`}
            type="number"
            placeholder="0"
            onChange={v => {
              if (!v && !filterValueNumbers[0]) {
                schemaColumn.setFilterValue(undefined)
                return
              }
              schemaColumn.setFilterValue((old: [number, number]) => [
                old?.[0],
                v,
              ])
            }}
          />
        </div>
      </div>
    </div>
  )
}

function MenuButton<T>({
  original,
  menuActions,
  dark,
}: {
  original: T | T[]
  menuActions: RowMenuAction<T>[] | TableMenuAction<T>[]
  dark?: boolean
}) {
  return (
    <Menu shadow="md" width={200} position="bottom-end">
      <Menu.Target>
        <button
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
          className={`base-button my-auto ${
            dark
              ? 'hover:!bg-brand-gray-100 active:!bg-brand-gray-200 !bg-brand-gray-50'
              : ''
          } min-w-[34px] w-[34px] h-[34px] rounded-md`}
        >
          <TableIcon
            icon="ellipsis"
            className="text-brand-gray-700 w-[22px] m-auto"
          />
        </button>
      </Menu.Target>
      <Menu.Dropdown classNames={{ dropdown: '!rounded-lg' }}>
        {(menuActions as any)
          .filter(
            (action: RowMenuAction<T> | TableMenuAction<T>) =>
              !action.hidden(original as any)
          )
          .map((action: RowMenuAction<T> | TableMenuAction<T>) => {
            return (
              <Menu.Item
                key={action.title}
                className="!py-2"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation()
                  action.onClick(original as any)
                }}
              >
                {action.title}
              </Menu.Item>
            )
          })}
      </Menu.Dropdown>
    </Menu>
  )
}

function SelectColumnMenu<T>({
  forDrawer,
  table,
  schema,
  tableType,
  maxVisibleColumns,
  enabledColumns,
}: {
  forDrawer?: boolean
  table: Table<any>
  schema: SchemaItem<T>[]
  tableType: TableType
  maxVisibleColumns: number
  enabledColumns: [string, boolean][]
}) {
  const statusOrder = {
    status: 1,
    date: 2,
    number: 3,
    string: 4,
  }

  return (
    <Menu
      shadow="md"
      width={280}
      position="bottom-start"
      closeOnItemClick={false}
    >
      <Menu.Target>
        <button
          className={`base-button flex gap-2 items-center ${
            forDrawer ? '!bg-brand-gray-100 h-[40px] !rounded-full' : 'h-full'
          } px-4 duration-300 text-brand-gray-800`}
        >
          {forDrawer && (
            <FontAwesomeIcon
              icon={faTableColumns}
              className="text-[15px] text-brand-gray-800"
            />
          )}
          <div className="text-brand-gray-800 font-medium text-sm">
            {forDrawer
              ? table.getAllColumns().filter(item => item.getIsVisible())
                  .length + ' columns selected'
              : 'Select columns'}
          </div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className="text-[10px] text-brand-gray-800"
          />
        </button>
      </Menu.Target>
      <Menu.Dropdown classNames={{ dropdown: '!rounded-lg' }}>
        {table
          .getAllColumns()
          .sort((a, b) => {
            const schemaColumnA = schema.find(item => item.accessorKey === a.id)
            const schemaColumnB = schema.find(item => item.accessorKey === b.id)
            if (!schemaColumnA || !schemaColumnB) return -1
            return (
              statusOrder[schemaColumnA.type] - statusOrder[schemaColumnB.type]
            )
          })
          .map(column => {
            const schemaColumn = schema.find(
              item => item.accessorKey === column.id
            )
            if (!schemaColumn) return
            const disabled =
              schemaColumn?.permanent ||
              (!column.getIsVisible() &&
                enabledColumns.length >=
                  (tableType === 'table' ? maxVisibleColumns : 5))
            const selected = column.getIsVisible()
            return (
              <Tooltip
                key={column.id}
                position="bottom"
                multiline
                maw={320}
                classNames={{ tooltip: '!bg-brand-gray-800' }}
                disabled={!schemaColumn.permanent && !disabled}
                transitionProps={{ transition: 'pop' }}
                label={
                  schemaColumn.permanent
                    ? "This column is locked and can't be replaced."
                    : disabled
                      ? `You have reached a maximum of ${maxVisibleColumns} columns, deselect one to show another.`
                      : ''
                }
              >
                <Menu.Item
                  className="!py-2"
                  classNames={{
                    item: disabled
                      ? 'hover:!bg-transparent !text-brand-gray-300'
                      : '',
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    if (disabled) return
                    column.toggleVisibility(!column.getIsVisible())
                    column.setFilterValue(() => undefined)
                  }}
                  rightSection={
                    selected ? (
                      <FontAwesomeIcon
                        icon={schemaColumn.permanent ? faLock : faCheck}
                        className={
                          disabled
                            ? 'text-brand-gray-300'
                            : 'text-brand-gray-800'
                        }
                      />
                    ) : undefined
                  }
                  leftSection={
                    <FontAwesomeIcon
                      icon={statusIcon(schemaColumn.type)}
                      className={
                        disabled ? 'text-brand-gray-300' : 'text-brand-gray-400'
                      }
                    />
                  }
                >
                  {schemaColumn?.header}
                </Menu.Item>
              </Tooltip>
            )
          })}
      </Menu.Dropdown>
    </Menu>
  )
}

function TableTypeButtons({
  tableType,
  setTableType,
}: {
  tableType: string
  setTableType: Dispatch<SetStateAction<TableType>>
}) {
  return (
    <div className="h-full">
      <button
        className={`base-button ${
          tableType === 'table' ? '!bg-brand-gray-50 !shadow-inner' : ''
        } p-2 w-[44px] !rounded-l-lg !rounded-r-none !border-y !border-l !border-r-0 h-full`}
        onClick={() => setTableType('table')}
      >
        <FontAwesomeIcon
          icon={faList}
          className={`${
            tableType === 'table'
              ? 'text-brand-gray-950'
              : 'text-brand-gray-500'
          }`}
        />
      </button>
      <button
        className={`base-button ${
          tableType === 'cards' ? '!bg-brand-gray-50 !shadow-inner' : ''
        } p-2 w-[44px] !rounded-r-lg !rounded-l-none h-full `}
        onClick={() => setTableType('cards')}
      >
        <FontAwesomeIcon
          icon={faTableCellsLarge}
          className={`${
            tableType === 'cards'
              ? 'text-brand-gray-950'
              : 'text-brand-gray-500'
          }`}
        />
      </button>
    </div>
  )
}

function Pagination({ table }: { table: Table<any> }) {
  return (
    <div className="flex flex-col items-center justify-center gap-5">
      <div className="flex bg-white rounded-lg">
        <button
          className={`border-y border-l border-solid border-brand-gray-300 h-[42px] w-[42px] rounded-l-lg transition-all ${
            !table.getCanPreviousPage()
              ? 'text-brand-gray-400'
              : 'hover:bg-brand-gray-50'
          }`}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>

        <button
          className={`h-[42px] w-[42px] hover:bg-brand-gray-50 transition-all ${
            table.getState().pagination.pageIndex === 0
              ? 'border-brand-gray-800 border-2 font-semi'
              : 'border border-solid border-brand-gray-300'
          }`}
          onClick={() => table.setPageIndex(0)}
        >
          1
        </button>
        {table.getState().pagination.pageIndex > 2 &&
          table.getPageCount() > 6 && (
            <div className="border-y border-r border-solid border-brand-gray-300 h-[42px] w-[42px] text-brand-gray-300 flex justify-center items-center">
              <FontAwesomeIcon icon={faEllipsis} />
            </div>
          )}

        {Array.from(Array(table.getPageCount()).keys())
          .slice(1, -1)
          .filter((item, index) => {
            const pIndex = table.getState().pagination.pageIndex
            const pCount = table.getPageCount()
            const difference = item - pIndex
            if (pIndex < 3 && item < 5) return true
            if (pIndex > pCount - 4 && item > pCount - 6) return true
            if (difference < 2 && difference > -2) return true
            return false
          })
          .map(item => (
            <button
              key={item}
              className={`h-[42px] w-[42px] hover:bg-brand-gray-50 transition-all ${
                table.getState().pagination.pageIndex === item
                  ? 'border-brand-gray-800 border-2 font-semi'
                  : table.getState().pagination.pageIndex === item + 1
                    ? 'border-y border-solid border-brand-gray-300'
                    : 'border-y border-r border-solid border-brand-gray-300'
              }`}
              onClick={() => table.setPageIndex(item)}
            >
              {item + 1}
            </button>
          ))}
        {table.getState().pagination.pageIndex < table.getPageCount() - 3 &&
          table.getPageCount() > 6 && (
            <div className="border-y border-r border-solid border-brand-gray-300 h-[42px] w-[42px] text-brand-gray-300 flex justify-center items-center">
              <FontAwesomeIcon icon={faEllipsis} />
            </div>
          )}
        {table.getPageCount() > 1 && (
          <button
            className={`h-[42px] w-[42px] hover:bg-brand-gray-50 transition-all ${
              table.getState().pagination.pageIndex === table.getPageCount() - 1
                ? 'border-brand-gray-800 border-2 font-semi'
                : 'border-y border-r border-solid border-brand-gray-300'
            }`}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          >
            {table.getPageCount()}
          </button>
        )}

        <button
          className={`border-y border-r border-solid border-brand-gray-300 h-[42px] w-[42px] rounded-r-lg transition-all ${
            !table.getCanNextPage()
              ? 'text-brand-gray-400'
              : 'hover:bg-brand-gray-50'
          }`}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
      <div className="text-brand-gray-400 text-sm">
        {table.getState().pagination.pageSize *
          table.getState().pagination.pageIndex +
          (table.getFilteredRowModel().rows.length === 0 ? 0 : 1)}
        -
        {table.getState().pagination.pageSize *
          (table.getState().pagination.pageIndex + 1) >
        table.getFilteredRowModel().rows.length
          ? table.getFilteredRowModel().rows.length
          : table.getState().pagination.pageSize *
            (table.getState().pagination.pageIndex + 1)}{' '}
        out of {table.getFilteredRowModel().rows.length} results
      </div>
    </div>
  )
}

function CheckBox({
  isDisabled,
  isSelected,
  isLoading,
  onClick,
}: {
  isSelected: boolean
  isDisabled?: boolean
  isLoading?: boolean
  onClick?: () => void
}) {
  return (
    <div className="flex items-center min-w-[40px]">
      {(!isDisabled || (isLoading && isSelected)) && (
        <>
          <div
            className={`transition-all w-[20px] h-[20px] my-auto rounded flex justify-center items-center relative ${
              isSelected
                ? 'bg-brand-gray-800 border border-solid border-brand-gray-800'
                : 'border border-solid border-brand-gray-200 group-hover:border-brand-gray-300 hover:bg-brand-gray-50 bg-white z-20'
            } ${
              isLoading ? 'opacity-75 cursor-not-allowed' : 'cursor-pointer'
            }`}
          >
            {isSelected && (
              <FontAwesomeIcon
                icon={faCheck}
                className="text-white text-[12px]"
              />
            )}
            <div
              onClick={e => {
                e.preventDefault()
                if (isLoading) return
                if (onClick) onClick()
              }}
              className="absolute w-[35px] h-[35px] -right-1/2 z-10"
            />
          </div>
        </>
      )}
    </div>
  )
}

function SelectMenu<T>({
  table,
  selectMenuActions,
  setIsLoading,
  isLoading,
  setHoveredMenuAction,
}: {
  table: Table<T>
  selectMenuActions: SelectMenuAction<T>[]
  setIsLoading: Dispatch<SetStateAction<boolean>>
  isLoading: boolean
  setHoveredMenuAction: Dispatch<SetStateAction<string>>
}) {
  const isError = useRef(false)
  const onClickWrapper = async (
    onClick: (data: Row<T>[]) => Promise<Row<T>[]>,
    data: Row<T>[]
  ) => {
    isError.current = false
    setIsLoadingState(true)
    setIsLoadingState2(true)
    setIsLoading(true)
    await new Promise(resolve => setTimeout(resolve, 1000))
    let onClickData: Row<T>[] = []
    try {
      onClickData = await onClick(data)
    } catch (error) {
      isError.current = true
    }
    setIsLoading(false)
    return onClickData
  }

  const [isLoadingState, setIsLoadingState] = useState(false)
  const [isLoadingState2, setIsLoadingState2] = useState(false)
  const [checkShouldAnimate, setCheckShouldAnimate] = useState(false)
  const [errorShouldAnimate, setErrorShouldAnimate] = useState(false)

  useEffect(() => {
    if (isLoadingState && !isLoading) {
      if (isError.current) {
        setErrorShouldAnimate(true)
      } else {
        setCheckShouldAnimate(true)
      }
      setTimeout(() => {
        setIsLoadingState(false)
      }, 800)
    }
  }, [isLoading])

  useEffect(() => {
    if (!isLoadingState && !isLoading) {
      setTimeout(() => {
        setIsLoadingState2(false)
        setCheckShouldAnimate(false)
        setErrorShouldAnimate(false)
      }, 400)
    }
  }, [isLoadingState])

  const isVisible =
    table.getIsSomeRowsSelected() ||
    table.getIsAllRowsSelected() ||
    isLoadingState

  return (
    <div
      className={`overflow-hidden transition-all duration-500 absolute h-[48px] bg-brand-gray-900 bottom-10 -translate-x-1/2 left-1/2 shadow-xl rounded-full flex text-sm font-medium divide-x divide-brand-gray-600 z-50 ${
        isVisible ? 'translate-y-0' : 'translate-y-40'
      }
      ${checkShouldAnimate ? 'bg-brand-green-500' : ''}
      ${errorShouldAnimate ? 'bg-brand-red-500' : ''}
      `}
    >
      <AnimateWidth isVisible={!!isLoadingState2}>
        <div className="relative flex justify-center items-center w-[48px] h-full">
          {isLoading ? (
            <Spinner size={'md'} thickness="3px" className="text-white" />
          ) : (
            <>
              <div
                className={`transition-all duration-1000 absolute bg-brand-gray-900 w-full h-full left-0 bottom-0 ${
                  checkShouldAnimate || errorShouldAnimate
                    ? 'translate-x-20'
                    : 'translate-x-[15px]'
                }`}
              />
              {checkShouldAnimate && (
                <TableIcon icon="check" className="text-white" />
              )}
              {errorShouldAnimate && (
                <TableIcon icon="error" className="text-white" />
              )}
            </>
          )}
        </div>
      </AnimateWidth>
      <AnimateWidth isVisible={!!!isLoadingState2}>
        <div className="text-brand-gray-50 flex justify-center items-center px-5 border-solid truncate h-full">
          <div
            onClick={() => table.toggleAllRowsSelected(false)}
            className="transition-all flex items-center hover:bg-brand-gray-800 active:bg-brand-gray-900 cursor-pointer select-none -mx-3 px-2 py-1.5 rounded-full"
          >
            <TableIcon icon="cancel" className="w-[18px] text-brand-gray-400" />
          </div>
        </div>
      </AnimateWidth>
      <AnimateWidth isVisible={!!!isLoadingState2}>
        <div className="text-brand-gray-400 flex justify-center items-center px-5 border-solid h-full">
          <div className="min-w-[20px] text-center">
            {table.getSelectedRowModel().rows.length}
          </div>
          <div>selected</div>
        </div>
      </AnimateWidth>
      {selectMenuActions.map(action => {
        const selectedRowsOriginals = table
          .getSelectedRowModel()
          .rows.map(row => row.original)

        // if (action.hidden(selectedRowsOriginals)) return null

        const isDisabled = action.selected(selectedRowsOriginals).length === 0

        return (
          <AnimateWidth
            key={action.title}
            isVisible={
              !isLoadingState2 && !action.hidden(selectedRowsOriginals)
            }
          >
            <div className="text-brand-gray-50 flex justify-center items-center px-5 border-solid h-full truncate">
              <div
                onMouseEnter={() => setHoveredMenuAction(action.title)}
                onMouseLeave={() => setHoveredMenuAction('')}
                onClick={async () => {
                  if (isDisabled) return
                  const affectedRows = await onClickWrapper(
                    action.onClick,
                    table.getSelectedRowModel().rows
                  )
                  affectedRows.forEach(row => {
                    if (row.getCanSelect() && row.getIsSelected())
                      row.toggleSelected(false)
                  })
                }}
                className={`transition-all flex gap-2 items-center hover:bg-brand-gray-800 active:bg-brand-gray-900
                 select-none -mx-3 px-3 py-1.5 rounded-full ${
                   isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'
                 }`}
              >
                <TableIcon
                  icon={action.icon}
                  className="w-[18px] text-brand-gray-400"
                />
                <div className="flex">
                  <div>{action.title}&nbsp;</div>
                  <div className="text-gray-400 w-[25px] text-center">
                    ({action.selected(selectedRowsOriginals).length})
                  </div>
                </div>
              </div>
            </div>
          </AnimateWidth>
        )
      })}
    </div>
  )
}

function RowButtons<T>({
  buttons,
  row,
}: {
  buttons: RowButton<T>[]
  row: Row<T>
}) {
  return (
    <div className="flex gap-1.5 min-w-[126px] justify-end">
      {buttons
        .filter(button => !button.hidden(row.original))
        .map((button, index) => {
          return (
            <Tooltip
              key={index}
              position="bottom-end"
              label={button.tooltip}
              disabled={!button.tooltip}
              transitionProps={{
                transition: 'pop',
                duration: 300,
              }}
            >
              <Link
                to={button.navigation ? button.navigation(row.original) : '#'}
                className={`base-button my-auto h-[34px] min-w-[34px] flex justify-center items-center gap-1.5 px-2 ${
                  button.bgColor === 'yellow'
                    ? '!bg-brand-yellow-100 !border-brand-yellow-300 !text-brand-yellow-900 hover:!bg-brand-yellow-150'
                    : ''
                }`}
                onClick={e => {
                  e.stopPropagation()
                  if (button.navigation) return
                  button.onClick(row.original)
                }}
              >
                <TableIcon
                  icon={button.icon}
                  className="w-[15px] min-w-[15px]"
                />
                {button.title && <p className="truncate">{button.title}</p>}
              </Link>
            </Tooltip>
          )
        })}
    </div>
  )
}
