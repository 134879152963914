import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import type React from 'react'

interface PopOverProps {
  children: React.ReactNode
  renderTrigger: React.ReactNode
}

const PopOver: React.FC<PopOverProps> = ({ children, renderTrigger }) => {
  const isDarkMode =
    document.body.getAttribute('theme') === 'dark' &&
    localStorage.getItem('color-theme') === 'dark'

  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>{renderTrigger}</PopoverTrigger>

      <PopoverContent
        outline="0"
        boxShadow={'none !important'}
        background={isDarkMode ? '#111E32' : '#fff'}
        p="16px"
        zIndex={9999}
        rounded="10px"
        w="270px"
        border={`1px solid ${isDarkMode ? '#253550' : '#EAEDF3'} !important`}
      >
        <PopoverArrow
          shadow="none !important"
          backgroundColor={isDarkMode ? '#111E32' : '#fff'}
          border={`1px solid ${isDarkMode ? '#253550' : '#EAEDF3'} !important`}
        />
        {children}
      </PopoverContent>
    </Popover>
  )
}

export default PopOver
