import {
  faEye,
  faEyeSlash,
  faQuestionCircle,
} from '@fortawesome/free-regular-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu } from '@mantine/core'
import type React from 'react'
import { type HTMLProps, useState } from 'react'
import {
  type FieldError,
  type UseFormRegisterReturn,
  UseFormSetValue,
} from 'react-hook-form'
import PlacesAutocomplete from 'react-places-autocomplete'
import { useGetCountriesQuery } from '../redux/services/coreApi'
import Tooltip from './Tooltip'
interface FormInputProps {
  label?: string
  placeholder?: string
  error?: FieldError
  registeredForm: UseFormRegisterReturn
  inputMode: 'tel' | 'email' | 'text' | 'numeric' | 'address'
  isSecure?: boolean
  disabled?: boolean
  onPressForgotPassword?: () => void
  showPassword?: boolean
  textarea?: boolean
  largeTitle?: boolean
  description?: string
  tooltip?: string
  className?: HTMLProps<HTMLElement>['className']
  cardStyling?: boolean
  setFormValue?: UseFormSetValue<any>
  addressCountry?: string
  onCountrySelect?: (countryIsoCode: string) => void
}

const FormInput: React.FC<FormInputProps> = ({
  label,
  placeholder,
  error,
  registeredForm,
  inputMode,
  isSecure,
  disabled,
  onPressForgotPassword,
  showPassword,
  textarea,
  largeTitle,
  description,
  tooltip,
  className,
  cardStyling,
  setFormValue,
  addressCountry,
  onCountrySelect,
}) => {
  const [isShowingPassword, setIsShowingPassword] = useState(false)

  return (
    <div className={`space-y-1 ${className}`}>
      <div className="flex items-center justify-between">
        {label && (
          <label
            htmlFor={label}
            className={`flex items-center ${
              largeTitle
                ? 'font-semi text-base mb-3  text-brand-accent dark:text-white'
                : 'text-description'
            }`}
          >
            <div>{label}</div>

            {tooltip && (
              <Tooltip label={tooltip}>
                <FontAwesomeIcon icon={faQuestionCircle} className="ml-1" />
              </Tooltip>
            )}
          </label>
        )}
        {description && (
          <label htmlFor={label} className="text-description">
            {description}
          </label>
        )}

        {onPressForgotPassword && (
          <div className="flex items-center font-medium gap-1 text-xs text-brand-dark-text-description">
            Forgot password?
            <button
              onClick={onPressForgotPassword}
              className="font-semi text-brand-accent hover:underline"
            >
              Reset
            </button>
          </div>
        )}
        {showPassword && (
          <div
            onClick={() => setIsShowingPassword(v => !v)}
            className="text-description-subtle hover:text-brand-light-text-description dark:hover:text-brand-accent select-none cursor-pointer"
          >
            {isShowingPassword ? (
              <span className="">
                {' '}
                <FontAwesomeIcon icon={faEyeSlash} /> Hide
              </span>
            ) : (
              <span>
                {' '}
                <FontAwesomeIcon icon={faEye} /> Show
              </span>
            )}
          </div>
        )}
      </div>

      {inputMode === 'address' ? (
        <AddressForm
          registeredForm={registeredForm}
          setFormValue={setFormValue}
          addressCountry={addressCountry}
          onCountrySelect={onCountrySelect}
        />
      ) : textarea ? (
        <textarea
          id={label}
          inputMode={inputMode}
          className={`input min-h-[100px] max-h-[300px] transition-none pt-2`}
          placeholder={placeholder}
          aria-disabled={disabled}
          disabled={disabled}
          {...registeredForm}
        />
      ) : (
        <input
          id={label}
          inputMode={inputMode}
          className={cardStyling ? 'input-card' : 'input'}
          placeholder={placeholder}
          aria-disabled={disabled}
          disabled={disabled}
          type={isSecure && !isShowingPassword ? 'password' : undefined}
          {...registeredForm}
        />
      )}

      {error && (
        <div className="flex items-center gap-1 text-warning">
          <span className="">{error.message}</span>
        </div>
      )}
    </div>
  )
}

interface AddressFormProps {
  registeredForm: UseFormRegisterReturn
  setFormValue?: UseFormSetValue<any>
  label?: string
  cardStyling?: boolean
  placeholder?: string
  disabled?: boolean
  addressCountry?: string
  onCountrySelect?: (countryIsoCode: string) => void
}

const AddressForm: React.FC<AddressFormProps> = ({
  registeredForm,
  setFormValue,
  label,
  cardStyling,
  placeholder,
  disabled,
  addressCountry,
  onCountrySelect,
}) => {
  const { data: countries } = useGetCountriesQuery()

  if (!setFormValue) {
    // biome-ignore lint/suspicious/noConsole: <explanation>
    console.error(
      'AddressForm component requires the setFormValue prop to be passed. FormInput will not work'
    )
    return null
  }
  const [address, setAddress] = useState('')

  // Update both state and RHF's value
  const handleAddressChange = (value: any) => {
    setAddress(value)
    setFormValue(registeredForm.name, value) // Set the form's value for the 'address' field
  }

  const handleAddressSelect = async (address: string, placeId: string) => {
    setAddress(address)
    setFormValue(registeredForm.name, address) // Update RHF's value

    if (placeId) {
      const service = new google.maps.places.PlacesService(
        document.createElement('div') // Placeholder div required by the API
      )

      service.getDetails({ placeId }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && place) {
          const countryComponent = place?.address_components?.find(component =>
            component.types.includes('country')
          )

          if (countryComponent) {
            const countryInSupportedCountries = countries?.find(
              x => x.isoCode === countryComponent?.short_name
            )
            if (onCountrySelect && countryInSupportedCountries) {
              onCountrySelect(countryInSupportedCountries.name) // Trigger the provided function with ISO code
            }
          }
        }
      })
    }
  }

  const addressCountryIsoCode = countries?.find(
    x => x.name === addressCountry
  )?.isoCode

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleAddressChange}
      onSelect={handleAddressSelect}
      searchOptions={{
        types: ['address'],
        ...(addressCountryIsoCode
          ? { componentRestrictions: { country: addressCountryIsoCode } }
          : {}),
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <Menu
            shadow="md"
            position="bottom-start"
            closeOnItemClick={false}
            opened={suggestions.length > 0}
            trapFocus={false}
            zIndex={9999} // support showing in modals
          >
            <Menu.Target>
              <div className="relative">
                <input
                  id={label}
                  inputMode="text"
                  aria-disabled={disabled}
                  {...getInputProps({
                    className: cardStyling ? 'input-card' : 'input',
                    placeholder,
                    disabled,
                    autoComplete: 'off',
                  })}
                />
                {loading && (
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-brand-accent">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="animate-spin"
                    />
                  </div>
                )}
              </div>
            </Menu.Target>
            <div className={`flex flex-wrap gap-1.5 text-sm font-medium`}>
              <Menu.Dropdown classNames={{ dropdown: '!rounded-lg' }}>
                {suggestions.map((suggestion, index) => {
                  const { key, ...restSuggestionProps } =
                    getSuggestionItemProps(suggestion, {
                      className: '!py-2',
                    })

                  return (
                    <Menu.Item
                      key={key || index} // Use 'key' from suggestionProps or fallback to index
                      {...restSuggestionProps} // Spread other props
                    >
                      {suggestion.description}
                    </Menu.Item>
                  )
                })}
              </Menu.Dropdown>
            </div>
          </Menu>
        </div>
      )}
    </PlacesAutocomplete>
  )
}

export default FormInput
