import type React from 'react'
import type { WalletDto } from '../../../../../../types/coreApi-types'

interface WalletsProps {
  wallets?: WalletDto[]
}

const Wallets: React.FC<WalletsProps> = ({ wallets }) => {
  return (
    <div>
      <h1 className="h1">Wallets</h1>
      {wallets && wallets.length === 0 && (
        <div className="flex justify-center text-description">No wallets</div>
      )}
      {wallets &&
        wallets?.map((item, i) => {
          return (
            <div key={i} className="card">
              <div className="flex items-center gap-5">
                <img src={item.network.iconUrl} className="w-8 h-8" alt="" />
                <h2 className="h2">{item.address}</h2>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default Wallets
