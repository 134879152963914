import { useToast } from '@chakra-ui/react'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DatePickerInput } from '@mantine/dates'
import moment from 'moment'
import type React from 'react'
import { useEffect, useState } from 'react'
import DefaultModal from '../../../../../../components/DefaultModal'
import DropdownCell from '../../../../../../components/DropdownCell'
import DropdownMenu from '../../../../../../components/DropdownMenu'
import FormButton from '../../../../../../components/FormButton'
import Icon from '../../../../../../components/Icon'
import {
  useCreateFundDepositMutation,
  useDeleteFundDepositMutation,
  useGetCurrenciesQuery,
  useGetNetworksQuery,
  useUpdateFundDepositMutation,
} from '../../../../../../redux/services/coreApi'
import type {
  CurrencyDto,
  DepositDto,
  NetworkDto,
  TransferStatus,
} from '../../../../../../types/coreApi-types'

interface AddFundDepositModalProps {
  isOpen: boolean
  onClose: () => void
  userId: string
  deposit: DepositDto | null
}

const AddFundDepositModal: React.FC<AddFundDepositModalProps> = ({
  isOpen,
  onClose,
  userId,
  deposit,
}) => {
  const toast = useToast()
  const [amountIn, setAmountIn] = useState<string>('')
  const [currencyToEurRate, setCurrencyToEurRate] = useState<string>('')
  const [selectedNetwork, setSelectedNetwork] = useState<NetworkDto | null>(
    null
  )
  const [selectedCurrency, setSelectedCurrency] = useState<CurrencyDto | null>(
    null
  )

  const [deleteFundDeposit, { isLoading: isDeleting }] =
    useDeleteFundDepositMutation()
  const [createFundDeposit, { isLoading }] = useCreateFundDepositMutation()
  const [updateFundDeposit, { isLoading: isUpdating }] =
    useUpdateFundDepositMutation()

  const { data: networksData } = useGetNetworksQuery(null)
  const { data: currencyData } = useGetCurrenciesQuery()

  useEffect(() => {
    if (!isOpen) return

    if (deposit) {
      setAmountIn(`${deposit.amount}`)
      setCurrencyToEurRate(deposit.currencyToEurRate.toString())
      setDate(moment(deposit.transferredAt).toDate())
      setStatus(deposit.transferStatus)
      //if (deposit.currency) setSelectedCurrency(deposit.currency)
      //if (deposit.wallet) setSelectedNetwork(deposit.wallet.network)
    } else {
      setAmountIn('')
      setCurrencyToEurRate('')
      setSelectedCurrency(null)
      setSelectedNetwork(null)
      setDate(new Date())
      setStatus(null)
    }
  }, [isOpen, deposit])

  const networkRequired = selectedCurrency?.currencyType === 'CryptoCurrency'

  const [date, setDate] = useState<Date>(new Date())
  const [status, setStatus] = useState<TransferStatus | null>(null)

  const statuses: TransferStatus[] = [
    'Processing',
    'Pending',
    'Completed',
    'Cancelled',
  ]

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={false}
      title={`${deposit ? 'Edit' : 'Add'} fund deposit`}
    >
      <div className="p-5 pt-0 flex flex-col gap-5">
        <div>
          <p className="mb-1 text-description">Date</p>
          <DatePickerInput
            valueFormat="DD MMM YYYY"
            value={date}
            classNames={{
              input:
                '!bg-white !border !border-solid !border-white !h-full !rounded-lg !text-sm hover:!bg-brand-gray-100 !transition-all !duration-300 !text-brand-gray-800 !font-medium',
              root: '!text-black !h-full',
              wrapper: '!h-full',
            }}
            popoverProps={{
              classNames: {
                dropdown: '!rounded-lg !z-[99999] !max-w-auto !w-[300px]',
              },
            }}
            placeholder={'Select date'}
            type="default"
            onChange={v => {
              setDate(moment(v).toDate())
            }}
          />
        </div>
        <div>
          <p className="mb-1 text-description">Amount invested</p>
          <input
            type="text"
            className="input-card"
            value={amountIn}
            onChange={e => setAmountIn(e.target.value)}
          />
        </div>
        {selectedCurrency && selectedCurrency.isoCode !== 'EUR' && (
          <div>
            <p className="mb-1 text-description">Currency to EUR rate</p>
            <input
              type="text"
              className="input-card"
              value={currencyToEurRate}
              onChange={e => setCurrencyToEurRate(e.target.value)}
            />
          </div>
        )}
        {!deposit && (
          <div className="flex flex-col">
            <p className="text-description mb-1">Currency</p>
            <DropdownMenu
              maxH="310px"
              renderList={currencyData?.map(c => {
                return (
                  <DropdownCell
                    key={c.id}
                    title={c.name}
                    subtitle={c.isoCode}
                    onClick={() => {
                      setSelectedNetwork(null)
                      setSelectedCurrency(c)
                    }}
                    isActive={c === selectedCurrency}
                    imageName={c}
                  />
                )
              })}
            >
              <div className="input-card text-start flex items-center pl-3 gap-2">
                <Icon src={selectedCurrency} size="base" />

                {selectedCurrency?.name}
                {!selectedCurrency && (
                  <p className="text-description">Select a currency</p>
                )}

                <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 text-brand-light-text-description">
                  <FontAwesomeIcon icon={faCaretDown} size="sm" />
                </div>
              </div>
            </DropdownMenu>
          </div>
        )}

        {!deposit && networkRequired && (
          <div className="flex flex-col">
            <p className="text-description mb-1">Network</p>
            <DropdownMenu
              maxH="310px"
              renderList={networksData
                ?.filter(n =>
                  n.cryptoCurrencies.find(
                    c => c.isoCode === selectedCurrency?.isoCode
                  )
                )
                .map(network => {
                  return (
                    <DropdownCell
                      key={network.id}
                      title={network.name}
                      subtitle={network.nativeCurrency?.isoCode}
                      onClick={() => setSelectedNetwork(network)}
                      isActive={network === selectedNetwork}
                      imageName={network}
                    />
                  )
                })}
            >
              <div className="input-card text-start flex items-center pl-3 gap-2">
                <Icon src={selectedNetwork} size="base" />

                {selectedNetwork?.name}
                {!selectedNetwork && (
                  <p className="text-description">Select a network</p>
                )}

                <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 text-brand-light-text-description">
                  <FontAwesomeIcon icon={faCaretDown} size="sm" />
                </div>
              </div>
            </DropdownMenu>
          </div>
        )}

        {deposit && (
          <div className="flex flex-col">
            <p className="text-description mb-1">Status</p>
            <DropdownMenu
              maxH="310px"
              renderList={statuses.map(s => {
                return (
                  <DropdownCell
                    key={s}
                    title={s}
                    onClick={() => setStatus(s)}
                    isActive={status === s}
                  />
                )
              })}
            >
              <div className="input-card text-start flex items-center pl-3 gap-2">
                {status}
                {!status && <p className="text-description">Select status</p>}

                <div className="absolute right-0 top-0 bottom-0 flex items-center mr-3 text-brand-light-text-description">
                  <FontAwesomeIcon icon={faCaretDown} size="sm" />
                </div>
              </div>
            </DropdownMenu>
          </div>
        )}

        <div className="flex gap-2">
          {deposit && deposit.transferStatus !== 'Cancelled' && (
            <div className="w-[200px]">
              <FormButton
                disabled={isLoading || isDeleting || isUpdating}
                isLoading={isDeleting}
                title="Cancel deposit"
                danger
                onClick={async () => {
                  await deleteFundDeposit(deposit.id).unwrap()
                  toast({
                    title: 'Fund deposit successfully cancelled',
                    status: 'success',
                  })
                  onClose()
                }}
              />
            </div>
          )}

          <FormButton
            title={deposit ? 'Save' : 'Add'}
            isLoading={isLoading || isUpdating}
            disabled={
              isLoading ||
              isUpdating ||
              (!!selectedCurrency &&
                selectedCurrency.isoCode !== 'EUR' &&
                !currencyToEurRate)
            }
            onClick={async () => {
              if (deposit) {
                if (!amountIn || !date || !status) {
                  toast({
                    title: 'Please fill in all the fields',
                    status: 'warning',
                  })
                  return
                }

                await updateFundDeposit({
                  depositId: deposit.id,
                  amount: Number.parseFloat(amountIn),
                  transferredAt: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
                  transferStatus: status,
                  currencyToEurRate: Number.parseFloat(currencyToEurRate),
                }).unwrap()
                toast({
                  title: 'Fund deposit successfully updated',
                  status: 'success',
                })
              } else {
                if (
                  !amountIn ||
                  !selectedCurrency ||
                  !userId ||
                  (networkRequired ? !selectedNetwork : false)
                ) {
                  toast({
                    title: 'Please fill in all the fields',
                    status: 'warning',
                  })
                  return
                }

                await createFundDeposit({
                  amountIn: Number.parseFloat(amountIn),
                  investorId: userId,
                  currencyInId: selectedCurrency.id,
                  currencyInNetworkId: selectedNetwork?.id,
                  transferredAt: moment(date).format('YYYY-MM-DDTHH:mm:ss'),
                  currencyToEurRate: Number.parseFloat(currencyToEurRate),
                }).unwrap()
                toast({
                  title: 'Fund deposit successfully created',
                  status: 'success',
                })
              }

              onClose()
            }}
          />
        </div>
      </div>
    </DefaultModal>
  )
}

export default AddFundDepositModal
