import { faUserSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../../../components/Pagewrapper'
import type { RootState } from '../../../../../redux/store'
type CancelledProps = {}

const Cancelled: React.FC<CancelledProps> = ({}) => {
  const session = useSelector((state: RootState) => state.session)

  return (
    <PageWrapper fixedWidth>
      <div className="card gap-2 mt-20 flex flex-col text-center items-center">
        <FontAwesomeIcon
          icon={faUserSlash}
          className="h-9 w-9 mt-5 text-brand-light-red"
        />
        <h1 className="font-semi text-lg">
          Something went wrong with your{' '}
          {session.role === 'Organization' ? 'KYB' : 'KYC'}
        </h1>
        <span className="text-description w-2/3 mb-5 mt-5">
          Something went wrong with your KYB process and we have cancelled it.
          Please contact us on{' '}
          <a
            href="mailto:m@penning.dk"
            className=" hover:underline text-brand-blue"
          >
            m@penning.dk
          </a>{' '}
          or call us on{' '}
          <a href="tel:+4530220033" className="hover:underline text-brand-blue">
            +45 30 22 00 33
          </a>{' '}
          .
        </span>
      </div>
    </PageWrapper>
  )
}

export default Cancelled
