import { useAuth0 } from '@auth0/auth0-react'
import type React from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import symbol from '../../assets/penning.png'
import img from '../../assets/welcome-image.png'
import Footer from '../../components/Footer'

type LoginScreenProps = {}

const LoginScreen: React.FC<LoginScreenProps> = () => {
  const history = useHistory()
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/app')
    }
  }, [isAuthenticated])

  return (
    <>
      <div className="bg-brand-dark-card flex flex-col md:flex-row min-h-screen text-white font-normal pt-5 relative md:items-center">
        <div className="m-5 space-y-10 md:max-w-lg md:min-w-[400px] shrink-0 md:mx-20 xl:mx-52 transition-all">
          <div className="space-y-4">
            <div className="bg-brand-dark-background w-12 h-12 rounded-lg flex items-center overflow-hidden justify-center">
              <img src={symbol} alt="Penning symbol object-contain w-2 " />
            </div>
            <div>
              <p className="text-base font-light">Welcome to</p>
              <p className="text-3xl">Penning Client Dashboard</p>
            </div>
          </div>

          <div className="flex flex-col gap-2 text-base ">
            <button
              className="bg-white text-brand-accent py-3 rounded-md"
              onClick={() => history.push('/sign-up')}
            >
              Get started
            </button>
            <button
              className="bg-brand-accent text-white py-3 rounded-md"
              onClick={() => loginWithRedirect()}
            >
              Log In
            </button>
          </div>
        </div>

        <img
          src={img}
          className="h-[500px] md:h-[550px] transition-all max-w-4xl rounded-xl ml-5 w-full object-cover object-left-top border-[#626B6D] border-2 border-solid border-r-0 mt-10 md:mt-0"
        />
      </div>
      <Footer />
    </>
  )
}

export default LoginScreen
