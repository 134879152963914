import type React from 'react'
import DefaultModal from '../../../../components/DefaultModal'
import type { BankAccountDto } from '../../../../types/coreApi-types'
import BankInfoContainer from '../Broker Service/components/BankInfoContainer'

interface Props {
  isOpen: boolean
  onClose: () => void
  bankAccount?: BankAccountDto
}

const BankInformationModal: React.FC<Props> = ({
  isOpen,
  onClose,
  bankAccount,
}) => {
  return (
    <DefaultModal
      isLoading={false}
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      title="Bank details"
      maxW="700px"
    >
      <BankInfoContainer accountInfo={bankAccount} />

      <div className="p-5">
        <button onClick={onClose} className="button">
          Close
        </button>
      </div>
    </DefaultModal>
  )
}

export default BankInformationModal
