import { useToast } from '@chakra-ui/react'
import { useState } from 'react'
import ChangeThemeCard from '../../../../components/ChangeThemeCard'

function SettingsScreen() {
  const changeDynamicSidebarPreference = () => {
    if (localStorage.getItem('dynamic-sidebar') === 'on') {
      localStorage.setItem('dynamic-sidebar', 'off')
    } else {
      localStorage.setItem('dynamic-sidebar', 'on')
    }
    window.dispatchEvent(new Event('storage'))
  }

  const [dynamicSidebar, setDynamicSidebar] = useState(
    localStorage.getItem('dynamic-sidebar') === 'on'
  )

  const [newsletter, setNewsletter] = useState(false)
  const toast = useToast()

  return (
    <div className="pb-20">
      <h1 className="h1">Settings</h1>
      <h2 className="h2 mt-10 mb-3">Appearance</h2>
      <ChangeThemeCard />

      {/* <SwitchSettingContainer
        title="Miniaturized sidebar"
        description="Decide whether or not the sidebar should resize when hovering."
        enabled={dynamicSidebar}
        iconRender={<FontAwesomeIcon icon={faLeftRight} size="lg" />}
        onChange={() => {
          setDynamicSidebar(!dynamicSidebar)
          changeDynamicSidebarPreference()
        }}
      /> */}
      {/* <h2 className="h2 mt-10 mb-3">Notifications</h2>
      <SwitchSettingContainer
        title="Newsletter"
        description="Get weekly updates on whats happening in the DeFi and crypto industry written by the Penning team."
        enabled={newsletter}
        iconRender={<FontAwesomeIcon icon={faEnvelope} />}
        onChange={() => {
          toast({
            description: `${
              newsletter ? 'Unsubscribed from' : 'Subscribed to'
            } newsletter`,
            position: 'bottom-right',
            variant: 'solid',
            status: 'success',
            containerStyle: {
              fontFamily: 'GeneralSans-Semibold',
              fontSize: '14px',
              marginTop: '0px',
            },
          })

          setNewsletter(!newsletter)
        }}
      /> */}
    </div>
  )
}

export default SettingsScreen
